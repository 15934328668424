import React, { useState, useRef, useEffect } from "react";
// import { useLocation } from "react-router-dom";

import {
  applyMakeup,
  initialiseCanvas,
  initialiseFaceMesh,
  smootheningTryon,
} from "../makeup_utils/tryon_handler.js";

import ImageUploading from "react-images-uploading";
import uploadanimation from "../images/icons/uploadanimation3.gif";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { MakeupAPIs } from "../network/api.js";
import Webcam from "react-webcam";
import liked from "../images/icons/likedN.svg";
import liked_red from "../images/icons/liked_red.svg";
// import wishlistICon from "../images/icons/wishlist.png";
import view from "../images/icons/appliedN.svg";
import clear from "../images/icons/resetN.svg";
import share from "../images/icons/shareN.svg";
import makeup_combo from "../images/icons/comboN.svg";
import download from "../images/icons/downloadN.svg";
import before_after from "../images/icons/compareN.svg";
import PoweredLogo from "../images/logo/PoweredLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import normalFace from '../images/icons/normal.svg'
import smoothFace from '../images/icons/smoothed.svg'

import imgSrcGloss from "../images/lip-shine-250_01.png";
// import imgSrcMetallicLipstick1 from "../images/lip_shine_v1_01.png";
import imgSrcMetallicLipstick1 from "../images/gloss_16-06_01_06.png";
// import imgSrcMetallicLipstick1 from "../images/gloss_16-06_02.png";
import imgSrcMetallicLipstick2 from "../images/lip_gloss_02_1.png";
import imgSrcGlitterMicro from "../images/glitter_lip_02.png";
// import sampleSVG from "../images/02.svg"
import imgSrcMetallicBackdrop from "../images/Glossy_metalic_backup.png";
import imgSrcMetallicEyeshadow1 from "../images/Glossy_es_05/Glossy_es_05_02.png";
import imgSrcMetallicEyeshadow2 from "../images/Glossy_es_08_01.png";

import imgSrcGlitterNano from "../images/lip_golden_glitter_03.png";
import imgSrcGlossGlitter from "../images/lip_glitter_gloss_04.png";

import imgeSrcEyeliner from "../images/default_eyeliner.png";
import CompareCanvas from "./CompareCanvas.js";
import OriginalCanvas from "./OriginalCanvas.js";
import changePhoto from "../images/icons/live.svg";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../configs/configs.js";
import { getDeviceUUID } from "../makeup_libs/utils.js";
import { validateInputImage } from "../makeup_utils/facemesh_utils.js";
import axios from "axios";
import { toast } from "react-toastify";

function Tryon(props) {
  const api = new MakeupAPIs()
  const uId = localStorage.getItem('uid');
  // eslint-disable-next-line
  {/* FEL-417 */}
  const locationValue = JSON.parse(sessionStorage.getItem('locationValue'));

  const [isLoading, setIsLoading] = useState(false);
  const [loadTryOn, setloadTryOn] = useState(false);
  //   const [captureImage, setCaptureImage] = useState(false);
  const [sliderWidth, setsliderWidth] = useState(null);
  const [sliderButtonPosition, setsliderButtonPosition] = useState(null);
  const [compare, setcompare] = useState(false);
  const [isErrorChecked, setIsErrorChecked] = useState(true);
  const [isPhotoUploading, setIsPhotoUploading] = useState(false);
  const [isSmoothening, setSmootheing] = useState(false);
  const [images,] = useState([]);
  const [currentItems, setCurrentItems] = useState({});
  const navigate = useNavigate();

  const webglRef = useRef(null);
  const webglRef2 = useRef(null);
  const imgGloss = useRef(null);
  const imgGlitter = useRef(null);
  const colorCanvasRef = useRef(null);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const canvasOriginalRef = useRef(null);
  //   const captureImageRef = useRef(captureImage);
  //   const location = useLocation();
  //   var globalMediapipeResults = [];
  //   var image_list = [];

  // Declare canvas
  var canvasElement = null;
  var canvasElementOriginal = null;
  //   var colorCanvas = null;
  var glCanvas = null;
  var glCanvas2 = null;
  var canvasElement_1 = null;

  const webCamError = () => {
    alert(
      "Some features of the app may not work properly if the permission to use camera is not allowed. Please allow the app to use the camera."
    );
  };
  // FEL-417
  function setupCanvas(_width,_height) {
    var videoWidth = _width;
    var videoHeight = _height;
    var devicePixelRatio = 1;

    if (props.tryOnMode === "upload_photo" || "change_photo") {
      window.insideOnce = false;
      // imgTag.src = image_list.data_url;
      // videoWidth = 1024;
      // videoHeight = 1024;
    } else {
      videoWidth = webcamRef?.current?.video?.videoWidth;
      videoHeight = webcamRef?.current?.video?.videoHeight;
    }

    // Set canvas width
    canvasRef.current.width = videoWidth * devicePixelRatio;
    canvasRef.current.height = videoHeight * devicePixelRatio;
    webglRef.current.width = videoWidth * devicePixelRatio;
    webglRef.current.height = videoHeight * devicePixelRatio;

    canvasElement_1 = document.getElementById("canvas_mask");
    canvasElement_1.width = videoWidth;
    canvasElement_1.height = videoHeight;

    canvasElement = canvasRef.current;
    // colorCanvas = colorCanvasRef.current;

    glCanvas = webglRef.current;
    glCanvas2 = webglRef2.current;

    glCanvas.width = canvasElement.width;
    glCanvas2.width = canvasElement.width || 1024;
    glCanvas.height = canvasElement.height;
    glCanvas2.height = canvasElement.height || 1024;

    canvasElementOriginal = document.getElementById("canvas_output_original");

    if (canvasElementOriginal) {
      canvasElementOriginal.width = videoWidth;
      canvasElementOriginal.height = videoHeight;
    }
  }

  const sliderChangeHandler = (e) => {
    const sliderPos = e.target.value;
    setsliderWidth(sliderPos);
    setsliderButtonPosition(sliderPos);
  };

  const compareHandler = () => {
    setcompare(!compare);
    setsliderWidth(50);
    setsliderButtonPosition(50);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      // FEL-417
      if(props.tryOnMode) {

        if (props.tryOnMode && props.tryOnMode === "video") { 
          setupCanvas(960,720);
        }
        else {
          setupCanvas(1024,1024);
        }
        initialiseCanvas(
          canvasElement,
          glCanvas2,
          canvasElement_1,
          canvasElementOriginal
        );
        if (props.tryOnMode && props.tryOnMode === "video") {
          initialiseFaceMesh(webcamRef, 540, 480, props.tryOnMode, null);
        } else {
          let getImageList = JSON.parse(sessionStorage.getItem('locationValue'));
          let image_list = getImageList.imageList;
          let imgTag = new Image();
          imgTag.src = image_list?.data_url;
          initialiseFaceMesh(webcamRef, 540, 480, getImageList.tryonType, imgTag);
        }
      }
    }, 1000);
    setloadTryOn(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    //eslint-disable-next-line
  }, [props.tryOnMode, props.imageList]);

  const clearMakeupItems = () => {
    props.resetMakeupItems();
    props.setLikedProd(false);
  };

  useEffect(() => {
    if (Object.keys(props.appliedMakeupItems).length > 0) {
      applyMakeup(props.appliedMakeupItems);
    }
  }, [props.appliedMakeupItems]);

  // FEL-766 New Smoothening
  function handleSmoothening() {
    setSmootheing(!isSmoothening);
  }

  //save makeup function
  const capture = async () => {
    const webcam = webcamRef.current;
    if (!webcam) return;

    const canvas1 = canvasRef.current;
    const canvas2 = webglRef2.current;
    if (!canvas1 || !canvas2) return;

    const compositeCanvas = document.createElement('canvas');
    const width = Math.max(canvas1.width, canvas2.width);
    const height = 800;

    compositeCanvas.width = width;
    compositeCanvas.height = height;

    const context = compositeCanvas.getContext('2d');
    const scale = height / canvas1.height;

    context.translate(width, 0);
    context.scale(-1, scale);
    context.drawImage(canvas1, 0, 0);

    context.translate(width, 0);
    context.scale(-1, 1);
    context.drawImage(canvas2, 0, 0);

    context.setTransform(1, 0, 0, 1, 0, 0);

    const poweredByImage = new Image();
    poweredByImage.src = PoweredLogo;
    poweredByImage.onload = () => {
      const overlayX = width - poweredByImage.width - 10;
      const overlayY = height - poweredByImage.height - 10;

      context.drawImage(poweredByImage, overlayX, overlayY);

      // Save the composite image
      compositeCanvas.toBlob(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
      // eslint-disable-next-line
        {/* FEL-417 */}
        link.download = 'SelfStylo Picture.png';
        link.click();
        URL.revokeObjectURL(url);
      }, 'image/jpeg');
    };

  };
  //like / dislike function
  const productLikeHandler = () => {
    if (!props.likedProd) {
      api.likemakeupProd(uId, props.activeProduct.id).then((res) => {
        props.setLikedProd(true)
      })
    } else {
      api.unlikemakeupProd(uId, props.activeProduct.id).then((res) => {
        props.setLikedProd(false)
      })
    }

  }
  const Fbhandle = () => {
    var uuid = getDeviceUUID();
    var currentProduct = Object.values(
      JSON.parse(sessionStorage.getItem("ids")) || {}
    );
    currentProduct = currentProduct.map(String);
    var productquery = currentProduct.join("&product_id=");
    fetch(
      `${BASE_URL}/share/?id=${uuid}&type=fb&product_id=${productquery}`
    ).then((response) => { });
  };
  const Twhandle = () => {
    var uuid = getDeviceUUID();
    var currentProduct = Object.values(
      JSON.parse(sessionStorage.getItem("ids")) || {}
    );
    currentProduct = currentProduct.map(String);
    var productquery = currentProduct.join("&product_id=");
    fetch(
      `${BASE_URL}/share/?id=${uuid}&type=tw&product_id=${productquery}`
    ).then((response) => { });
  };
  const Wphandle = () => {
    var uuid = getDeviceUUID();
    var currentProduct = Object.values(
      JSON.parse(sessionStorage.getItem("ids")) || {}
    );
    currentProduct = currentProduct.map(String);
    var productquery = currentProduct.join("&product_id=");
    fetch(
      `${BASE_URL}/share/?id=${uuid}&type=wp&product_id=${productquery}`
    ).then((response) => { });
  };

  const validateImage = (errors) => {

    var err_msg = ''
    if (errors) {

      if (errors.acceptType) { err_msg = 'Invalid file type' }
      if (errors.maxFileSize) { err_msg = 'File size should be less than 5 Mb' }
      if (errors.resolution) { err_msg = 'Selected file is not match your desired resolution' }

      toast.error(err_msg, {
        autoClose: 3000,
      });
      setIsErrorChecked(false);
    }
    else {
      return true
    }
  }

  const onChange = (imageList, addUpdateIndex) => {
    setIsPhotoUploading(true);

    let imgTag = new Image();
    if (imageList) {
      imgTag.src = imageList[imageList.length - 1].data_url;
    }

    axios.post(BASE_URL + "/api/get_resized_img/", { "img": imageList[imageList.length - 1].data_url }).then((res) => {
      if (res.data.data) {
        var new_img = {}
        new_img.data_url = res.data.data.cropped_img
        validateInputImage(imgTag).then((is_valid) => {
          const urlParams = new URLSearchParams(window.location.search);
          // get token from the query parameters.
          var token = urlParams.get('t') || '';
          if (is_valid) {
            sessionStorage.setItem('locationValue', JSON.stringify({
              tryonType: 'change_photo',
              imageList: new_img,
              token: token
            }));
            props.setImageList(new_img);
            clearMakeupItems();
            setIsPhotoUploading(false);
              navigate('/Makeup-Tryon',{state: {
                tryonType: 'change_photo',
                imageList: new_img,
                token: token
            }})
          } else {
            toast.error("Please upload a valid image!", {
              autoClose: 3000,
            })
          }
        }).catch((err) => {
          setIsPhotoUploading(false);
        });
      }
    }).catch((err) => {
      setIsPhotoUploading(false);
    });
  }

  //Exit Tryon
  function handleCloseTryon() {
    navigate('/');
    window.location.reload();
  }

  const removeItemHandler = (el, makeupItem) => {
	// get current makeup type
	let makeup_id = props.makeupTypes.filter(function(item) {
		return item.makeup_name === makeupItem})?.[0];
      // check if it's a current makeup item then remove active product
	  if (makeup_id.id === props.activeMakeupType.id) {
        props.setActiveProduct({ id: null, name: null });
      }
      // update active makeup items
	  let category = props.activeCategory;
      let data = {};
      data[category] = { id: null, name: null };
      props.activeMakeupItem[makeup_id.id] = data;
      props.setActiveMakeupItem(props.activeMakeupItem);

	  //remove makeup item
    let newAppliedItems = {...currentItems};
    delete newAppliedItems[makeupItem]; 
    setCurrentItems(newAppliedItems);
    applyMakeup(newAppliedItems);

  }

  //FEL-766 New Smoothening
  useEffect(() => {
    smootheningTryon(isSmoothening);
  }, [isSmoothening])
  

  useEffect(() => {
    setCurrentItems(props.appliedMakeupItems);
  }, [props.appliedMakeupItems])
  

  return (
    <>
      <div className="row g-0 logoAbsoluteLight">
        {
          isPhotoUploading && <div className="d-flex align-items-center justify-content-center uploadanimation" style={{ filter: isPhotoUploading ? 'blur(1px)' : '' }}> <div className="d-flex align-items-center justify-content-center uploadanimation-bg"> </div>
            <img className="btn_icon_btn" src={uploadanimation} alt="uploadanimation"></img></div>
        }
        <div className="mx-auto text-center">
          <img
            src="/static/media/transparentLogo.64437d00.svg"
            className="WidthClasst d-none"
            alt="logo"
          />
          <div className="actionBtnAll">
            <button
              className="btn"
              data-bs-placement="top"
              title="Close"
              data-bs-toggle="modal" data-bs-target="#closeTryonModal"
            >
              <FontAwesomeIcon icon={faTimes} color="#211616"></FontAwesomeIcon>
            </button>
          </div>
        </div>
      </div>
      <div className="modal fade" id="closeTryonModal" tabIndex="-1" aria-labelledby="closeTryonModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="closeTryonModalLabel">Close</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Do you want to exit SelfStylo?
            </div>
            <div className="modal-footer appliedMobileFooter">
              <button type="button" className="btn btn-secondary closeModalButton" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary closeModalButton" onClick={() => handleCloseTryon()}>Yes</button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="views"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="View Applied Items"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewItem">
                Applied Items
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body py-4">
              <div className="mt-2">
                {Object.entries(currentItems).map((item, i) => {
                  return (
                    <div key={i}>
                      <div className="card mt-2 shadow-sm makeupItem">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-5 col-5 p-2 borderRight my-auto">
                            {/* FEL-417 */}
                            <div className="d-flex align-items-center">
                              <img
                                src={item[1]?.makeup_icon}
                                alt="icon"
                                // src={JSON.stringify(props.brand_type)}
                                className="imgSmall"
                              />
                              <span className="text-muted small mx-2">
                                {
                                  item[0]
                                }
                              </span>
                            </div>
                          </div>
                          <div className="col-md-7 col-sm-2 col-6 p-2 my-auto col-md-1 my-auto p-2 borderleft d-flex align-items-center">
                            {/* <div> */}
                            <svg width="30" height="30">
                              <circle
                                cx="15"
                                cy="15"
                                r="15"
                                strokeWidth="4"
                                fill={
                                  item[1]?.code
                                }
                              />
                            </svg>
                            <span className="my-auto p-2 ">
                              {
                                item[1]?.product_name
                              }
                            </span>
                            {/* </div>   */}
                          </div>
                          <div
                            className="col-md-1 my-auto col-md-3 p-2 borderleft "
                            style={{
                              position: "absolute",
                              top: "10%",
                              right: "0",
                              width: "50px",
                              // transform: "translateY",
                            }}
                          >
                            <button
                              className="btn"
                              onClick={(e) => removeItemHandler(e, item[0])}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                color="#211616"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="App h-100 stepMobileAlign" style={{ filter: isPhotoUploading ? 'blur(4px)' : '' }}>
        {isLoading && (
          <div className="divLoader">
            <div className="loader"></div>
          </div>
        )}

        {loadTryOn ? (
          <>
            <div className="camwidth">
              {props.tryOnMode === "video" ? (
                <Webcam
                  id="web12"
                  className="videoElement"
                  ref={webcamRef}
                  style={{
                    display: "none",
                  }}
                  onUserMediaError={() => {
                    webCamError();
                    window.location.href = "/";
                  }}
                />
              ) : (
                <canvas
                  ref={webcamRef}
                  style={{
                    display: "none",
                  }}
                />
              )}
            </div>
            <img
              alt=""
              id="image_tex_gloss"
              ref={imgGloss}
              width="10"
              height="10"
              src={imgSrcGloss}
              style={{ display: "none" }}
            ></img>
            <img
              alt=""
              id="image_tex_glitter_nano"
              ref={imgGlitter}
              width="100"
              height="100"
              src={imgSrcGlitterNano}
              style={{ display: "none" }}
            ></img>
            <img
              alt=""
              id="image_tex_glitter_micro"
              ref={imgGlitter}
              width="100"
              height="100"
              src={imgSrcGlitterMicro}
              style={{ display: "none" }}
            ></img>
            <img
              alt=""
              id="image_tex_gloss_glitter"
              width="100"
              height="100"
              src={imgSrcGlossGlitter}
              style={{ display: "none" }}
            ></img>

            {/* Latest Editions : */}
            <img
              alt=""
              id="image_tex_metallic_lipstick_1"
              width="100"
              height="100"
              src={imgSrcMetallicLipstick1}
              style={{ display: "none" }}
            ></img>
            <img
              alt=""
              id="image_tex_metallic_lipstick_2"
              width="100"
              height="100"
              src={imgSrcMetallicLipstick2}
              style={{ display: "none" }}
            ></img>

            {/* for eyeshadow */}

            <img
              alt=""
              id="image_tex_metallic_backdrop"
              width="100"
              height="100"
              src={imgSrcMetallicBackdrop}
              style={{ display: "none" }}
            ></img>
            <img
              alt=""
              id="image_tex_metallic_eyeshadow_1"
              width="100"
              height="100"
              src={imgSrcMetallicEyeshadow1}
              style={{ display: "none" }}
            ></img>
            <img
              alt=""
              id="image_tex_metallic_eyeshadow_2"
              width="100"
              height="100"
              src={imgSrcMetallicEyeshadow2}
              style={{ display: "none" }}
            ></img>
            {/* Eyeliner tetxures */}
            <img
              alt=""
              id="image_tex_eyeliner_left"
              width="10"
              height="10"
              src={imgeSrcEyeliner}
              style={{ display: "none" }}
              crossOrigin="anonymous"
            ></img>

            <img
              alt=""
              id="image_tex_eyeliner_right"
              width="100"
              height="100"
              src={imgeSrcEyeliner}
              style={{ display: "none" }}
            ></img>

            {
              <div className="canvas-container">
                <div
                  className="output-wrapper canvas-wrapper"
                  style={{ width: compare ? sliderWidth + "%" : 100 + "%" }}
                >
                  <OriginalCanvas
                    canvasRef={canvasRef}
                    webglRef={webglRef}
                    webglRef2={webglRef2}
                    colorCanvasRef={colorCanvasRef}
                  />
                </div>
                <div className="original-wrapper canvas-wrapper">
                  <CompareCanvas
                    canvasOriginalRef={canvasOriginalRef}
                    is_visible={compare}
                  />
                </div>

                <input
                  type="range"
                  min="1"
                  max="100"
                  {...(sliderWidth
                    ? { value: sliderWidth }
                    : { defaultValue: "50" })}
                  // value={sliderWidth}
                  onChange={(e) => sliderChangeHandler(e)}
                  // defaultValue="50"
                  className={"diffslider " + (compare ? "" : "d-none")}
                  name="slider"
                  id="slider"
                ></input>
                <div
                  className={"slider-button " + (compare ? "" : "d-none")}
                  style={{
                    left: `calc(${sliderButtonPosition}% - 18px)`,
                    right: `calc(${sliderButtonPosition}% - 18px)`,
                  }}
                ></div>
              </div>
            }

            <canvas
              id="canvas_mask"
              width="540"
              height="480"
              style={{ display: "none" }}
            ></canvas>
            <div className="cameraActionBtn">
              <div className="webcamBtnContainer">
                <div className="actionBtnAll">
                  {/* FEL-417 For disabling and higlighting according to product liked and unliked or cleared*/}
                  <button className="btn clickonclearbtn " title="Like" onClick={productLikeHandler} disabled={((Object.keys(props.appliedMakeupItems)).length>0 && (props?.activeProduct?.id)!==null) ?false:true}>
                    <img src={props.likedProd && (Object.keys(props.appliedMakeupItems)).length >0 ? liked_red : liked} className="btn" alt="like" />
                  </button>
                </div>
                <div className="actionBtnAll">
                  <button
                    onClick={compareHandler}
                    className="btn"
                    title="Compare"
                    data-bs-toggle="tooltip"
                  >
                    <img
                      src={before_after}
                      className="btn"
                      alt="before-after"
                    />
                  </button>
                </div>
                <div className="actionBtnAll">
                  <button
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-placement="top"
                    title="Applied Items"
                    id="showApplied"
                    data-bs-target="#views"
                    // eslint-disable-next-line
                    // FEL-417 
                    disabled={(Object.keys(props.appliedMakeupItems)).length > 0 ? false : true}
                  >
                    <img src={view} alt="view" />
                  </button>
                </div>
                <div className="actionBtnAll">
                  <button
                    className="btn"
                    data-bs-placement="top"
                    title="Share"
                    data-bs-toggle="modal"
                    data-bs-target="#share"
                  >
                    <img src={share} className="btn" alt="share" />
                  </button>
                </div>
                <div
                  className="modal fade"
                  id="share"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel2">
                          Share via
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body py-4">
                        <FacebookShareButton
                          url={
                            `${BASE_URL}/static/img/social_share.png`
                          }
                          quote={!(props.configData.data === undefined) ? !(props.configData.data["share_quote"] === undefined) ? props.configData.data["share_quote"] : "#selfstylo" : "#selfstylo"}
                          hashtag={!(props.configData.data === undefined) ? !(props.configData.data["share_hashtags"] === undefined) ? props.configData.data["share_hashtags"] : "#selfstylo" : "#selfstylo"}
                          className="mx-2"
                          onClick={Fbhandle}
                        >
                          <FacebookIcon round={true} size={40} />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={
                            `${BASE_URL}/static/img/social_share.png`
                          }
                          quote={!(props.configData.data === undefined) ? !(props.configData.data["share_quote"] === undefined) ? props.configData.data["share_quote"] : "#selfstylo" : "#selfstylo"}
                          hashtag={!(props.configData.data === undefined) ? !(props.configData.data["share_hashtags"] === undefined) ? props.configData.data["share_hashtags"] : "#selfstylo" : "#selfstylo"}
                          className="mx-2"
                          onClick={Twhandle}
                        >
                          <TwitterIcon round={true} size={40} />
                        </TwitterShareButton>

                        <WhatsappShareButton
                          url={
                            (!(props.configData.data === undefined) ? !(props.configData.data["share_quote"] === undefined) ? props.configData.data["share_quote"] : "#selfstylo" : "#selfstylo") + '\n\n\n' +
                            (!(props.configData.data === undefined) ? !(props.configData.data["share_url"] === undefined) ? props.configData.data["share_url"] : "#selfstylo" : "#selfstylo") + '\n\n' +
                            (!(props.configData.data === undefined) ? !(props.configData.data["share_hashtags"] === undefined) ? props.configData.data["share_hashtags"] : "#selfstylo" : "#selfstylo") + "\n Poweredby SelfStylo "
                          }
                          quote={!(props.configData.data === undefined) ? !(props.configData.data["share_quote"] === undefined) ? props.configData.data["share_quote"] : "#selfstylo" : "#selfstylo"}
                          hashtag={!(props.configData.data === undefined) ? !(props.configData.data["share_hashtags"] === undefined) ? props.configData.data["share_hashtags"] : "#selfstylo" : "#selfstylo"}
                          className="mx-2"
                          onClick={Wphandle}
                        >
                          <WhatsappIcon round={true} size={36} />
                        </WhatsappShareButton>
                        <div className="mt-2">
                          <p>
                            By sharing your picture on social media, you are
                            bound by the social media’s terms and conditions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="actionBtnAll">
                  <button
                    className="btn"
                    data-bs-toggle="tooltip"
                    title="Save Makeup"
                    onClick={capture}
                    // FEL -417
                    disabled={compare}
                  >
                    <img src={download} className="btn" alt="save" />
                  </button>
                </div>
                {/* FEL-417  For Hiding change or upload photo option while in live mode */}
                  {
                    locationValue.tryonType !=="video" && 
                    <div className="actionBtnAll">
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          // maxNumber={maxNumber}
                          dataURLKey="data_url"
                          acceptType={window.photoAccessType}
                          maxFileSize={5242880}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            isDragging,
                            dragProps,
                            errors
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper">

                              {(isErrorChecked && errors && validateImage(errors))}
                              <button
                                // onClick={() => LoadCamera("upload")}
                                className="btn"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Change Photo"
                                style={isDragging ? { color: "red" } : null}
                                onClick={() => {
                                  onImageUpload(errors)
                                  setIsErrorChecked(true);
                                }}
                                {...dragProps}
                              >
                                <div className="">
                                  <div className="">
                                    <img src={changePhoto} className="btn" alt="Change" />
                                  </div>
                                </div>
                              </button>
                              &nbsp;
                              {imageList.map((image, index) => (
                                <div key={index} className="image-item h-100">
                                  <img
                                    id="image"
                                    src={image.data_url}
                                    alt=""
                                    height="inherit"
                                    width="inherit"
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </ImageUploading>
                    </div>
                  }
                <div className="actionBtnAll">
                  <button
                    className="btn"
                    title="Clear"
                    data-bs-toggle="tooltip"
                    onClick={clearMakeupItems}
                  >
                    <img src={clear} className="btn" alt="clear" />
                  </button>
                </div>
                <div className="actionBtnAll" id="makeupSmootheningBtn">
                  {/* FEL-766 New Smoothening */}
                <button
                  className="btn"
                  data-bs-placement="top"
                  title="Smoothening"
                  onClick={(e) => handleSmoothening()}
                >
                  <img src={isSmoothening ? smoothFace : normalFace} className="btn" alt="smooth" />
                </button>
              </div>
                <div className="actionBtnAll" id="makeupcombobtn">
                  <button
                    className="btn"
                    data-bs-placement="top"
                    title="Multiple Makeup"
                  >
                    <img
                      src={makeup_combo}
                      className="btn"
                      onClick={(e) => {
                        props.setComboMode(!props.comboMode);
                        props.setAppliedMakeupItems({});
                      }}
                      alt="makeup_combo"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="poweredBy">
              <img src={PoweredLogo} className="btn" alt="powerby" />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default Tryon;
