import cv from "@techstark/opencv-js";

function smoothenSkin(image, d = 11, sigmaColor = 50, sigmaSpace = 50) {
    // Convert the image to BGR format
    let imageBGR = new cv.Mat();
    cv.cvtColor(image, imageBGR, cv.COLOR_RGBA2BGR);

    // Apply bilateral filter for smoothing
    let smoothedImage = new cv.Mat();
    cv.bilateralFilter(imageBGR, smoothedImage, d, sigmaColor, sigmaSpace);

    // Convert the smoothed image back to RGB format
    let smoothedRGBImage = new cv.Mat();
    cv.cvtColor(smoothedImage, smoothedRGBImage, cv.COLOR_BGR2RGBA);

    // Clean up Mats
    imageBGR.delete();
    smoothedImage.delete();

    return smoothedRGBImage;
}
export default function Smoothening(_landmarks, _canvasElement, canvasCtx) {

    const landmarks_points = _landmarks.map(lm => [parseInt(lm.x * _canvasElement.width), parseInt(lm.y * _canvasElement.height)]);

    let forehead_landmark_indices = [162, 21, 54, 103, 67, 109, 10, 338, 297, 332, 284, 251, 389, 150, 149, 176, 148, 152, 377, 400, 378, 379];

    //Incrementing the face region
    for (let idx of forehead_landmark_indices) {
        if (idx === 54 || idx === 21 || idx === 284 || idx === 251) {
            landmarks_points[idx] = [parseInt(landmarks_points[idx][0]), parseInt(landmarks_points[idx][1]) - 100];
        } else if (idx === 150 || idx === 149 || idx === 176 || idx === 148 || idx === 377 || idx === 400 || idx === 378 || idx === 379) {
            landmarks_points[idx] = [parseInt(landmarks_points[idx][0]), parseInt(landmarks_points[idx][1]) + 100];
        } else {
            landmarks_points[idx] = [parseInt(landmarks_points[idx][0]), parseInt(landmarks_points[idx][1]) - 100];
        }
    }

    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;

    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;

    for (let [x, y] of landmarks_points) {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);

        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
    }


    let x = minX;
    let y = minY;

    let w = maxX - minX;
    let h = maxY - minY;

    let imageData = canvasCtx.getImageData(x, y, w, h);
    let regionMat = cv.matFromImageData(imageData);

    // Apply skin smoothing on the region Mat
    let smoothedRegionMat = smoothenSkin(regionMat);

    // Convert smoothed Mat back to ImageData
    let smoothedImageData = new ImageData(
        new Uint8ClampedArray(smoothedRegionMat.data), w, h);

    // Update the canvas with the smoothed image data
    canvasCtx.putImageData(smoothedImageData, x, y);

    // Cleanup Mats to avoid memory leaks
    regionMat.delete();
    smoothedRegionMat.delete();
}