import { BASE_URL } from "../configs/configs";
import axios from "axios";
let uuid = localStorage.getItem('uid');

export class MakeupAPIs {
    // Get all makeup types
    getMakeupTypes = async () => {
        let makeup_data = [];
        await axios.get(BASE_URL + '/api/makeup_all/').then((res) => {
            makeup_data = res.data
        }).catch((e) => {
            console.log(e)
        });

        return makeup_data;
    }

    // get all makeup categories
    getAllCategories = async (makeup_id) => {
        let category_data = [];
        await axios.get(BASE_URL + `/api/category_all?brand_id=${makeup_id}`).then((res) => {
            category_data = res.data
        }).catch((e) => {
            console.log(e)
        });
        return category_data;
    }

    // get all makeup products
    getAllMakeupProducts = async (category_id) => {
        let product_data = [];
        await axios.get(BASE_URL + `/api/product_all?category_id=${category_id}`).then((res) => {
            product_data = res.data
        }).catch((e) => {
            console.log(e);
        });
        return product_data;

    }

    // get all makeup styles
    getAllMakeupStyles = async () => {
        let style_data = [];
        await axios.get(BASE_URL + '/api/get_styles/').then((res) => {
            style_data = res.data.data;
        }).catch((e) => {
            console.log(e);
        });
        return style_data;
    }

    // get all makeup combos
    getAllMakeupCombos = async () => {
        let combo_data = [];
        await axios.get(BASE_URL + '/api/makeup_Combo/').then((res) => {
            combo_data = res.data.data;
        }).catch((e) => {
            console.log(e)
        });
        return combo_data;
    }

    //like makeup prodcuts
    likemakeupProd = async (userId, prodId) => {
        let data = []
        await axios.get(BASE_URL + `/info/like?id=${userId}&product_id=${prodId}`).then((res) => {
            data = res.data
        }).catch((e) => {
            console.log(e)
        })
        return data
    }

    //unlike makeup prodcuts
    unlikemakeupProd = async (userId, prodId) => {
        let data = []
        await axios.get(BASE_URL + `/info/unlike?id=${userId}&product_id=${prodId}`).then((res) => {
            data = res.data
        }).catch((e) => {
            console.log(e)
        })
        return data
    }

    //check product status
    checkProdStatus = async (userId, prodId) => {
        let prod_status = []
        await axios.get(BASE_URL + `/info/status?id=${userId}&product_id=${prodId}`).then((res) => {
            prod_status = res.data
        }).catch((e) => {
            console.log(e)
        })
        return prod_status
    }

    // fetch makeup item request details
    getIframeDetails = async (token) => {
        let iframe_data = []
        await axios.get(BASE_URL + `/api/fetch_makeup_data?token=${token}`).then(res => {
            iframe_data = res.data
        }).catch((e) => {
            console.log(e)
        })
        return iframe_data
    }

    // get makeup details
    getMakeupDetails = async (makeup_type) => {
        let makeupData = {}
        await axios.get(BASE_URL + `/api/makeup?type=${makeup_type}&mode=integration`).then(res => {
            makeupData = res.data
        }).catch((e) => {
            console.log(e)
        })
        return makeupData
    }

    // get product details
    getProductDetails = async (product_detail) => {
        let productDetail = {}
        await axios.post(BASE_URL + "/api/get_product/", product_detail).then(res => {
            productDetail = res.data.data;
        }).catch((e) => {
            console.log(e)
        })
        return productDetail
    }

    //get skintone undertone - foundation
    getSkinTones = async () => {
        let tones = []
        await axios.get(BASE_URL + "/api/get_skintone_undertone").then((res) => {
            tones = res.data
        }).catch((e) => {
            console.log(e)
        })
        return tones
    }

    //get shade for selected skin tones
    getShadesForSkintone = async (currentSkintone, currentUndertone) => {
        let data = []
        await axios.get(BASE_URL + `/api/get_skintone_colors?st=${currentSkintone}&ut=${currentUndertone}`).then((res) => {
            data = res.data
        }).catch((e) => {
            console.log(e)
        })
        return data
    }


    //Foundation Based on captured Image
    colorChange = async (_message) => {

        let data = [];
        await axios
            .get(
                BASE_URL + `/api/skincolorimagecapt/?company_id=12&skintone=${_message}`
            )
            .then((res) => {
                data = res.data.data;
            }).catch((e) => {
                console.log(e)
            });
        return data;
    }

    //Count Makeup Types Tried On Number Of Clicks
    makeupTypeCount = async (_id) => {
        let countDetail = {}
        await axios.get(BASE_URL + "/api/makup_click/", {
            brand_id: _id
        }).then(res => {
            countDetail = res;
        }).catch((e) => {
            console.log(e)
        })
        return countDetail
    }
    //Count Categories Tried On Number Of Clicks
    categoryCount = async (_id) => {
        let countDetail = {}
        await axios.get(BASE_URL + "/api/category_click/", {
            category_id: _id
        }).then(res => {
            countDetail = res;
        }).catch((e) => {
            console.log(e)
        })
        return countDetail
    }

    //Count Product Tried On Number Of Clicks
    productCount = async (_id) => {
        let countDetail = {}
        await axios.post(BASE_URL + "/api/product_try_count", {
            color_code: _id,
            device_uuid: uuid
        }).then(res => {
            countDetail = res;
        }).catch((e) => {
            console.log(e)
        })
        return countDetail
    }

    //Check Brand Subscription
    checkSubscription = async () => {
        let isSubscribed = { allowed: null, message: '' };
        await axios.post(BASE_URL + "/api/check_access", {
            device_uuid: uuid,
            is_kiosk: window.isKiosk
        }).then((res) => {
            var response = res.data.data;
            if (response && response['access'] === false) {
                isSubscribed["message"] = response.message
                isSubscribed["allowed"] = false
            }
            else {
                isSubscribed["message"] = response.message
                isSubscribed["allowed"] = true;
            }
        })
        return isSubscribed;
    }

    getConfigurations = async () => {
        let configData = {};
        await axios.get(BASE_URL + '/api/configurations/').then((_response) => {
            configData = _response.data;
        }).catch((e) => {
            console.log(e)
        });
        return configData;
    }
}