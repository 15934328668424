import React, { useEffect, useState,useRef } from 'react'
import { MakeupAPIs } from '../network/api';
import { BASE_URL } from '../configs/configs';
import Slider from "react-slick";
import useWindowDimensions from '../makeup_utils/window_dimensions';
import { applyMakeup } from '../makeup_utils/tryon_handler';


export default function MakeupStyles(props) {

    const [styleData, setStyleData] = useState([]);
    const sliderRef = useRef();
    const {width}=useWindowDimensions();
    const [isLoading, setIsLoading] = useState(false);

    var default_settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4
      };
    
    var mobile_settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 820,
            settings: {
              // slidesToShow: 5,
              // slidesToScroll: 4,
              // initialSlide: 2,
              // arrows: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              // initialSlide: 0,
              // arrows: false,
            },
          },
        ],
    };

    var settings = width <= 600 ? mobile_settings : default_settings

    const setMakeupStyle = (id, image) => {
        let applied_makeup = {...props.appliedMakeupItems};
        if (applied_makeup[props.activeMakeupType.name] !== undefined && applied_makeup[props.activeMakeupType.name] !== null) {
            applied_makeup[props.activeMakeupType.name]['style_id'] = id;
            applied_makeup[props.activeMakeupType.name]['style_image'] = BASE_URL + image;
        }
        props.setAppliedMakeupItems(applied_makeup);
    }

    useEffect(() => {
        let api = new MakeupAPIs();
        setIsLoading(true);
        api.getAllMakeupStyles().then((res) => {
            if (props.activeMakeupType?.name !== null && props.activeMakeupType?.name !== undefined) {
                setStyleData(res[props.activeMakeupType.name] || []);
                setIsLoading(false);
                if (props.activeMakeupStyle.id === null || props.activeMakeupStyle.id === undefined) {
                    props.setActiveMakeupStyle({
                        id:res[props.activeMakeupType.name]?.[0]?.id,
                        image:BASE_URL + res[props.activeMakeupType.name]?.[0]?.eyeliner_style_image
                    });
                }
            }
        });
    //eslint-disable-next-line
    }, [props.activeMakeupType])


    const activeStyleHandler = (item) => {
        props.setActiveMakeupStyle({id: item.id, image: BASE_URL + item.eyeliner_style_image});
        setMakeupStyle(item.id, item.eyeliner_style_image);
    };

    return (
        <>
            <div className='row p-2' >
                {styleData?.length > 0 && (<hr className='mt-2'/>)}
                {!isLoading && <Slider {...settings} ref={sliderRef}>
                {styleData?.length > 0 && styleData.map((item,key) => {
                return (
                    <div key={key} className={"commonStyle col-md-3 " + (props.activeMakeupStyle.id === item.id ? 'activeCategoryStyle' : '')}
                        onClick={(e) => {activeStyleHandler(item)}} title={item.eyeliner_style_name}>
                        <div className="text-truncate text-center">
                            <button
                                className="btn btn-sm btnPdng" data-bs-toggle="tooltip"
                                data-bs-placement="top"><img
                                    src={BASE_URL + item.eyeliner_style_icon}
                                    className="logo-size" alt={item.eyeliner_style_name}/>
                                <div className="category_tags"><span
                                        className="d-grid mx-auto my-2 text-center flex-wrap">{item.eyeliner_style_name}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    )
                })}
                </Slider>}
            </div>
        </>
    )
}
