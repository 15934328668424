import { MakeupAPIs } from "../network/api";

// Creates a list of color values from rgb color value
const getColor = (color_code) => {
    let color = color_code.split("rgb")[1].slice(1, -1).split(",");

    var ra = parseInt(color[0]);
    var ga = parseInt(color[1]);
    var ba = parseInt(color[2]);

    return [ra, ga, ba];
  };


// Divides array to small sized chunks
const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

  
  // load products from the category
  const loadProducts = (category_id, setProductData) => {
    let api = new MakeupAPIs();
    if (category_id !== null && category_id !== undefined) {
      api.getAllMakeupProducts(category_id).then((res) => {
        setProductData(res);
      });
    }else{
      setProductData([])
    }
  }


export {getColor, chunk, loadProducts}