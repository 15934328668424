import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./configs/firebaseConfig";
import "./css/CustomApp.css"


// navbar
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConsentScreen } from './components/ConsentScreen';
import {initializeFaceMesh} from './makeup_utils/facemesh_utils.js'

initializeFaceMesh(null,null,null,null,null);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<ConsentScreen/>} />
      <Route exact path="/Makeup-Tryon" element={<App/>} />
    </Routes>
    <ToastContainer/>
  </BrowserRouter>
);
