import React, { useEffect, useState } from "react";
import { DeviceUUID } from "device-uuid";
import { toast } from 'react-toastify';
import logo from "../images/logo/FelicitaLogo.svg";//FEL-766
import BgRight from "../images/icons/bg-right.png";
import BgLeft from "../images/icons/bg-left.png";
import MakeupTypes from "./MakeupTypes";
import Tryon from "./Tryon";
import MakeupCategories from "./MakeupCategories";
import MakeupStyles from "./MakeupStyles";
import MakeupProducts from "./MakeupProducts";
import MakeupCombo from "./MakeupCombo";
import { useLocation } from "react-router-dom";
import { applyMakeup } from "../makeup_utils/tryon_handler";
import useWindowDimensions from "../makeup_utils/window_dimensions";
import { MakeupAPIs } from "../network/api";
import { getColor } from "../makeup_utils/common_utils";
import { Foundation } from "./Foundation";
import ShadePicker from "./ShadePicker";
import CapturePhoto from './CapturePhoto'
import { BASE_URL } from "../configs/configs";

function Layout() {

    const [makeupTypes, setMakeupTypes] = useState([]);
    const [activeMakeupType, setActiveMakeupType] = useState({ id: null, name: null, icon: null });
    const [activeCategory, setActiveCategory] = useState(null);
    const [activeProduct, setActiveProduct] = useState({ id: null, name: null });
    const [activeMakeupItem, setActiveMakeupItem] = useState({});
    const [activeMakeupStyle, setActiveMakeupStyle] = useState({ id: null, image: null });
    const [appliedMakeupItems, setAppliedMakeupItems] = useState({});
    const [comboMode, setComboMode] = useState(false);
    const [activeCombo, setActiveCombo] = useState(null);
    const [imageList, setImageList] = useState([]);
    const [tryOnMode, setTryOnMode] = useState('');
    const [likedProd, setLikedProd] = useState(false)
    const [showCategories, setShowCategories] = useState(true);
    const [foundationType, setFoundationType] = useState(1)
    const [productData, setProductData] = useState([]);
    const [getConfigDataState, setConfigDataState] = useState({});
    const [showProducts, setShowProducts] = useState(false);

    const { width } = useWindowDimensions()
    // const [token, setToken] = useState('');

    const location = useLocation();

    const resetMakeupItems = () => {
        setActiveMakeupItem({});
        setAppliedMakeupItems({});
        applyMakeup({});
        setActiveCombo({});
        setActiveProduct({ id: null, name: null })
    }
    const api = new MakeupAPIs();
    let uuid = localStorage.getItem('uid');
    var du = new DeviceUUID().parse();

    useEffect(() => {
        let getImageList = JSON.parse(sessionStorage.getItem('locationValue'))
        let image_list = getImageList.imageList;
        setImageList(image_list);
        let try_on_type = (getImageList.tryonType) || 'video';
        setTryOnMode(try_on_type);
        let tokenVal = location && location?.state?.token;
        // setToken(tokenVal);

        // FEL-42 : Author - Pruthvi Barot
        // this block is developed for initialising all the makeup items for iframe.
        if (tokenVal !== '') {
            var active_makeup_type = {}
            var active_category = null;
            var active_product = {}
            // get makeup details using token
            api.getIframeDetails(tokenVal).then((res) => {
                var product_details = res.data['payload'];
                var applied_makeup = {}
                for (let product_item of Object.entries(product_details)) {
                    let makeup_type = product_item[0]
                    // get makeup details
                    // eslint-disable-next-line no-loop-func
                    api.getMakeupDetails(makeup_type).then((res) => {
                        active_makeup_type = res
                        // get product details
                        api.getProductDetails(product_item[1]).then((resp) => {
                            console.log(resp)
                            active_category = resp.categorie_id;
                            active_product = { id: resp.id, name: resp.color_name }

                            // prepare applied makeup items
                            applied_makeup[product_item[0]] = {
                                id: resp.id,
                                color: getColor(resp.color_code),
                                code: resp.color_code,
                                product_type_code: resp.product_type_code,
                                product_code: resp.product_code,
                                intensity: resp.intensity_web,
                                style_id: activeMakeupStyle?.id,
                                style_image: activeMakeupStyle?.image,
                            }

                            // initialise all active states
                            setAppliedMakeupItems(applied_makeup);
                            applyMakeup(applied_makeup);
                            // eslint-disable-next-line no-unused-vars
                            let activeMakeupTypeId = active_makeup_type.id
                            // setActiveMakeupItem({ activeMakeupTypeId: { active_category: active_product } });
                        });

                    });


                }

                setTimeout(() => {
                    setTimeout(() => {     
                        setActiveMakeupType({
                            id: active_makeup_type.id,
                            name: active_makeup_type.makeup_name,
                            icon: active_makeup_type.icon
                        });
                    }, 200);
                    setTimeout(() => {
                        setActiveCategory(active_category);
                    }, 400);
                    setTimeout(() => {
                        setActiveProduct(active_product);
                        setActiveMakeupItem({ activeMakeupTypeId: { active_category: active_product } });
                    }, 600);
                }, 1000);


            });
        }
    // eslint-disable-next-line
    }, [location])
    const urlParams = new URLSearchParams(window.location.search);
    var orginated = urlParams.get("external");
    useEffect(() => {

        //Check Subscription
        api.checkSubscription().then((_isAllowed) => {
            if (_isAllowed.allowed === false) {
                toast.error(_isAllowed.message, {
                    autoClose: 3000,
                });
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            }
        });

        //Get Configurations
        api.getConfigurations().then((_configData) => {
            setConfigDataState(_configData)
        });

        //Record Try-ons
        fetch("https://api.ipify.org/?format=json")
            .then((response) => response.json())
            .then((data) => {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        data: du,
                        id: uuid,
                        ip: data["ip"],
                        orginated: orginated,
                        company_id: 12,
                        request_type: window.token !== "" ? "iframe" : "direct",
                    }),
                };
                fetch(BASE_URL + "/info/", requestOptions).then((response) =>
                    response.json()
                );
                // .then((data) => console.log(data));
            });
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        if (activeMakeupType.name === 'Foundation' && foundationType !== 1) {
            setShowCategories(false)
            // setActiveCategory(null)
            // setProductData([])
        } else {
            setFoundationType(1)
            setShowCategories(true)
        }
    }, [activeMakeupType, foundationType])

    return (
        <div className="container-fluid bg-img cover-container d-flex mx-auto flex-column">
            <div className="bgleftimg"><img src={BgLeft} alt="bg_left" /></div>
            <div className="bgrightimg"><img src={BgRight} alt="bg_right" /></div>
            <div className="card border-0 card-bg_main">
                {width <= 600 ?
                    <div className="row g-0 justify-content-center verticleAlignMiddleMob">
                        <div
                            className="col-xl-1 col-md-12 col-lg-1 order-xl-1 order-md-1 order-1 text-center rounded-3 bg-Opa m-2 widthSize py-2 categoryAlign cardMobile ">
                            <img src={logo} className="WidthClass d-none d-md-none d-lg-block" alt="logo" />
                            <MakeupTypes
                                makeupTypes={makeupTypes}
                                setMakeupTypes={setMakeupTypes}
                                activeMakeupType={activeMakeupType}
                                setActiveMakeupType={setActiveMakeupType}
                                activeMakeupItem={activeMakeupItem}
                                setActiveMakeupItem={setActiveMakeupItem}
                                setActiveCategory={setActiveCategory}
                                setActiveProduct={setActiveProduct}
                                setComboMode={setComboMode}

                            />
                        </div>
                        <div className="col-xl-5 col-md-12 col-lg-5 order-xl-2 order-md-2 rounded-3 m-2 order-2 bg-Opa cameraViewport height100vh">
                            <Tryon
                                setImageList={setImageList}
                                configData={getConfigDataState}
                                appliedMakeupItems={appliedMakeupItems}
                                setAppliedMakeupItems={setAppliedMakeupItems}
                                comboMode={comboMode}
                                setComboMode={setComboMode}
                                imageList={imageList}
                                tryOnMode={tryOnMode}
                                activeProduct={activeProduct}
                                setActiveProduct={setActiveProduct}
                                setLikedProd={setLikedProd}
                                likedProd={likedProd}
                                resetMakeupItems={resetMakeupItems}
                                makeupTypes={makeupTypes}
                                activeMakeupItem={activeMakeupItem}
                                setActiveMakeupItem={setActiveMakeupItem}
                                activeMakeupType={activeMakeupType}

                            />
                        </div>
                        <div className="col-xl-5 col-md-12 col-lg-5 order-xl-3 order-md-3 order-3 rounded-3 bg-Opa my-2 animeClass widthSize cardMobile">
                            <div className="row g-0 widthSize">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 px-4 g-0 p-2 spacerMobile">
                                    {!comboMode ? <>
                                        {
                                            activeMakeupType.name === 'Foundation' &&
                                            <Foundation
                                            setProductData={setProductData}
                                                foundationType={foundationType}
                                                setFoundationType={setFoundationType}
                                                activeMakeupType={activeMakeupType}
                                                setActiveCategory={setActiveCategory}
                                            />
                                        }
                                        {showCategories && <div>
                                            <MakeupCategories
                                                activeMakeupType={activeMakeupType}
                                                activeCategory={activeCategory}
                                                setActiveCategory={setActiveCategory}
                                                activeMakeupItem={activeMakeupItem}
                                                setActiveMakeupItem={setActiveMakeupItem}
                                                setShowCategories={setShowCategories}
                                                setShowProducts={setShowProducts}
                                                activeProduct={activeProduct}
                                            />

                                            <div>
                                                <MakeupStyles
                                                    activeMakeupType={activeMakeupType}
                                                    activeMakeupStyle={activeMakeupStyle}
                                                    setActiveMakeupStyle={setActiveMakeupStyle}
                                                    appliedMakeupItems={appliedMakeupItems}
                                                    setAppliedMakeupItems={setAppliedMakeupItems}
                                                />
                                            </div>
                                        </div>}
                                        {activeMakeupType.name === 'Foundation' && foundationType === 2 &&
                                            <ShadePicker
                                                setShowProducts={setShowProducts}
                                                setProductData={setProductData}
                                            />}
                                        {activeMakeupType.name === 'Foundation' && foundationType === 3 && <CapturePhoto
                                            setShowProducts={setShowProducts}
                                            setProductData={setProductData} />}
                                        {!showCategories &&
                                            <>

                                                <MakeupProducts
                                                    activeMakeupType={activeMakeupType}
                                                    activeCategory={activeCategory}
                                                    setActiveCategory={setActiveCategory}
                                                    activeProduct={activeProduct}
                                                    setActiveProduct={setActiveProduct}
                                                    activeMakeupStyle={activeMakeupStyle}
                                                    activeMakeupItem={activeMakeupItem}
                                                    setActiveMakeupItem={setActiveMakeupItem}
                                                    appliedMakeupItems={appliedMakeupItems}
                                                    setAppliedMakeupItems={setAppliedMakeupItems}
                                                    setLikedProd={setLikedProd}
                                                    likedProd={likedProd}
                                                    setShowCategories={setShowCategories}
                                                    productData={productData}
                                                    setProductData={setProductData}
                                                    showProducts={showProducts}

                                                />
                                            </>}
                                        <div></div>
                                        <div></div>
                                    </>
                                        : <>
                                            <MakeupCombo
                                                activeMakeupStyle={activeMakeupStyle}
                                                appliedMakeupItems={appliedMakeupItems}
                                                setAppliedMakeupItems={setAppliedMakeupItems}
                                                activeCombo={activeCombo}
                                                setActiveCombo={setActiveCombo}
                                            ></MakeupCombo>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row g-0 justify-content-center">
                        <div
                            className="col-xl-2 col-md-12 col-lg-2 order-xl-2 order-md-1 order-1 m-2 text-center rounded-3 bg-Opa widthSize py-2 categoryAlign cardMobile">
                            <img src={logo} className="WidthClass d-none d-md-none d-lg-block" alt="logo" />
                            <MakeupTypes
                                makeupTypes={makeupTypes}
                                setMakeupTypes={setMakeupTypes}
                                activeMakeupType={activeMakeupType}
                                setActiveMakeupType={setActiveMakeupType}
                                activeMakeupItem={activeMakeupItem}
                                setActiveMakeupItem={setActiveMakeupItem}
                                setActiveCategory={setActiveCategory}
                                setActiveProduct={setActiveProduct}
                                setComboMode={setComboMode}
                            />
                        </div>
                        <div className="col-xl-5 col-md-12 col-lg-5 order-xl-2 order-md-2 rounded-3 m-2 order-2 bg-Opa cameraViewport height100vh">
                            <Tryon
                                setImageList={setImageList}
                                configData={getConfigDataState}
                                appliedMakeupItems={appliedMakeupItems}
                                setAppliedMakeupItems={setAppliedMakeupItems}
                                comboMode={comboMode}
                                setComboMode={setComboMode}
                                imageList={imageList}
                                tryOnMode={tryOnMode}
                                activeProduct={activeProduct}
                                setActiveProduct={setActiveProduct}
                                setLikedProd={setLikedProd}
                                likedProd={likedProd}
                                resetMakeupItems={resetMakeupItems}
                                makeupTypes={makeupTypes}
                                activeMakeupItem={activeMakeupItem}
                                setActiveMakeupItem={setActiveMakeupItem}
                                activeMakeupType={activeMakeupType}

                            />
                        </div>
                        <div className="col-xl-4 col-md-12 col-lg-4 order-xl-4 order-md-3 order-3 m-2 rounded-3 animeClass widthSize cardMobile card card-radius shadow">
                            <div className="row g-0 widthSize">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-10">
                                    {!comboMode ? <>
                                        {
                                            activeMakeupType.name === 'Foundation' &&
                                            <Foundation
                                            setProductData={setProductData}
                                                foundationType={foundationType}
                                                setFoundationType={setFoundationType}
                                                activeMakeupType={activeMakeupType}
                                                setActiveCategory={setActiveCategory}
                                            />
                                        }
                                        {showCategories && <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 g-0 p-3 spacerMobile shadow card-radius mb-3">
                                            <MakeupCategories
                                                activeMakeupType={activeMakeupType}
                                                activeCategory={activeCategory}
                                                setActiveCategory={setActiveCategory}
                                                activeMakeupItem={activeMakeupItem}
                                                setActiveMakeupItem={setActiveMakeupItem}
                                                setShowCategories={setShowCategories}
                                                setShowProducts={setShowProducts}
                                                activeProduct={activeProduct}
                                            />
                                            {/* FEL-417 to align slider left-right icons */}
                                            <div className="px-3">
                                                <MakeupStyles
                                                    activeMakeupType={activeMakeupType}
                                                    activeMakeupStyle={activeMakeupStyle}
                                                    setActiveMakeupStyle={setActiveMakeupStyle}
                                                    appliedMakeupItems={appliedMakeupItems}
                                                    setAppliedMakeupItems={setAppliedMakeupItems}
                                                />
                                            </div>
                                        </div>}
                                        {activeMakeupType.name === 'Foundation' && foundationType === 2 &&
                                            <ShadePicker
                                                setShowProducts={setShowProducts}
                                                setProductData={setProductData}
                                            />}
                                        {activeMakeupType.name === 'Foundation' && foundationType === 3 && <CapturePhoto
                                            setShowProducts={setShowProducts}
                                            setProductData={setProductData} />}
                                        <MakeupProducts
                                            activeMakeupType={activeMakeupType}
                                            activeCategory={activeCategory}
                                            setActiveCategory={setActiveCategory}
                                            activeProduct={activeProduct}
                                            setActiveProduct={setActiveProduct}
                                            activeMakeupStyle={activeMakeupStyle}
                                            activeMakeupItem={activeMakeupItem}
                                            setActiveMakeupItem={setActiveMakeupItem}
                                            appliedMakeupItems={appliedMakeupItems}
                                            setAppliedMakeupItems={setAppliedMakeupItems}
                                            setLikedProd={setLikedProd}
                                            likedProd={likedProd}
                                            productData={productData}
                                            setProductData={setProductData}
                                            showProducts={showProducts}
                                        />
                                        <div></div>
                                        <div></div>
                                    </>
                                        : <>
                                            <MakeupCombo
                                                activeMakeupStyle={activeMakeupStyle}
                                                appliedMakeupItems={appliedMakeupItems}
                                                setAppliedMakeupItems={setAppliedMakeupItems}
                                                activeCombo={activeCombo}
                                                setActiveCombo={setActiveCombo}
                                            ></MakeupCombo>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>}

            </div>
        </div>
    )
}

export default Layout