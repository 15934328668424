import React,{useState,useRef,useEffect} from 'react'
import { MakeupAPIs } from '../network/api';

export default function ShadePicker(props){
  let api = new MakeupAPIs();
  const [Skintones, setSkintones] = useState([]);
  const [Undertones, setUndertones] = useState([]);
  const [SelectedSkintone, setSelectedSkintone] = useState(null);
  const [SelectedUndertone, setSelectedUndertone] = useState(null);
  const currentSkintone = useRef(SelectedSkintone);
  const currentUndertone = useRef(SelectedUndertone);

  function skintoneClickHandler(e,id) {
    e.preventDefault();
    currentSkintone.current = id;
    setSelectedSkintone(id)
  }

  function undertoneClickHandler(e,id) {
    e.preventDefault();
    currentUndertone.current = id;
    setSelectedUndertone(id)
  }

  function getShadesForSkintone() {
    if (currentSkintone.current && currentUndertone.current) {
      api.getShadesForSkintone(currentSkintone.current,currentUndertone.current).then((res) => {
       props.setProductData(res.data);
       props.setShowProducts(true);
      });
    }

  }

  useEffect(() => {
    props.setShowProducts(false);
    api.getSkinTones().then((res)=>{
      setSkintones(res.data.skintones)
      setUndertones(res.data.undertones)
    })
    // eslint-disable-next-line
  }, []);

  return (
    <div>
       <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mobMl-0 FavMain px-4 shadePickerDiv">
        {/* <span className="mt-2 d-flex justify-content-center text-secondary">Select your skin tone</span> */}
        <div className="card p-4 shadow-sm card-radius">
          <span className="text-secondary">Let's select your skin tone, tap to select the one that’s closest to your skin.</span>
          <div className="mb-pallette mt-1 small">
            {Skintones.map((item, id) => (
              <button key={id} className={SelectedSkintone === item.id ? "mb-pallette-item border-0 skintone-btn active2":"mb-pallette-item border-0 skintone-btn"} onClick={(e) => {skintoneClickHandler(e,item.id);getShadesForSkintone(item.id)}} style={{width: "20%", backgroundColor: item.code}}></button>
            ))}
          </div>
        </div>
        <div className="card p-4 mt-2 shadow-sm card-radius mt-3 mb-3">
          <span className="text-secondary">What is your Undertone? Check out the veins on your wrist and select the closest.</span>
          <div className="mb-pallette mt-1 small text-center">
            {Undertones.map((item, id) => (
              <button key={id} className={SelectedUndertone === item.id ? "mb-pallette-item border-0 undertone-btn active2":"mb-pallette-item border-0 undertone-btn"} onClick={(e) => {undertoneClickHandler(e,item.id); getShadesForSkintone();}} style={{width: "20%", backgroundColor: item.code}}></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
