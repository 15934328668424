import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { MakeupAPIs } from '../network/api'
import { BASE_URL } from '../configs/configs'
import { chunk } from '../makeup_utils/common_utils';

export default function MakeupCombo(props) {

    const [comboData, setComboData] = useState([]);
    const [comboItems, setComboItems] = useState([]);
    const sliderRef = useRef();

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var combo_item_settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };

    useEffect(() => {

        let api = new MakeupAPIs();

        api.getAllMakeupCombos().then((res) => {
            // console.log(res)
            setComboData(res);
        });
      
    }, []);

    const comboClickHandler = (item) => {

        let applied_makeup = {};
        Object.entries(item?.template_param).forEach((value) => {
            applied_makeup[value[0]] = {
                id: value[1].id,
                color: value[1].color,
                code: value[1].code,
                product_type_code: value[1].product_type_code,
                product_code: value[1].product_code,
                intensity:value[1].intensity_web,
                style_id: props.activeMakeupStyle?.id,
                style_image: props.activeMakeupStyle?.image,
          
            }
        });
        props.setAppliedMakeupItems(applied_makeup);
        props.setActiveCombo(item.id);
        setComboItems(Object.entries(item?.template_param));

    }
    

    return (
        <>
            <div className="container shadow px-3 card-radius">
                <Slider {...settings} ref={sliderRef}>

                    {comboData.length > 0 && chunk(comboData, 6).map((item, key) => {
                        // return (
                            // <div className="row d-flex p-3">
                            // {item.map((element, id) => {
                                return (
                                <div className="row d-flex p-4" key={key}>
                                    {item.map((element, key) => {
                                        return (
                                        <div key={key} className={"col-4 col-md-4 col-lg-4 comboPart " + (props.activeCombo === element.id ? 'activeCombo' : '') }>
                                            <div className="p-1"
                                            onClick={(e) => {
                                                comboClickHandler(element)
                                            }}
                                            >
                                            <div className="d-flex align-items-center justify-content-between h-100 mt-2 mtNoneMob">
                                                <div className="w-100 text-center mb-2">
                                                <img
                                                    src={BASE_URL + element.template_icon}
                                                    className="img-fluid rounded"
                                                    alt="template icon"
                                                />
                                                </div>
                                            </div>
                                            <span className="font14 text-center d-none d-md-block">
                                                {element.template_name}
                                            </span>
                                        </div>
                                    </div>
                                        )
                                    })}
                            </div>
                        )
                    //     })}
                    // </div>
                    // )
                    })}
                </Slider>
                {/* </div> */}
                {/* <hr /> */}
            </div>
            <div className="container mt-5 p-2">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-10 mobMl-0 FavMain">
                        <div className='row px-3'>
                        <Slider {...combo_item_settings}>
                            {/* {console.log()} */}
                            {comboItems.map((item, index) => {
                            return (
                                <div key={index} className="col-4 col-md-4 col-lg-4 px-2">
                                    <div className="card p-2 card-radius-small">
                                    <div className="d-flex align-items-center justify-content-between h-100 mt-2 mtNoneMob">
                                        <div className="w-100 text-center minHeight40">
                                        <img src={BASE_URL + item[1].icon} className="img-fluid icW40" alt="icon"/>
                                        </div>
                                        <div className="w-100 text-center">
                                        <svg className="svgCircle" viewBox="0 0 40 40">
                                            <circle
                                            cx="20"
                                            cy="20"
                                            r="20"
                                            strokeWidth="4"
                                            fill={item[1].code}
                                            />
                                        </svg>
                                        </div>
                                    </div>
                                    <span className="font14 mt-2 text-center mb-0 d-none d-md-block">
                                        {item[0]}
                                    </span>
                                    </div>
                            </div>
                            );
                        })}
                        </Slider>
                        </div>
                    </div>
                </div>
            </div>
            </>
    )
}