import React, { useEffect } from 'react'
import { MakeupAPIs } from '../network/api';
import { getColor, loadProducts } from '../makeup_utils/common_utils';
import Slider from 'react-slick';
import useWindowDimensions from '../makeup_utils/window_dimensions';
import { applyMakeup } from '../makeup_utils/tryon_handler';

export default function MakeupProducts(props) {
    const uId = localStorage.getItem('uid')

    // const [productData, setProductData] = useState([]);
    const { width } = useWindowDimensions()

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                // desktop
                breakpoint: 99999,
                settings: "unslick",
            },
            // // tabs
            {
                breakpoint: 600,
                settings: "unslick",
            },

            // mobile
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4.5,
                    slidesToScroll: 4.5,
                    arrows: false,
                },
            },
        ],
    };

    useEffect(() => {
        if (props.activeCategory){
            loadProducts(props.activeCategory, props.setProductData);
        }
        // eslint-disable-next-line
    }, [props.activeCategory])
    
    // FEL-417 : Author - Dhruvil Mehta
    // For Handle Product Like Status
    const productLikeHandler = (item) => {
        let api = new MakeupAPIs()
        api.checkProdStatus(uId, item.id).then((res) => {
            if (res.result === 'ok') {
                props.setLikedProd(true)
            } else if (res.result === null) {
                props.setLikedProd(false)
            }
        })
        api.productCount(item.id);
    }
    useEffect(() => {
        productLikeHandler(props.activeProduct);      
        // eslint-disable-next-line
    }, [props.activeMakeupType])
    
    const productClickHandler = (item) => {
        props.setActiveProduct({ id: item.id, name: item.color_name });
        let data = {};
        let category = props.activeCategory;
        data[category] = { id: item.id, name: item.color_name };
        props.activeMakeupItem[props.activeMakeupType.id] = data;
        props.setActiveMakeupItem(props.activeMakeupItem);

        let applied_makeup = { ...props.appliedMakeupItems };
        applied_makeup[props.activeMakeupType.name] = {
            id: item.id,
            color: getColor(item.color_code),
            makeup_icon: props.activeMakeupType.icon,
            code: item.color_code,
            product_name: item.color_name,
            product_type_code: item.product_type_code,
            product_code: item.product_code,
            intensity: item.intensity_web,
            style_id: props.activeMakeupStyle?.id,
            style_image: props.activeMakeupStyle?.image,

        }

        props.setAppliedMakeupItems(applied_makeup);
        productLikeHandler(item);
    }

    const removeItemHandler = () => {
        props.setActiveProduct({ id: null, name: null });
        let data = {};
        let category = props.activeCategory;
        data[category] = { id: null, name: null };
        props.activeMakeupItem[props.activeMakeupType.id] = data;
        props.setActiveMakeupItem(props.activeMakeupItem);
        // FEL-417
        props.setLikedProd(false);

        delete props.appliedMakeupItems[props.activeMakeupType.name];
        applyMakeup(props.appliedMakeupItems);        
    }

    return (
        <>
            {props.showProducts && props.productData?.length > 0 &&
                <>
                    <div className="container widthSize">
                        <div className="text-center">
                            <p className="hr-line ml-2 mr-2 font14">{props.activeProduct.name || "Select Any Shade"}</p>
                        </div>
                    </div>
                    {
                        width <= 600 ?
                            <>
                                <div className="containers-fluid">

                                    <div className="row" >
                                        <div className="col-2">
                                            <button className="backButtonMobile" onClick={() => { props.setShowCategories(true); }}>
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="angle-left"
                                                    className="svg-inline--fa fa-angle-left fa-w-8 "
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 256 512"
                                                    color="#211616"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3 col-2">
                                            <div className="noneButtonMobileMain text-center m-2"><button
                                                onClick={removeItemHandler}
                                                className={"btn btn-sm rounded-circle btn-sm btnPdng " + (props.activeProduct.id === null ? 'active2' : '')}
                                                data-bs-toggle="tooltip" title="Clear">
                                                <div className="noneButtonMobile noneSelectMob"><svg aria-hidden="true"
                                                    focusable="false" data-prefix="fas" data-icon="ban"
                                                    className="svg-inline--fa fa-ban fa-w-16 " role="img"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                    color="#00000">
                                                    <path fill="currentColor"
                                                        d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z">
                                                    </path>
                                                </svg></div>
                                            </button></div>
                                        </div>
                                        <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-9 col-xs-9 col-8 px-0 mobMl-0 mobSliderMargin">
                                            {/* <div className="containers">
                            <div className="row g-0 flex-grow-1"></div>
                        </div> */}
                                            {props.productData?.length > 0 && <Slider {...settings}>
                                                {props.productData.map((item, id) => (
                                                    <div key={id} className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 text-center">
                                                        {/* FEL-417 */}
                                                        <div className="text-center posRelative m-2">
                                                            <div className="text-end favAbs"></div>
                                                            <div className="text-center"><button id={item.id} onClick={() => { productClickHandler(item) }}
                                                                // FEL-417 Added class for product highlight on like
                                                                className={"btn btn-sm rounded-circle btn-sm btnPdng " + (props.activeProduct.id === item.id ? 'active2' && props.likedProd ?'active2 activeLike':'active2' : '') }
                                                                data-bs-toggle="tooltip" title={item.color_name}><svg width="40" height="40">
                                                                    <circle cx="20" cy="20" r="20" strokeWidth="4"
                                                                        fill={item.color_code}></circle>
                                                                </svg></button></div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>}
                                        </div>

                                    </div>

                                </div>
                            </>
                            :
                            <div className="containers-fluid">
                            <div className="row g-0 flex-grow-1">
                                {/* FEL-417 */}
                                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3 mb-3">
                                        <div className="noneButtonMobileMain text-center m-2"><button
                                            onClick={removeItemHandler}
                                            className={"btn btn-sm rounded-circle btn-sm btnPdng " + (props.activeProduct.id === null ? 'active2' : '')}
                                            data-bs-toggle="tooltip" title="Clear">
                                            <div className="noneButtonMobile noneSelectMob"><svg aria-hidden="true"
                                                focusable="false" data-prefix="fas" data-icon="ban"
                                                className="svg-inline--fa fa-ban fa-w-16 " role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                color="#00000">
                                                <path fill="currentColor"
                                                    d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z">
                                                </path>
                                            </svg></div>
                                        </button></div>
                                    </div>
                                    {props.productData?.length > 0 && props.productData.map((item, key) => {
                                        return (
                                            <div key={key} className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3 mb-3">
                                                {/* FEL-417 */}
                                                <div className="text-center posRelative m-2">
                                                    <div className="text-end favAbs"></div>
                                                    <div className="text-center"><button id={item.id} onClick={() => { productClickHandler(item) }}
                                                        // FEL-417 Added class for product highlight on like
                                                        className={"btn btn-sm rounded-circle btn-sm btnPdng  " + (props.activeProduct.id === item.id ? props.likedProd ?'activeLike': 'active2' : '') }
                                                        data-bs-toggle="tooltip" title={item.color_name}><svg width="40" height="40">
                                                            <circle cx="20" cy="20" r="20" strokeWidth="4"
                                                                fill={item.color_code}></circle>
                                                        </svg></button></div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                    }
                </>}
        </>
    )
}
