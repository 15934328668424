//main();

//
// Start here
//
// import { abc } from './finalMapping';
import { mat4 } from 'gl-matrix';

function getLeftEyelinerProgramData(canvas, gl) {
  // If we don't have a GL context, give up now

  if (!gl) {
    alert(
      "Unable to initialize WebGL. Your browser or machine may not support it."
    );
    return;
  }

  // Vertex shader program

  const vsSource = `
    attribute vec3 aVertexPosition;
    attribute vec3 aColor;
    attribute vec2 aTexCoords;

    uniform mat4 uModelViewMatrix;
    uniform mat4 uProjectionMatrix;

    varying vec3 vColor;

    
    
    varying vec2 vTexCoords;
    //varying vec4 vAvgLuminance;
    //varying float vBrightness;
    //varying float vContrast;

    void main() {
        gl_Position = uProjectionMatrix * uModelViewMatrix * vec4(aVertexPosition.xyz,1.0);
        
        vColor = aColor;
        vTexCoords = aTexCoords;
    }
  `;

    // Fragment shader program

    const fsSource = `
    precision lowp float;
    uniform sampler2D uSampler;
    uniform  float uOpacity;
    uniform  float blendOpacity;
    uniform vec3 appliedColor;
    varying vec3 vColor;
    varying vec2 vTexCoords;
    void main() {
        vec4 color = vec4(1.0,1.0,1.0,uOpacity);
        vec4 lum = vec4(0.0,0.0,0.0,6.5);
        float brightness = 0.4;
        float contrast = 0.4;

      vec4 texColor = texture2D(uSampler,vTexCoords);
      // if(texColor.a > 0.25)
      // {
      //   texColor = vec4(appliedColor.rgb,1.0);
      // }

      //vec4(1,1,1,0) or vec4(0,0,0,0)
      //vec4(0,0,0,1)
      //gl_FragColor = vec4(mix((texColor * brightness) , mix(lum,texColor,contrast),0.5).rgb,0.75);
      vec3 newColor = texColor.rgb + appliedColor;
      // vec3 newColor = texColor.rgb + vec3((56/255),(23/255),(60/255));
      
      vec3 mixedColor = mix(newColor,vec3(0,0,0),blendOpacity);
    
      gl_FragColor = vec4(mixedColor.rgb,texColor.a) *color ;
      // gl_FragColor = texColor * color;
      // gl_FragColor = texColor;
      // gl_FragColor = vec4(0.0,0.0,0.0,1.0);
      //gl_FragColor = vec4(texColor.rgb,0.45);
      //gl_FragColor = texture2D(uSampler,vTexCoords);
    }
  `;



  // Initialize a shader program; this is where all the lighting
  // for the vertices and so forth is established.
  const shaderProgramMakeup = initShaderProgram(gl, vsSource, fsSource);
  const programInfoMakeup = {
    program: shaderProgramMakeup,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(
        shaderProgramMakeup,
        "aVertexPosition"
      ),
      
      colorPosition: gl.getAttribLocation(shaderProgramMakeup, "aColor"),
      texCoords: gl.getAttribLocation(shaderProgramMakeup, "aTexCoords"),
    },
    uniformLocations: {
      appliedColorPositon : gl.getUniformLocation(
        shaderProgramMakeup,
        "appliedColor"
      ),
      projectionMatrix: gl.getUniformLocation(
        shaderProgramMakeup,
        "uProjectionMatrix"
      ),
      modelViewMatrix: gl.getUniformLocation(
        shaderProgramMakeup,
        "uModelViewMatrix"
      ),
      sampler: gl.getUniformLocation(shaderProgramMakeup, "uSampler"),
      opacity: gl.getUniformLocation(shaderProgramMakeup, "uOpacity"),
    },
  };

  // Here's where we call the routine that builds all the
  // objects we'll be drawing.
  const buffers = null;

  const texture = null;


  //   // Draw the scene repeatedly
  //   function render(now) {
  //     now *= 0.001;  // convert to seconds
  //     const deltaTime = now - then;
  //     then = now;

  //     drawScene(gl, programInfo, buffers, texture, deltaTime);

  //     requestAnimationFrame(render);
  //   }
  //   requestAnimationFrame(render);

  return {
    programInfo: programInfoMakeup,
    shaderProgram: shaderProgramMakeup,
    buffers: buffers,
    texture: texture,
  };
}

//
// initBuffers
//
// Initialize the buffers we'll need. For this demo, we just
// have one object -- a simple three-dimensional cube.
//
function initLeftEyelinerBuffers(gl, landmarks) {
  // Create a buffer for the square's positions.

  const positionBuffer = gl.createBuffer();
  //const colorBuffer = gl.createBuffer();
  const indexBuffer = gl.createBuffer();
  // Select the positionBuffer as the one to apply buffer
  // operations to from here out.

  // Now create an array of positions for the square.


  var eyeshadowVertices = [];
  var texels = [];
  var texelsTwo = [];
  for (let i = 0; i < landmarks.length; i++) {
    eyeshadowVertices.push(landmarks[i].x)
    eyeshadowVertices.push(landmarks[i].y - 0.0019)
    eyeshadowVertices.push(landmarks[i].z)

  }

  // for (var i = 0; i < landmarks.length; i++) {
  //   // if (eyeliner[i] == 130 || eyeliner[i] == 226 || eyeliner[i] == 35) {
  //   if (eyeliner[i] == 130 || eyeliner[i] == 226) {
  //     eyeshadowVertices.push(landmarks[eyeliner[i]].x);
  //     eyeshadowVertices.push(landmarks[33].y);
  //     eyeshadowVertices.push(landmarks[eyeliner[i]].z);
  //   } else {
  //     eyeshadowVertices.push(landmarks[eyeliner[i]].x);
  //     eyeshadowVertices.push(landmarks[eyeliner[i]].y);
  //     eyeshadowVertices.push(landmarks[eyeliner[i]].z);
  //   }

  //   texels.push(0.5);
  //   texels.push(0.5);
  // }

  // for (var i = 0; i < rightEyeshadow.length; i++) {
  //     eyeshadowVertices.push(landmarks[rightEyeshadow[i]].x);
  //     eyeshadowVertices.push(landmarks[rightEyeshadow[i]].y);
  //     eyeshadowVertices.push(landmarks[rightEyeshadow[i]].z);
  // }

  // eyeshadowVertices = [];
  // console.log("EYESHADOW VERTICES::::",eyeshadowVertices);
  var drawOrder = [
      173, 155, 133,
      246, 33, 7,
      382, 398, 362,
      263, 466, 249,
      308, 415, 324,
      78, 95, 191,
      356, 389, 264,
      127, 34, 162,
      368, 264, 389,
      139, 162, 34,
      267, 0, 302,
      37, 72, 0,
      11, 302, 0,
      11, 0, 72,
      349, 451, 350,
      120, 121, 231,
      452, 350, 451,
      232, 231, 121,
      267, 302, 269,
      37, 39, 72,
      303, 269, 302,
      73, 72, 39,
      357, 343, 350,
      128, 121, 114,
      277, 350, 343,
      47, 114, 121,
      350, 452, 357,
      121, 128, 232,
      453, 357, 452,
      233, 232, 128,
      299, 333, 297,
      69, 67, 104,
      332, 297, 333,
      103, 104, 67,
      175, 152, 396,
      175, 171, 152,
      377, 396, 152,
      148, 152, 171,
      381, 384, 382,
      154, 155, 157,
      398, 382, 384,
      173, 157, 155,
      280, 347, 330,
      50, 101, 118,
      348, 330, 347,
      119, 118, 101,
      269, 303, 270,
      39, 40, 73,
      304, 270, 303,
      74, 73, 40,
      9, 336, 151,
      9, 151, 107,
      337, 151, 336,
      108, 107, 151,
      344, 278, 360,
      115, 131, 48,
      279, 360, 278,
      49, 48, 131,
      262, 431, 418,
      32, 194, 211,
      424, 418, 431,
      204, 211, 194,
      304, 408, 270,
      74, 40, 184,
      409, 270, 408,
      185, 184, 40,
      272, 310, 407,
      42, 183, 80,
      415, 407, 310,
      191, 80, 183,
      322, 270, 410,
      92, 186, 40,
      409, 410, 270,
      185, 40, 186,
      347, 449, 348,
      118, 119, 229,
      450, 348, 449,
      230, 229, 119,
      434, 432, 430,
      214, 210, 212,
      422, 430, 432,
      202, 212, 210,
      313, 314, 18,
      83, 18, 84,
      17, 18, 314,
      17, 84, 18,
      307, 375, 306,
      77, 76, 146,
      291, 306, 375,
      61, 146, 76,
      259, 387, 260,
      29, 30, 160,
      388, 260, 387,
      161, 160, 30,
      286, 414, 384,
      56, 157, 190,
      398, 384, 414,
      173, 190, 157,
      418, 424, 406,
      194, 182, 204,
      335, 406, 424,
      106, 204, 182,
      367, 416, 364,
      138, 135, 192,
      434, 364, 416,
      214, 192, 135,
      391, 423, 327,
      165, 98, 203,
      358, 327, 423,
      129, 203, 98,
      298, 301, 284,
      68, 54, 71,
      251, 284, 301,
      21, 71, 54,
      4, 275, 5,
      4, 5, 45,
      281, 5, 275,
      51, 45, 5,
      254, 373, 253,
      24, 23, 144,
      374, 253, 373,
      145, 144, 23,
      320, 321, 307,
      90, 77, 91,
      375, 307, 321,
      146, 91, 77,
      280, 425, 411,
      50, 187, 205,
      427, 411, 425,
      207, 205, 187,
      421, 313, 200,
      201, 200, 83,
      18, 200, 313,
      18, 83, 200,
      335, 321, 406,
      106, 182, 91,
      405, 406, 321,
      181, 91, 182,
      405, 321, 404,
      181, 180, 91,
      320, 404, 321,
      90, 91, 180,
      17, 314, 16,
      17, 16, 84,
      315, 16, 314,
      85, 84, 16,
      425, 266, 426,
      205, 206, 36,
      423, 426, 266,
      203, 36, 206,
      369, 396, 400,
      140, 176, 171,
      377, 400, 396,
      148, 171, 176,
      391, 269, 322,
      165, 92, 39,
      270, 322, 269,
      40, 39, 92,
      417, 465, 413,
      193, 189, 245,
      464, 413, 465,
      244, 245, 189,
      257, 258, 386,
      27, 159, 28,
      385, 386, 258,
      158, 28, 159,
      260, 388, 467,
      30, 247, 161,
      466, 467, 388,
      246, 161, 247,
      248, 456, 419,
      3, 196, 236,
      399, 419, 456,
      174, 236, 196,
      333, 298, 332,
      104, 103, 68,
      284, 332, 298,
      54, 68, 103,
      285, 8, 417,
      55, 193, 8,
      168, 417, 8,
      168, 8, 193,
      340, 261, 346,
      111, 117, 31,
      448, 346, 261,
      228, 31, 117,
      285, 417, 441,
      55, 221, 193,
      413, 441, 417,
      189, 193, 221,
      327, 460, 326,
      98, 97, 240,
      328, 326, 460,
      99, 240, 97,
      277, 355, 329,
      47, 100, 126,
      371, 329, 355,
      142, 126, 100,
      309, 392, 438,
      79, 218, 166,
      439, 438, 392,
      219, 166, 218,
      381, 382, 256,
      154, 26, 155,
      341, 256, 382,
      112, 155, 26,
      360, 279, 420,
      131, 198, 49,
      429, 420, 279,
      209, 49, 198,
      365, 364, 379,
      136, 150, 135,
      394, 379, 364,
      169, 135, 150,
      355, 277, 437,
      126, 217, 47,
      343, 437, 277,
      114, 47, 217,
      443, 444, 282,
      223, 52, 224,
      283, 282, 444,
      53, 224, 52,
      281, 275, 363,
      51, 134, 45,
      440, 363, 275,
      220, 45, 134,
      431, 262, 395,
      211, 170, 32,
      369, 395, 262,
      140, 32, 170,
      337, 299, 338,
      108, 109, 69,
      297, 338, 299,
      67, 69, 109,
      335, 273, 321,
      106, 91, 43,
      375, 321, 273,
      146, 43, 91,
      348, 450, 349,
      119, 120, 230,
      451, 349, 450,
      231, 230, 120,
      467, 359, 342,
      247, 113, 130,
      446, 342, 359,
      226, 130, 113,
      282, 283, 334,
      52, 105, 53,
      293, 334, 283,
      63, 53, 105,
      250, 458, 462,
      20, 242, 238,
      461, 462, 458,
      241, 238, 242,
      276, 353, 300,
      46, 70, 124,
      383, 300, 353,
      156, 124, 70,
      325, 292, 324,
      96, 95, 62,
      308, 324, 292,
      78, 62, 95,
      283, 276, 293,
      53, 63, 46,
      300, 293, 276,
      70, 46, 63,
      447, 264, 345,
      227, 116, 34,
      372, 345, 264,
      143, 34, 116,
      352, 345, 346,
      123, 117, 116,
      340, 346, 345,
      111, 116, 117,
      1, 19, 274,
      1, 44, 19,
      354, 274, 19,
      125, 19, 44,
      248, 281, 456,
      3, 236, 51,
      363, 456, 281,
      134, 51, 236,
      425, 426, 427,
      205, 207, 206,
      436, 427, 426,
      216, 206, 207,
      380, 381, 252,
      153, 22, 154,
      256, 252, 381,
      26, 154, 22,
      391, 393, 269,
      165, 39, 167,
      267, 269, 393,
      37, 167, 39,
      199, 428, 200,
      199, 200, 208,
      421, 200, 428,
      201, 208, 200,
      330, 329, 266,
      101, 36, 100,
      371, 266, 329,
      142, 100, 36,
      422, 432, 273,
      202, 43, 212,
      287, 273, 432,
      57, 212, 43,
      290, 250, 328,
      60, 99, 20,
      462, 328, 250,
      242, 20, 99,
      258, 286, 385,
      28, 158, 56,
      384, 385, 286,
      157, 56, 158,
      342, 446, 353,
      113, 124, 226,
      265, 353, 446,
      35, 226, 124,
      257, 386, 259,
      27, 29, 159,
      387, 259, 386,
      160, 159, 29,
      430, 422, 431,
      210, 211, 202,
      424, 431, 422,
      204, 202, 211,
      445, 342, 276,
      225, 46, 113,
      353, 276, 342,
      124, 113, 46,
      424, 422, 335,
      204, 106, 202,
      273, 335, 422,
      43, 202, 106,
      306, 292, 307,
      76, 77, 62,
      325, 307, 292,
      96, 62, 77,
      366, 447, 352,
      137, 123, 227,
      345, 352, 447,
      116, 227, 123,
      302, 268, 303,
      72, 73, 38,
      271, 303, 268,
      41, 38, 73,
      371, 358, 266,
      142, 36, 129,
      423, 266, 358,
      203, 129, 36,
      327, 294, 460,
      98, 240, 64,
      455, 460, 294,
      235, 64, 240,
      294, 331, 278,
      64, 48, 102,
      279, 278, 331,
      49, 102, 48,
      303, 271, 304,
      73, 74, 41,
      272, 304, 271,
      42, 41, 74,
      427, 436, 434,
      207, 214, 216,
      432, 434, 436,
      212, 216, 214,
      304, 272, 408,
      74, 184, 42,
      407, 408, 272,
      183, 42, 184,
      394, 430, 395,
      169, 170, 210,
      431, 395, 430,
      211, 210, 170,
      395, 369, 378,
      170, 149, 140,
      400, 378, 369,
      176, 140, 149,
      296, 334, 299,
      66, 69, 105,
      333, 299, 334,
      104, 105, 69,
      417, 168, 351,
      193, 122, 168,
      6, 351, 168,
      6, 168, 122,
      280, 411, 352,
      50, 123, 187,
      376, 352, 411,
      147, 187, 123,
      319, 320, 325,
      89, 96, 90,
      307, 325, 320,
      77, 90, 96,
      285, 295, 336,
      55, 107, 65,
      296, 336, 295,
      66, 65, 107,
      404, 320, 403,
      180, 179, 90,
      319, 403, 320,
      89, 90, 179,
      330, 348, 329,
      101, 100, 119,
      349, 329, 348,
      120, 119, 100,
      334, 293, 333,
      105, 104, 63,
      298, 333, 293,
      68, 63, 104,
      323, 454, 366,
      93, 137, 234,
      447, 366, 454,
      227, 234, 137,
      16, 315, 15,
      16, 15, 85,
      316, 15, 315,
      86, 85, 15,
      429, 279, 358,
      209, 129, 49,
      331, 358, 279,
      102, 49, 129,
      15, 316, 14,
      15, 14, 86,
      317, 14, 316,
      87, 86, 14,
      8, 285, 9,
      8, 9, 55,
      336, 9, 285,
      107, 55, 9,
      329, 349, 277,
      100, 47, 120,
      350, 277, 349,
      121, 120, 47,
      252, 253, 380,
      22, 153, 23,
      374, 380, 253,
      145, 23, 153,
      402, 403, 318,
      178, 88, 179,
      319, 318, 403,
      89, 179, 88,
      351, 6, 419,
      122, 196, 6,
      197, 419, 6,
      197, 6, 196,
      324, 318, 325,
      95, 96, 88,
      319, 325, 318,
      89, 88, 96,
      397, 367, 365,
      172, 136, 138,
      364, 365, 367,
      135, 138, 136,
      288, 435, 397,
      58, 172, 215,
      367, 397, 435,
      138, 215, 172,
      438, 439, 344,
      218, 115, 219,
      278, 344, 439,
      48, 219, 115,
      271, 311, 272,
      41, 42, 81,
      310, 272, 311,
      80, 81, 42,
      5, 281, 195,
      5, 195, 51,
      248, 195, 281,
      3, 51, 195,
      273, 287, 375,
      43, 146, 57,
      291, 375, 287,
      61, 57, 146,
      396, 428, 175,
      171, 175, 208,
      199, 175, 428,
      199, 208, 175,
      268, 312, 271,
      38, 41, 82,
      311, 271, 312,
      81, 82, 41,
      444, 445, 283,
      224, 53, 225,
      276, 283, 445,
      46, 225, 53,
      254, 339, 373,
      24, 144, 110,
      390, 373, 339,
      163, 110, 144,
      295, 282, 296,
      65, 66, 52,
      334, 296, 282,
      105, 52, 66,
      346, 448, 347,
      117, 118, 228,
      449, 347, 448,
      229, 228, 118,
      454, 356, 447,
      234, 227, 127,
      264, 447, 356,
      34, 127, 227,
      336, 296, 337,
      107, 108, 66,
      299, 337, 296,
      69, 66, 108,
      151, 337, 10,
      151, 10, 108,
      338, 10, 337,
      109, 108, 10,
      278, 439, 294,
      48, 64, 219,
      455, 294, 439,
      235, 219, 64,
      407, 415, 292,
      183, 62, 191,
      308, 292, 415,
      78, 191, 62,
      358, 371, 429,
      129, 209, 142,
      355, 429, 371,
      126, 142, 209,
      345, 372, 340,
      116, 111, 143,
      265, 340, 372,
      35, 143, 111,
      388, 390, 466,
      161, 246, 163,
      249, 466, 390,
      7, 163, 246,
      352, 346, 280,
      123, 50, 117,
      347, 280, 346,
      118, 117, 50,
      295, 442, 282,
      65, 52, 222,
      443, 282, 442,
      223, 222, 52,
      19, 94, 354,
      19, 125, 94,
      370, 354, 94,
      141, 94, 125,
      295, 285, 442,
      65, 222, 55,
      441, 442, 285,
      221, 55, 222,
      419, 197, 248,
      196, 3, 197,
      195, 248, 197,
      195, 197, 3,
      359, 263, 255,
      130, 25, 33,
      249, 255, 263,
      7, 33, 25,
      275, 274, 440,
      45, 220, 44,
      457, 440, 274,
      237, 44, 220,
      300, 383, 301,
      70, 71, 156,
      368, 301, 383,
      139, 156, 71,
      417, 351, 465,
      193, 245, 122,
      412, 465, 351,
      188, 122, 245,
      466, 263, 467,
      246, 247, 33,
      359, 467, 263,
      130, 33, 247,
      389, 251, 368,
      162, 139, 21,
      301, 368, 251,
      71, 21, 139,
      374, 386, 380,
      145, 153, 159,
      385, 380, 386,
      158, 159, 153,
      379, 394, 378,
      150, 149, 169,
      395, 378, 394,
      170, 169, 149,
      351, 419, 412,
      122, 188, 196,
      399, 412, 419,
      174, 196, 188,
      426, 322, 436,
      206, 216, 92,
      410, 436, 322,
      186, 92, 216,
      387, 373, 388,
      160, 161, 144,
      390, 388, 373,
      163, 144, 161,
      393, 326, 164,
      167, 164, 97,
      2, 164, 326,
      2, 97, 164,
      354, 370, 461,
      125, 241, 141,
      462, 461, 370,
      242, 141, 241,
      0, 267, 164,
      0, 164, 37,
      393, 164, 267,
      167, 37, 164,
      11, 12, 302,
      11, 72, 12,
      268, 302, 12,
      38, 12, 72,
      386, 374, 387,
      159, 160, 145,
      373, 387, 374,
      144, 145, 160,
      12, 13, 268,
      12, 38, 13,
      312, 268, 13,
      82, 13, 38,
      293, 300, 298,
      63, 68, 70,
      301, 298, 300,
      71, 70, 68,
      340, 265, 261,
      111, 31, 35,
      446, 261, 265,
      226, 35, 31,
      380, 385, 381,
      153, 154, 158,
      384, 381, 385,
      157, 158, 154,
      280, 330, 425,
      50, 205, 101,
      266, 425, 330,
      36, 101, 205,
      423, 391, 426,
      203, 206, 165,
      322, 426, 391,
      92, 165, 206,
      429, 355, 420,
      209, 198, 126,
      437, 420, 355,
      217, 126, 198,
      391, 327, 393,
      165, 167, 98,
      326, 393, 327,
      97, 98, 167,
      457, 438, 440,
      237, 220, 218,
      344, 440, 438,
      115, 218, 220,
      382, 362, 341,
      155, 112, 133,
      463, 341, 362,
      243, 133, 112,
      457, 461, 459,
      237, 239, 241,
      458, 459, 461,
      238, 241, 239,
      434, 430, 364,
      214, 135, 210,
      394, 364, 430,
      169, 210, 135,
      414, 463, 398,
      190, 173, 243,
      362, 398, 463,
      133, 243, 173,
      262, 428, 369,
      32, 140, 208,
      396, 369, 428,
      171, 208, 140,
      457, 274, 461,
      237, 241, 44,
      354, 461, 274,
      125, 44, 241,
      316, 403, 317,
      86, 87, 179,
      402, 317, 403,
      178, 179, 87,
      315, 404, 316,
      85, 86, 180,
      403, 316, 404,
      179, 180, 86,
      314, 405, 315,
      84, 85, 181,
      404, 315, 405,
      180, 181, 85,
      313, 406, 314,
      83, 84, 182,
      405, 314, 406,
      181, 182, 84,
      418, 406, 421,
      194, 201, 182,
      313, 421, 406,
      83, 182, 201,
      366, 401, 323,
      137, 93, 177,
      361, 323, 401,
      132, 177, 93,
      408, 407, 306,
      184, 76, 183,
      292, 306, 407,
      62, 183, 76,
      408, 306, 409,
      184, 185, 76,
      291, 409, 306,
      61, 76, 185,
      410, 409, 287,
      186, 57, 185,
      291, 287, 409,
      61, 185, 57,
      436, 410, 432,
      216, 212, 186,
      287, 432, 410,
      57, 186, 212,
      434, 416, 427,
      214, 207, 192,
      411, 427, 416,
      187, 192, 207,
      264, 368, 372,
      34, 143, 139,
      383, 372, 368,
      156, 139, 143,
      457, 459, 438,
      237, 218, 239,
      309, 438, 459,
      79, 239, 218,
      352, 376, 366,
      123, 137, 147,
      401, 366, 376,
      177, 147, 137,
      4, 1, 275,
      4, 45, 1,
      274, 275, 1,
      44, 1, 45,
      428, 262, 421,
      208, 201, 32,
      418, 421, 262,
      194, 32, 201,
      327, 358, 294,
      98, 64, 129,
      331, 294, 358,
      102, 129, 64,
      367, 435, 416,
      138, 192, 215,
      433, 416, 435,
      213, 215, 192,
      455, 439, 289,
      235, 59, 219,
      392, 289, 439,
      166, 219, 59,
      328, 462, 326,
      99, 97, 242,
      370, 326, 462,
      141, 242, 97,
      326, 370, 2,
      97, 2, 141,
      94, 2, 370,
      94, 141, 2,
      460, 455, 305,
      240, 75, 235,
      289, 305, 455,
      59, 235, 75,
      448, 339, 449,
      228, 229, 110,
      254, 449, 339,
      24, 110, 229,
      261, 446, 255,
      31, 25, 226,
      359, 255, 446,
      130, 226, 25,
      449, 254, 450,
      229, 230, 24,
      253, 450, 254,
      23, 24, 230,
      450, 253, 451,
      230, 231, 23,
      252, 451, 253,
      22, 23, 231,
      451, 252, 452,
      231, 232, 22,
      256, 452, 252,
      26, 22, 232,
      256, 341, 452,
      26, 232, 112,
      453, 452, 341,
      233, 112, 232,
      413, 464, 414,
      189, 190, 244,
      463, 414, 464,
      243, 244, 190,
      441, 413, 286,
      221, 56, 189,
      414, 286, 413,
      190, 189, 56,
      441, 286, 442,
      221, 222, 56,
      258, 442, 286,
      28, 56, 222,
      442, 258, 443,
      222, 223, 28,
      257, 443, 258,
      27, 28, 223,
      444, 443, 259,
      224, 29, 223,
      257, 259, 443,
      27, 223, 29,
      259, 260, 444,
      29, 224, 30,
      445, 444, 260,
      225, 30, 224,
      260, 467, 445,
      30, 225, 247,
      342, 445, 467,
      113, 247, 225,
      250, 309, 458,
      20, 238, 79,
      459, 458, 309,
      239, 79, 238,
      290, 305, 392,
      60, 166, 75,
      289, 392, 305,
      59, 75, 166,
      460, 305, 328,
      240, 99, 75,
      290, 328, 305,
      60, 75, 99,
      376, 433, 401,
      147, 177, 213,
      435, 401, 433,
      215, 213, 177,
      250, 290, 309,
      20, 79, 60,
      392, 309, 290,
      166, 60, 79,
      411, 416, 376,
      187, 147, 192,
      433, 376, 416,
      213, 192, 147,
      341, 463, 453,
      112, 233, 243,
      464, 453, 463,
      244, 243, 233,
      453, 464, 357,
      233, 128, 244,
      465, 357, 464,
      245, 244, 128,
      412, 343, 465,
      188, 245, 114,
      357, 465, 343,
      128, 114, 245,
      437, 343, 399,
      217, 174, 114,
      412, 399, 343,
      188, 114, 174,
      363, 440, 360,
      134, 131, 220,
      344, 360, 440,
      115, 220, 131,
      456, 420, 399,
      236, 174, 198,
      437, 399, 420,
      217, 198, 174,
      456, 363, 420,
      236, 198, 134,
      360, 420, 363,
      131, 134, 198,
      361, 401, 288,
      132, 58, 177,
      435, 288, 401,
      215, 177, 58,
      353, 265, 383,
      124, 156, 35,
      372, 383, 265,
      143, 35, 156,
      255, 249, 339,
      25, 110, 7,
      390, 339, 249,
      163, 7, 110,
      261, 255, 448,
      31, 228, 25,
      339, 448, 255,
      110, 25, 228,
      14, 317, 13,
      14, 13, 87,
      312, 13, 317,
      82, 87, 13,
      317, 402, 312,
      87, 82, 178,
      311, 312, 402,
      81, 178, 82,
      402, 318, 311,
      178, 81, 88,
      310, 311, 318,
      80, 88, 81,
      318, 324, 310,
      88, 80, 95,
      415, 310, 324,
      191, 95, 80

    ];
    // for(let i = 0;i<drawOrder.length;i++){
    //   eyeshadowVertices.push(landmarks[drawOrder[i]].x);
    //   eyeshadowVertices.push(landmarks[drawOrder[i]].y);
    //   eyeshadowVertices.push(landmarks[drawOrder[i]].z);
    // }

    //eslint-disable-next-line
    texels = [
      0.408772, 0.626106,
      0.410373, 0.608920,
      0.419054, 0.612845,
      0.289712, 0.631747,
      0.280098, 0.624400,
      0.289712, 0.619236,
      0.589660, 0.608938,
      0.591234, 0.626106,
      0.580985, 0.612840,
      0.719902, 0.624400,
      0.710288, 0.631747,
      0.710288, 0.619236,
      0.596371, 0.306047,
      0.586614, 0.307634,
      0.586800, 0.304600,
      0.403629, 0.306047,
      0.413200, 0.304600,
      0.413386, 0.307634,
      0.980531, 0.598436,
      0.941867, 0.680924,
      0.893693, 0.600040,
      0.019469, 0.598436,
      0.106310, 0.600044,
      0.058133, 0.680924,
      0.865595, 0.666313,
      0.134410, 0.666317,
      0.528249, 0.349596,
      0.499977, 0.347466,
      0.527121, 0.333802,
      0.471751, 0.349596,
      0.472879, 0.333802,
      0.500023, 0.333766,
      0.631101, 0.552846,
      0.633505, 0.573912,
      0.600862, 0.567527,
      0.368756, 0.552793,
      0.398964, 0.567345,
      0.366427, 0.573884,
      0.603876, 0.583413,
      0.396012, 0.583304,
      0.560215, 0.342771,
      0.439785, 0.342771,
      0.553172, 0.331473,
      0.446828, 0.331473,
      0.573500, 0.580000,
      0.572156, 0.562348,
      0.426243, 0.579569,
      0.427685, 0.562039,
      0.598631, 0.545021,
      0.401223, 0.544828,
      0.579658, 0.590055,
      0.420121, 0.589772,
      0.635536, 0.810751,
      0.709250, 0.798492,
      0.668509, 0.880086,
      0.364501, 0.810886,
      0.331431, 0.880286,
      0.290777, 0.798554,
      0.757824, 0.852324,
      0.242176, 0.852324,
      0.499877, 0.091010,
      0.499977, 0.045547,
      0.559100, 0.097368,
      0.440512, 0.097581,
      0.568013, 0.055435,
      0.430987, 0.055935,
      0.606793, 0.604463,
      0.611335, 0.637716,
      0.393207, 0.604463,
      0.388665, 0.637716,
      0.723087, 0.467946,
      0.713757, 0.532373,
      0.665586, 0.504049,
      0.276896, 0.467943,
      0.334562, 0.503927,
      0.286267, 0.532325,
      0.667113, 0.539327,
      0.332828, 0.539288,
      0.585384, 0.333459,
      0.414617, 0.333459,
      0.577238, 0.326110,
      0.422762, 0.326110,
      0.499987, 0.730081,
      0.558266, 0.738328,
      0.500023, 0.809424,
      0.441728, 0.738324,
      0.570304, 0.812129,
      0.429765, 0.812166,
      0.551868, 0.463430,
      0.570338, 0.451425,
      0.560611, 0.480983,
      0.448340, 0.463064,
      0.439121, 0.481042,
      0.429819, 0.451385,
      0.578632, 0.466377,
      0.421352, 0.466259,
      0.602918, 0.157137,
      0.645735, 0.187360,
      0.588371, 0.195559,
      0.396889, 0.157245,
      0.411362, 0.195673,
      0.354128, 0.187447,
      0.625560, 0.219688,
      0.374293, 0.219815,
      0.599262, 0.318931,
      0.400738, 0.318931,
      0.607600, 0.322297,
      0.392400, 0.322297,
      0.571228, 0.317308,
      0.568842, 0.307634,
      0.593203, 0.314324,
      0.428771, 0.317309,
      0.406787, 0.314327,
      0.431158, 0.307634,
      0.609945, 0.360090,
      0.631938, 0.336500,
      0.390095, 0.360427,
      0.367856, 0.336081,
      0.702114, 0.566837,
      0.297879, 0.566824,
      0.666525, 0.566134,
      0.333434, 0.566122,
      0.720122, 0.285333,
      0.675343, 0.296022,
      0.685945, 0.224643,
      0.279777, 0.285342,
      0.313951, 0.224692,
      0.324548, 0.296007,
      0.655317, 0.254485,
      0.344549, 0.254561,
      0.534090, 0.220859,
      0.527671, 0.263774,
      0.499968, 0.218629,
      0.465828, 0.220810,
      0.472329, 0.263774,
      0.499977, 0.262981,
      0.596961, 0.293460,
      0.603900, 0.289783,
      0.611897, 0.306039,
      0.403039, 0.293460,
      0.388103, 0.306039,
      0.396100, 0.289783,
      0.618026, 0.305289,
      0.381974, 0.305289,
      0.680678, 0.652735,
      0.681215, 0.641660,
      0.702097, 0.646409,
      0.319322, 0.652735,
      0.297903, 0.646409,
      0.318785, 0.641660,
      0.698585, 0.636844,
      0.301415, 0.636844,
      0.607610, 0.646112,
      0.583841, 0.631286,
      0.392390, 0.646112,
      0.416164, 0.631286,
      0.569944, 0.232965,
      0.430012, 0.233191,
      0.600409, 0.250995,
      0.399510, 0.251079,
      0.816351, 0.259740,
      0.771915, 0.316422,
      0.760966, 0.220247,
      0.183610, 0.259743,
      0.238979, 0.220255,
      0.228018, 0.316428,
      0.584177, 0.375893,
      0.621009, 0.425982,
      0.577268, 0.414065,
      0.415838, 0.375804,
      0.422729, 0.414015,
      0.378909, 0.425990,
      0.602995, 0.451312,
      0.396993, 0.451203,
      0.770092, 0.767979,
      0.826722, 0.721245,
      0.830705, 0.806186,
      0.229924, 0.767997,
      0.169295, 0.806186,
      0.173287, 0.721252,
      0.895093, 0.745859,
      0.104907, 0.745859,
      0.500151, 0.472844,
      0.515097, 0.472748,
      0.499910, 0.501747,
      0.485301, 0.472605,
      0.516446, 0.500361,
      0.483370, 0.500413,
      0.688880, 0.590540,
      0.681215, 0.603765,
      0.661242, 0.586975,
      0.311120, 0.590540,
      0.338758, 0.586975,
      0.318785, 0.603765,
      0.656636, 0.599403,
      0.343364, 0.599403,
      0.576838, 0.288154,
      0.581691, 0.279937,
      0.423162, 0.288154,
      0.418309, 0.279937,
      0.680198, 0.429281,
      0.752033, 0.398685,
      0.247923, 0.398667,
      0.319688, 0.429262,
      0.704663, 0.378470,
      0.295284, 0.378419,
      0.543283, 0.180745,
      0.499913, 0.178271,
      0.456549, 0.180799,
      0.555168, 0.269206,
      0.444832, 0.269206,
      0.552888, 0.284192,
      0.447112, 0.284192,
      0.499977, 0.280615,
      0.526913, 0.282143,
      0.473087, 0.282143,
      0.643998, 0.465512,
      0.642764, 0.395662,
      0.357155, 0.395730,
      0.355808, 0.465594,
      0.614074, 0.116754,
      0.624563, 0.075808,
      0.385764, 0.116846,
      0.375437, 0.075808,
      0.531597, 0.647517,
      0.549756, 0.600249,
      0.563544, 0.640172,
      0.468268, 0.647329,
      0.436392, 0.640113,
      0.450067, 0.599566,
      0.560698, 0.604668,
      0.438999, 0.603505,
      0.654766, 0.655989,
      0.629906, 0.653924,
      0.656636, 0.644643,
      0.345234, 0.655989,
      0.343364, 0.644643,
      0.370094, 0.653924,
      0.634038, 0.644029,
      0.365962, 0.644029,
      0.723330, 0.636627,
      0.276670, 0.636627,
      0.517862, 0.528052,
      0.541366, 0.521101,
      0.520797, 0.557435,
      0.482113, 0.528021,
      0.479154, 0.557346,
      0.458639, 0.520911,
      0.544341, 0.548416,
      0.455607, 0.548199,
      0.552386, 0.697432,
      0.499955, 0.687602,
      0.447580, 0.697390,
      0.499923, 0.648476,
      0.781070, 0.564595,
      0.752212, 0.589195,
      0.752702, 0.542818,
      0.218937, 0.564589,
      0.247308, 0.542806,
      0.247792, 0.589190,
      0.732392, 0.575453,
      0.267612, 0.575440,
      0.585271, 0.664823,
      0.414712, 0.664780,
      0.566036, 0.417671,
      0.531915, 0.398463,
      0.468080, 0.398465,
      0.433991, 0.417638,
      0.536915, 0.406214,
      0.463080, 0.406216,
      0.595293, 0.514976,
      0.627543, 0.526648,
      0.372120, 0.526586,
      0.404670, 0.514867,
      0.617942, 0.491684,
      0.382385, 0.491427,
      0.539958, 0.442861,
      0.554318, 0.433923,
      0.544562, 0.451624,
      0.460042, 0.442861,
      0.455528, 0.451377,
      0.445682, 0.433923,
      0.562759, 0.441215,
      0.437114, 0.441104,
      0.606630, 0.596295,
      0.393362, 0.596294,
      0.587247, 0.601068,
      0.412782, 0.601030,
      0.567985, 0.506521,
      0.432112, 0.506411,
      0.589072, 0.491363,
      0.410986, 0.491277,
      0.801779, 0.168062,
      0.733752, 0.130299,
      0.198221, 0.168062,
      0.266248, 0.130299,
      0.711218, 0.180025,
      0.288719, 0.180054,
      0.570082, 0.533674,
      0.429884, 0.533478,
      0.655896, 0.679837,
      0.687132, 0.677654,
      0.662801, 0.717082,
      0.344108, 0.679849,
      0.337212, 0.717117,
      0.312876, 0.677668,
      0.703624, 0.706729,
      0.296392, 0.706757,
      0.537728, 0.494615,
      0.462783, 0.494253,
      0.531987, 0.469860,
      0.467288, 0.470075,
      0.664630, 0.147129,
      0.335279, 0.147180,
      0.588166, 0.890956,
      0.412198, 0.891099,
      0.624852, 0.271901,
      0.374971, 0.272195,
      0.733530, 0.623023,
      0.742870, 0.644554,
      0.257135, 0.644560,
      0.266470, 0.623023,
      0.758757, 0.617213,
      0.241246, 0.617214,
      0.672684, 0.743419,
      0.327338, 0.743473,
      0.722943, 0.728037,
      0.277076, 0.728068,
      0.526227, 0.426090,
      0.523913, 0.436170,
      0.517472, 0.422123,
      0.473773, 0.426090,
      0.482483, 0.422151,
      0.476088, 0.436170,
      0.516311, 0.436946,
      0.483518, 0.437016,
      0.742247, 0.685493,
      0.771046, 0.651041,
      0.770391, 0.700444,
      0.257765, 0.685510,
      0.229622, 0.700459,
      0.228962, 0.651049,
      0.805016, 0.657892,
      0.194993, 0.657898,
      0.590372, 0.298177,
      0.607591, 0.305797,
      0.409626, 0.298177,
      0.392389, 0.305797,
      0.897013, 0.531231,
      0.821442, 0.542444,
      0.102986, 0.531237,
      0.178560, 0.542446,
      0.825608, 0.602325,
      0.174399, 0.602329,
      0.810748, 0.476074,
      0.189241, 0.476076,
      0.500026, 0.452513,
      0.499816, 0.437019,
      0.513050, 0.452718,
      0.486717, 0.452371,
      0.509127, 0.437282,
      0.490726, 0.437599,
      0.663187, 0.355403,
      0.336768, 0.355267,
      0.633830, 0.601178,
      0.634070, 0.590424,
      0.366170, 0.601178,
      0.365930, 0.590424,
      0.534154, 0.379360,
      0.465844, 0.379359,
      0.499886, 0.133083,
      0.552012, 0.137408,
      0.447750, 0.137523,
      0.645429, 0.303293,
      0.354490, 0.303216,
      0.542902, 0.415208,
      0.457098, 0.415208,
      0.790082, 0.608646,
      0.209925, 0.608647,
      0.716482, 0.666799,
      0.283526, 0.666810,
      0.892441, 0.459239,
      0.107550, 0.459245,
      0.525850, 0.319809,
      0.474155, 0.319808,
      0.549626, 0.319139,
      0.450374, 0.319139,
      0.577414, 0.436833,
      0.422552, 0.436767,
      0.567192, 0.430580,
      0.432949, 0.430482,
      0.588354, 0.453138,
      0.411671, 0.453035,
      0.681008, 0.101715,
      0.318993, 0.101715,
      0.616907, 0.744114,
      0.383103, 0.744160,
      0.523481, 0.594373,
      0.476410, 0.594194,
      0.499523, 0.598938,
      0.812086, 0.411461,
      0.187885, 0.411462,
      0.573521, 0.296460,
      0.426479, 0.296460,
      0.614083, 0.718613,
      0.385919, 0.718636,
      0.551980, 0.295368,
      0.448020, 0.295368,
      0.986046, 0.439966,
      0.992440, 0.519223,
      0.013954, 0.439966,
      0.007561, 0.519223,
      0.499977, 0.294066,
      0.526878, 0.295374,
      0.473122, 0.295374,
      0.499977, 0.304722,
      0.526967, 0.304722,
      0.473033, 0.304722,
      0.551338, 0.304722,
      0.572058, 0.304722,
      0.448662, 0.304722,
      0.427942, 0.304722,
      0.499974, 0.560363,
      0.871706, 0.208059,
      0.128294, 0.208059,
      0.932695, 0.269895,
      0.866152, 0.317295,
      0.067305, 0.269895,
      0.133823, 0.317299,
      0.547818, 0.307634,
      0.452182, 0.307634,
      0.499989, 0.530175,
      0.524613, 0.307634,
      0.475387, 0.307634,
      0.711045, 0.601048,
      0.288955, 0.601048,
      0.698585, 0.612551,
      0.301415, 0.612551,
      0.500023, 0.892950,
      0.622953, 0.677221,
      0.377046, 0.677222,
      0.499914, 0.419853,
      0.508953, 0.420562,
      0.490967, 0.420622,
      0.725342, 0.610869,
      0.274658, 0.610869,
      0.526564, 0.453882,
      0.473466, 0.454256,
      0.547226, 0.579605,
      0.452770, 0.579150,
      0.499988, 0.381566,
      0.499974, 0.397628,
      0.500016, 0.320776,
      0.500023, 0.307652,
      0.573595, 0.610193,
      0.426450, 0.610201,
      0.531529, 0.444943,
      0.468472, 0.444943,
      0.885770, 0.384971,
      0.114210, 0.384978,
      0.967686, 0.355643,
      0.032314, 0.355643,
      0.810858, 0.353695,
      0.189096, 0.353700,
      0.557261, 0.427174,
      0.442739, 0.427174,
      0.554692, 0.419934,
      0.445308, 0.419934,
    ];

    texelsTwo = [
      0.499977, 0.347466,
      0.500026, 0.452513,
      0.499974, 0.397628,
      0.482113, 0.528021,
      0.500151, 0.472844,
      0.49991, 0.501747,
      0.499523, 0.598938,
      0.289712, 0.619236,
      0.499955, 0.687602,
      0.499987, 0.730081,
      0.500023, 0.89295,
      0.500023, 0.333766,
      0.500016, 0.320776,
      0.500023, 0.307652,
      0.499977, 0.304722,
      0.499977, 0.294066,
      0.499977, 0.280615,
      0.499977, 0.262981,
      0.499968, 0.218629,
      0.499816, 0.437019,
      0.473773, 0.42609,
      0.104907, 0.745859,
      0.36593, 0.590424,
      0.338758, 0.586975,
      0.31112, 0.59054,
      0.274658, 0.610869,
      0.393362, 0.596294,
      0.345234, 0.655989,
      0.370094, 0.653924,
      0.319322, 0.652735,
      0.297903, 0.646409,
      0.247792, 0.58919,
      0.396889, 0.157245,
      0.280098, 0.6244,
      0.10631, 0.600044,
      0.209925, 0.608647,
      0.355808, 0.465594,
      0.471751, 0.349596,
      0.474155, 0.319808,
      0.439785, 0.342771,
      0.414617, 0.333459,
      0.450374, 0.319139,
      0.428771, 0.317309,
      0.374971, 0.272195,
      0.486717, 0.452371,
      0.485301, 0.472605,
      0.257765, 0.68551,
      0.401223, 0.544828,
      0.429819, 0.451385,
      0.421352, 0.466259,
      0.276896, 0.467943,
      0.48337, 0.500413,
      0.337212, 0.717117,
      0.296392, 0.706757,
      0.169295, 0.806186,
      0.44758, 0.69739,
      0.39239, 0.646112,
      0.35449, 0.303216,
      0.067305, 0.269895,
      0.442739, 0.427174,
      0.457098, 0.415208,
      0.381974, 0.305289,
      0.392389, 0.305797,
      0.277076, 0.728068,
      0.422552, 0.436767,
      0.385919, 0.718636,
      0.383103, 0.74416,
      0.331431, 0.880286,
      0.229924, 0.767997,
      0.364501, 0.810886,
      0.229622, 0.700459,
      0.173287, 0.721252,
      0.472879, 0.333802,
      0.446828, 0.331473,
      0.422762, 0.32611,
      0.445308, 0.419934,
      0.388103, 0.306039,
      0.403039, 0.29346,
      0.403629, 0.306047,
      0.460042, 0.442861,
      0.431158, 0.307634,
      0.452182, 0.307634,
      0.475387, 0.307634,
      0.465828, 0.22081,
      0.472329, 0.263774,
      0.473087, 0.282143,
      0.473122, 0.295374,
      0.473033, 0.304722,
      0.427942, 0.304722,
      0.426479, 0.29646,
      0.423162, 0.288154,
      0.418309, 0.279937,
      0.390095, 0.360427,
      0.013954, 0.439966,
      0.499914, 0.419853,
      0.4132, 0.3046,
      0.409626, 0.298177,
      0.46808, 0.398465,
      0.422729, 0.414015,
      0.46308, 0.406216,
      0.37212, 0.526586,
      0.334562, 0.503927,
      0.411671, 0.453035,
      0.242176, 0.852324,
      0.290777, 0.798554,
      0.327338, 0.743473,
      0.39951, 0.251079,
      0.441728, 0.738324,
      0.429765, 0.812166,
      0.412198, 0.891099,
      0.288955, 0.601048,
      0.218937, 0.564589,
      0.412782, 0.60103,
      0.257135, 0.64456,
      0.427685, 0.562039,
      0.44834, 0.463064,
      0.17856, 0.542446,
      0.247308, 0.542806,
      0.286267, 0.532325,
      0.332828, 0.539288,
      0.368756, 0.552793,
      0.398964, 0.567345,
      0.47641, 0.594194,
      0.189241, 0.476076,
      0.228962, 0.651049,
      0.490726, 0.437599,
      0.40467, 0.514867,
      0.019469, 0.598436,
      0.426243, 0.579569,
      0.396993, 0.451203,
      0.26647, 0.623023,
      0.439121, 0.481042,
      0.032314, 0.355643,
      0.419054, 0.612845,
      0.462783, 0.494253,
      0.238979, 0.220255,
      0.198221, 0.168062,
      0.10755, 0.459245,
      0.18361, 0.259743,
      0.13441, 0.666317,
      0.385764, 0.116846,
      0.490967, 0.420622,
      0.382385, 0.491427,
      0.174399, 0.602329,
      0.318785, 0.603765,
      0.343364, 0.599403,
      0.3961, 0.289783,
      0.187885, 0.411462,
      0.430987, 0.055935,
      0.318993, 0.101715,
      0.266248, 0.130299,
      0.500023, 0.809424,
      0.499977, 0.045547,
      0.36617, 0.601178,
      0.393207, 0.604463,
      0.410373, 0.60892,
      0.194993, 0.657898,
      0.388665, 0.637716,
      0.365962, 0.644029,
      0.343364, 0.644643,
      0.318785, 0.64166,
      0.301415, 0.636844,
      0.058133, 0.680924,
      0.301415, 0.612551,
      0.499988, 0.381566,
      0.415838, 0.375804,
      0.445682, 0.433923,
      0.465844, 0.379359,
      0.499923, 0.648476,
      0.288719, 0.180054,
      0.335279, 0.14718,
      0.440512, 0.097581,
      0.128294, 0.208059,
      0.408772, 0.626106,
      0.455607, 0.548199,
      0.499877, 0.09101,
      0.375437, 0.075808,
      0.11421, 0.384978,
      0.448662, 0.304722,
      0.44802, 0.295368,
      0.447112, 0.284192,
      0.444832, 0.269206,
      0.430012, 0.233191,
      0.406787, 0.314327,
      0.400738, 0.318931,
      0.3924, 0.322297,
      0.367856, 0.336081,
      0.247923, 0.398667,
      0.45277, 0.57915,
      0.436392, 0.640113,
      0.416164, 0.631286,
      0.413386, 0.307634,
      0.228018, 0.316428,
      0.468268, 0.647329,
      0.411362, 0.195673,
      0.499989, 0.530175,
      0.479154, 0.557346,
      0.499974, 0.560363,
      0.432112, 0.506411,
      0.499886, 0.133083,
      0.499913, 0.178271,
      0.456549, 0.180799,
      0.344549, 0.254561,
      0.378909, 0.42599,
      0.374293, 0.219815,
      0.319688, 0.429262,
      0.357155, 0.39573,
      0.295284, 0.378419,
      0.44775, 0.137523,
      0.410986, 0.491277,
      0.313951, 0.224692,
      0.354128, 0.187447,
      0.324548, 0.296007,
      0.189096, 0.3537,
      0.279777, 0.285342,
      0.133823, 0.317299,
      0.336768, 0.355267,
      0.429884, 0.533478,
      0.455528, 0.451377,
      0.437114, 0.441104,
      0.467288, 0.470075,
      0.414712, 0.66478,
      0.377046, 0.677222,
      0.344108, 0.679849,
      0.312876, 0.677668,
      0.283526, 0.66681,
      0.241246, 0.617214,
      0.102986, 0.531237,
      0.267612, 0.57544,
      0.297879, 0.566824,
      0.333434, 0.566122,
      0.366427, 0.573884,
      0.396012, 0.583304,
      0.420121, 0.589772,
      0.007561, 0.519223,
      0.432949, 0.430482,
      0.458639, 0.520911,
      0.473466, 0.454256,
      0.476088, 0.43617,
      0.468472, 0.444943,
      0.433991, 0.417638,
      0.483518, 0.437016,
      0.482483, 0.422151,
      0.42645, 0.610201,
      0.438999, 0.603505,
      0.450067, 0.599566,
      0.289712, 0.631747,
      0.27667, 0.636627,
      0.517862, 0.528052,
      0.710288, 0.619236,
      0.526227, 0.42609,
      0.895093, 0.745859,
      0.63407, 0.590424,
      0.661242, 0.586975,
      0.68888, 0.59054,
      0.725342, 0.610869,
      0.60663, 0.596295,
      0.654766, 0.655989,
      0.629906, 0.653924,
      0.680678, 0.652735,
      0.702097, 0.646409,
      0.752212, 0.589195,
      0.602918, 0.157137,
      0.719902, 0.6244,
      0.893693, 0.60004,
      0.790082, 0.608646,
      0.643998, 0.465512,
      0.528249, 0.349596,
      0.52585, 0.319809,
      0.560215, 0.342771,
      0.585384, 0.333459,
      0.549626, 0.319139,
      0.571228, 0.317308,
      0.624852, 0.271901,
      0.51305, 0.452718,
      0.515097, 0.472748,
      0.742247, 0.685493,
      0.598631, 0.545021,
      0.570338, 0.451425,
      0.578632, 0.466377,
      0.723087, 0.467946,
      0.516446, 0.500361,
      0.662801, 0.717082,
      0.703624, 0.706729,
      0.830705, 0.806186,
      0.552386, 0.697432,
      0.60761, 0.646112,
      0.645429, 0.303293,
      0.932695, 0.269895,
      0.557261, 0.427174,
      0.542902, 0.415208,
      0.618026, 0.305289,
      0.607591, 0.305797,
      0.722943, 0.728037,
      0.577414, 0.436833,
      0.614083, 0.718613,
      0.616907, 0.744114,
      0.668509, 0.880086,
      0.770092, 0.767979,
      0.635536, 0.810751,
      0.770391, 0.700444,
      0.826722, 0.721245,
      0.527121, 0.333802,
      0.553172, 0.331473,
      0.577238, 0.32611,
      0.554692, 0.419934,
      0.611897, 0.306039,
      0.596961, 0.29346,
      0.596371, 0.306047,
      0.539958, 0.442861,
      0.568842, 0.307634,
      0.547818, 0.307634,
      0.524613, 0.307634,
      0.53409, 0.220859,
      0.527671, 0.263774,
      0.526913, 0.282143,
      0.526878, 0.295374,
      0.526967, 0.304722,
      0.572058, 0.304722,
      0.573521, 0.29646,
      0.576838, 0.288154,
      0.581691, 0.279937,
      0.609945, 0.36009,
      0.986046, 0.439966,
      0.5868, 0.3046,
      0.590372, 0.298177,
      0.531915, 0.398463,
      0.577268, 0.414065,
      0.536915, 0.406214,
      0.627543, 0.526648,
      0.665586, 0.504049,
      0.588354, 0.453138,
      0.757824, 0.852324,
      0.70925, 0.798492,
      0.672684, 0.743419,
      0.600409, 0.250995,
      0.558266, 0.738328,
      0.570304, 0.812129,
      0.588166, 0.890956,
      0.711045, 0.601048,
      0.78107, 0.564595,
      0.587247, 0.601068,
      0.74287, 0.644554,
      0.572156, 0.562348,
      0.551868, 0.46343,
      0.821442, 0.542444,
      0.752702, 0.542818,
      0.713757, 0.532373,
      0.667113, 0.539327,
      0.631101, 0.552846,
      0.600862, 0.567527,
      0.523481, 0.594373,
      0.810748, 0.476074,
      0.771046, 0.651041,
      0.509127, 0.437282,
      0.595293, 0.514976,
      0.980531, 0.598436,
      0.5735, 0.58,
      0.602995, 0.451312,
      0.73353, 0.623023,
      0.560611, 0.480983,
      0.967686, 0.355643,
      0.580985, 0.61284,
      0.537728, 0.494615,
      0.760966, 0.220247,
      0.801779, 0.168062,
      0.892441, 0.459239,
      0.816351, 0.25974,
      0.865595, 0.666313,
      0.614074, 0.116754,
      0.508953, 0.420562,
      0.617942, 0.491684,
      0.825608, 0.602325,
      0.681215, 0.603765,
      0.656636, 0.599403,
      0.6039, 0.289783,
      0.812086, 0.411461,
      0.568013, 0.055435,
      0.681008, 0.101715,
      0.733752, 0.130299,
      0.63383, 0.601178,
      0.606793, 0.604463,
      0.58966, 0.608938,
      0.805016, 0.657892,
      0.611335, 0.637716,
      0.634038, 0.644029,
      0.656636, 0.644643,
      0.681215, 0.64166,
      0.698585, 0.636844,
      0.941867, 0.680924,
      0.698585, 0.612551,
      0.584177, 0.375893,
      0.554318, 0.433923,
      0.534154, 0.37936,
      0.711218, 0.180025,
      0.66463, 0.147129,
      0.5591, 0.097368,
      0.871706, 0.208059,
      0.591234, 0.626106,
      0.544341, 0.548416,
      0.624563, 0.075808,
      0.88577, 0.384971,
      0.551338, 0.304722,
      0.55198, 0.295368,
      0.552888, 0.284192,
      0.555168, 0.269206,
      0.569944, 0.232965,
      0.593203, 0.314324,
      0.599262, 0.318931,
      0.6076, 0.322297,
      0.631938, 0.3365,
      0.752033, 0.398685,
      0.547226, 0.579605,
      0.563544, 0.640172,
      0.583841, 0.631286,
      0.586614, 0.307634,
      0.771915, 0.316422,
      0.531597, 0.647517,
      0.588371, 0.195559,
      0.520797, 0.557435,
      0.567985, 0.506521,
      0.543283, 0.180745,
      0.655317, 0.254485,
      0.621009, 0.425982,
      0.62556, 0.219688,
      0.680198, 0.429281,
      0.642764, 0.395662,
      0.704663, 0.37847,
      0.552012, 0.137408,
      0.589072, 0.491363,
      0.685945, 0.224643,
      0.645735, 0.18736,
      0.675343, 0.296022,
      0.810858, 0.353695,
      0.720122, 0.285333,
      0.866152, 0.317295,
      0.663187, 0.355403,
      0.570082, 0.533674,
      0.544562, 0.451624,
      0.562759, 0.441215,
      0.531987, 0.46986,
      0.585271, 0.664823,
      0.622953, 0.677221,
      0.655896, 0.679837,
      0.687132, 0.677654,
      0.716482, 0.666799,
      0.758757, 0.617213,
      0.897013, 0.531231,
      0.732392, 0.575453,
      0.702114, 0.566837,
      0.666525, 0.566134,
      0.633505, 0.573912,
      0.603876, 0.583413,
      0.579658, 0.590055,
      0.99244, 0.519223,
      0.567192, 0.43058,
      0.541366, 0.521101,
      0.526564, 0.453882,
      0.523913, 0.43617,
      0.531529, 0.444943,
      0.566036, 0.417671,
      0.516311, 0.436946,
      0.517472, 0.422123,
      0.573595, 0.610193,
      0.560698, 0.604668,
      0.549756, 0.600249,
      0.710288, 0.631747,
      0.72333, 0.636627];


  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
  gl.bufferData(
    gl.ELEMENT_ARRAY_BUFFER,
    new Uint16Array(drawOrder),
    gl.STATIC_DRAW
  );



  // Now pass the list of positions into WebGL to build the
  // shape. We do this by creating a Float32Array from the
  // JavaScript array, then use it to fill the current buffer.

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array(eyeshadowVertices),
    gl.DYNAMIC_DRAW
  );
  // Convert the array of colors into a table for all the vertices.

  // var colors = [1.0, 0.0, 0.0];

  // const colorBuffer = gl.createBuffer();
  // gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
  // gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(colors), gl.STATIC_DRAW);

  const textureBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, textureBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(texelsTwo), gl.STATIC_DRAW);

  return {
    position: positionBuffer,
    // color: colorBuffer,
    indices: indexBuffer,
    texture: textureBuffer,
    vertexCount: drawOrder.length,
    count: eyeshadowVertices.length / 3
  };
}

//
// Initialize a texture and load an image.
// When the image finished loading copy it into the texture.
//
function loadLeftEyelinerTexture(gl, url, i) {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);

  // Because images have to be download over the internet
  // they might take a moment until they are ready.
  // Until then put a single pixel in the texture so we can
  // use it immediately. When the image has finished downloading
  // we'll update the texture with the contents of the image.
  const level = 0;
  const internalFormat = gl.RGBA;
  const width = 1;
  const height = 1;
  const border = 0;
  const srcFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  const pixel = new Uint8Array([0, 0, 0, 0]); // opaque blue
  gl.texImage2D(
    gl.TEXTURE_2D,
    level,
    internalFormat,
    width,
    height,
    border,
    srcFormat,
    srcType,
    pixel
  );

  const image = new Image();
  image.onload = function () {
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(
      gl.TEXTURE_2D,
      level,
      internalFormat,
      srcFormat,
      srcType,
      image
    );

    // WebGL1 has different requirements for power of 2 images
    // vs non power of 2 images so check if the image is a
    // power of 2 in both dimensions.
    if (isPowerOf2(image.width) && isPowerOf2(image.height)) {
      // Yes, it's a power of 2. Generate mips.
      gl.generateMipmap(gl.TEXTURE_2D);
    } else {
      // No, it's not a power of 2. Turn of mips and set
      // wrapping to clamp to edge
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    }
  };
  image.src = url;

  if (i) {
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, i);

    // WebGL1 has different requirements for power of 2 images
    // vs non power of 2 images so check if the image is a
    // power of 2 in both dimensions.
    if (isPowerOf2(i.width) && isPowerOf2(i.height)) {
      // Yes, it's a power of 2. Generate mips.
      gl.generateMipmap(gl.TEXTURE_2D);
    } else {
      // No, it's not a power of 2. Turn of mips and set
      // wrapping to clamp to edge
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    }
  }

  return texture;
}

function isPowerOf2(value) {
  return (value & (value - 1)) === 0;
}

//
// Draw the scene.
//
function drawLeftEyelinerScene(gl, programInfoMakeup, buffersMakeup, texture, opacity = 0.2,colorValue = [0.0,0.0,0.0]) {
  //gl.clearColor(0.0, 0.0, 0.0, 0.0);  // Clear to black, fully opaque
  //gl.clearDepth(1.0);                 // Clear everything
  gl.disable(gl.DEPTH_TEST);
  gl.enable(gl.BLEND);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  // Enable depth testing
  //gl.depthFunc(gl.LEQUAL);            // Near things obscure far things

  //gl.clear(gl.COLOR_BUFFER_BIT);

  // const fieldOfView = (45 * Math.PI) / 180; // in radians
  // const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
  // const zNear = 1.0;
  // const zFar = 1000.0;
  const projectionMatrix = mat4.create();

  // note: js always has the first argument
  // as the destination to receive the result.
  // mat4.perspective(projectionMatrix,
  //     fieldOfView,
  //     aspect,
  //     zNear,
  //     zFar);

  // Set the drawing position to the "identity" point, which is
  // the center of the scene.
  const modelViewMatrix = mat4.create();

  mat4.scale(modelViewMatrix, modelViewMatrix, [2.0, 2.0, 2.0]);
  mat4.rotate(
    modelViewMatrix,
    modelViewMatrix,
    (180 * Math.PI) / 180,
    [0, 0, 1]
  );
  mat4.translate(modelViewMatrix, modelViewMatrix, [-0.5, -0.5, 0]);

  {
    const numComponents = 3; // pull out 2 values per iteration
    const type = gl.FLOAT; // the data in the buffer is 32bit floats
    const normalize = false; // don't normalize
    const stride = 0; // how many bytes to get from one set of values to the next
    // 0 = use type and numComponents above
    const offset = 0; // how many bytes inside the buffer to start from
    gl.bindBuffer(gl.ARRAY_BUFFER, buffersMakeup.position);
    gl.vertexAttribPointer(
      programInfoMakeup.attribLocations.vertexPosition,
      numComponents,
      type,
      normalize,
      stride,
      offset
    );
    gl.enableVertexAttribArray(
      programInfoMakeup.attribLocations.vertexPosition
    );

    // gl.bindBuffer(gl.ARRAY_BUFFER, buffersMakeup.color);
    // gl.vertexAttribPointer(
    //     programInfoMakeup.attribLocations.colorPosition,
    //     numComponents,
    //     type,
    //     normalize,
    //     stride,
    //     offset);
    // gl.enableVertexAttribArray(
    //     programInfoMakeup.attribLocations.colorPosition);

    gl.bindBuffer(gl.ARRAY_BUFFER, buffersMakeup.texture);
    gl.vertexAttribPointer(
      programInfoMakeup.attribLocations.texCoords,
      2,
      type,
      normalize,
      stride,
      offset
    );
    gl.enableVertexAttribArray(programInfoMakeup.attribLocations.texCoords);
  }

  // Tell WebGL to use our program when drawing
  //gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffersMakeup.indices);
  gl.useProgram(programInfoMakeup.program);

  // Set the shader uniforms

  gl.uniformMatrix4fv(
    programInfoMakeup.uniformLocations.projectionMatrix,
    false,
    projectionMatrix
  );
  gl.uniformMatrix4fv(
    programInfoMakeup.uniformLocations.modelViewMatrix,
    false,
    modelViewMatrix
  );
  gl.uniform1f(
    programInfoMakeup.uniformLocations.opacity,
    opacity,
  );
  gl.uniform3fv(
    programInfoMakeup.uniformLocations.appliedColorPositon,
    new Float32Array(colorValue),
  );

  // gl.uniform4fv(
  //     programInfo.uniformLocations.color,
  //     1,
  //     new Float32Array([0.0, 1.0, 0.0, 1.0]),
  //     0
  // );

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.uniform1i(programInfoMakeup.uniformLocations.sampler, 0);
  {
    const vertexCount = buffersMakeup.vertexCount;
    const type = gl.UNSIGNED_SHORT;
    const offset = 0;
    gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
    // gl.drawArrays(gl.TRIANGLES, 0, buffersMakeup.count);//(Mode,firstElementor starting element,count or number of elements to be rendered)
  }
}

//
// Initialize a shader program, so WebGL knows how to draw our data
//
function initShaderProgram(gl, vsSource, fsSource) {
  const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
  const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

  // Create the shader program

  const shaderProgram = gl.createProgram();
  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);

  // If creating the shader program failed, alert

  if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
    alert(
      "Unable to initialize the shader program: " +
      gl.getProgramInfoLog(shaderProgram)
    );
    return null;
  }

  return shaderProgram;
}

//
// creates a shader of the given type, uploads the source and
// compiles it.
//
function loadShader(gl, type, source) {
  const shader = gl.createShader(type);

  // Send the source to the shader object

  gl.shaderSource(shader, source);

  // Compile the shader program

  gl.compileShader(shader);

  // See if it compiled successfully

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    alert(
      "An error occurred compiling the shaders: " + gl.getShaderInfoLog(shader)
    );
    gl.deleteShader(shader);
    return null;
  }

  return shader;
}

export {
  getLeftEyelinerProgramData,
  drawLeftEyelinerScene,
  loadLeftEyelinerTexture,
  initLeftEyelinerBuffers,
};


