import { FaceMesh } from "@mediapipe/face_mesh";
// import * as cam from "@mediapipe/camera_utils";

var faceMesh = null;


function initializeFaceMesh(onResults, webcamRef, width, height, tryon_type, imageTag) {

  // var camera = null;
  if (faceMesh == null) {
    faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://makeup.selfstylo.com/static/face_mesh/${file}`;
      },
    });
    faceMesh.setOptions({
      maxNumFaces: 1,
      refineLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    faceMesh.initialize()

    return;
  }

  if (faceMesh != null) {
    faceMesh.onResults(onResults);

    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {

      switch (tryon_type) {
        case "video":

          // Approach 1 with browser's default camera

          navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
              const videoElement = document.createElement('video');
            // eslint-disable-next-line
              {/* FEL-417 Video element was mirrored*/}
              videoElement.style.transform = 'rotateX(180deg)'
              videoElement.srcObject = stream;
              videoElement.addEventListener('loadedmetadata', () => {
                const width = videoElement.videoWidth;
                const height = videoElement.videoHeight;

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;
                canvas.style.display = "none";
                // canvas.willReadFrequently="true"

                document.body.appendChild(canvas);

                async function processFrame() {
                  context.drawImage(videoElement, 0, 0, width, height);
                  const imageData = context.getImageData(0, 0, width, height);

                  // Pass imageData to facemesh or perform any other processing
                  await faceMesh.send({ image: imageData }).then(() => {
                    // console.log("camera------then-------------")
                  });

                  requestAnimationFrame(processFrame);
                }

                videoElement.play();
                processFrame();
              });
            })
            .catch((error) => {
              console.log(error);
            });

          // Apporach 2 using mediapipe camera

          // camera = new cam.Camera(webcamRef.current.video, {
          //   onFrame: async () => {
          //     try {
          //       await faceMesh.send({ image: webcamRef?.current?.video }).then(() => {
          //         // console.log("camera------then-------------")
          //       });
          //     } catch (error) {
          //       console.log(error)
          //     }
          //   },
          //   width: width,
          //   height: height,
          //   // width: 540,
          //   // height: 480,
          // });
          // try {
          //   camera.start();
          // } catch (e) {
          //   console.log(e);
          // }
          break;

        case "upload_photo":

          try {
            faceMesh.setOptions({
              selfieMode: true,
              maxNumFaces: 1,
              refineLandmarks: true,
              minDetectionConfidence: 0.5,
              minTrackingConfidence: 0.5,
            });
            setTimeout(() => {
              faceMesh.send({ image: imageTag }).catch((e) => {
                console.log("Something went wrong in Upload Photo", e)
              });
            }, 100);
          } catch (error) {
            console.log(error)
          }
          break;
        case "change_photo":

          try {
            faceMesh.setOptions({
              selfieMode: true,
              maxNumFaces: 1,
              refineLandmarks: true,
              minDetectionConfidence: 0.5,
              minTrackingConfidence: 0.5,
            });
            setTimeout(() => {
              faceMesh.send({ image: imageTag }).catch((e) => {
                console.log("Something went wrong in Change Photo", e)
              });
            }, 100);
          } catch (error) {
            console.log(error)
          }
          break;

        case "capture_photo":
          // camera = new cam.Camera(webcamRef.current.video, {
          //   onFrame: async () => {
          //     try {
          //       await faceMesh.send({ image: webcamRef?.current?.video }).then(() => {
          //         // console.log("camera------then-------------")
          //       });
          //     } catch (error) {
          //       console.log(error)
          //     }
          //   },
          //   width: props.sharedataconfig.data && "input_resolution_width" in props.sharedataconfig.data ? props.sharedataconfig.data["input_resolution_width"] : 540,
          //   height: props.sharedataconfig.data && "input_resolution_height" in props.sharedataconfig.data ? props.sharedataconfig.data["input_resolution_height"] : 480,
          //   // width: 540,
          //   // height: 480,
          // });
          // try {
          //   camera.start();
          // } catch (e) {
          //   console.log(e);
          // }
          break;

        default:
          break;
      }
    }
  }
}

async function validateInputImage(imageTag) {
  return new Promise(async (resolve, reject) => {
    function onImageResults(results) {
      // console.log("results", results);
      if (results.multiFaceLandmarks && results.multiFaceLandmarks.length > 0) {
        resolve(true);
      }
      resolve(false);
    }
    try {
      var faceMesh2 = new FaceMesh({
        locateFile: (file) => {
          return `https://makeup.selfstylo.com/static/face_mesh/${file}`;
        },
      });
      faceMesh2.setOptions({
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
      });
      faceMesh2.send({ image: imageTag }).catch((e) => {
        console.log("====-------camera=====---fault", e)
      });
      faceMesh2.onResults(onImageResults)
    }
    catch (error) {
      console.log(error)
      return false;
    }
  })
}

export { initializeFaceMesh, validateInputImage }