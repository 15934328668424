import React from 'react'
import custom from "../images/icons/custom.svg";
import capturephoto from "../images/icons/analyse.svg";
import shadepicker from "../images/icons/shades.svg";
import { MakeupAPIs } from '../network/api';
import { loadProducts } from '../makeup_utils/common_utils';

export const Foundation = (props) => {

    const handleStage = (id)=>{
      if(id === 1){
        const api = new MakeupAPIs();
        api.getAllCategories(props.activeMakeupType.id).then((res) => 
        {
          props.setActiveCategory(res[0].id);
          loadProducts(res[0].id, props.setProductData);
        })
      }
      props.setFoundationType(id)
    }

  return (
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 g-0 p-3 spacerMobile shadow card-radius mb-3'>
    <div className='row'>
      <div className="col-md-4 foundationBlock">
                {/* <div className="d-none">{props.categoryRender}</div> */}
                <div
                  className={"selectedFoundation " + (props.foundationType === 1 ? "activeFoundation":"") }
                  title="Custom"
                  onClick={()=>handleStage(1)}
                >
                  <div className="text-truncate text-center">
                    <button
                      className="btn btn-sm btnPdng"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                    >
                      <img src={custom} className="logo-size" alt="Custom" />
                    </button>
                    <div className="category_tags">
                      <span className="d-grid mx-auto my-2 text-center flex-wrap">
                        Custom
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 foundationBlock">
                {/* <div className="d-none">{props.categoryRender}</div> */}
                <div
                  className={"selectedFoundation " + (props.foundationType === 2 ? "activeFoundation":"") }
                  title="Shade Picker"
                  onClick={()=>handleStage(2)}
                >
                  <div className="text-truncate text-center">
                    <button
                      className="btn btn-sm btnPdng"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                    >
                      <img
                        src={shadepicker}
                        className="logo-size"
                        alt="selectshades"
                      />
                    </button>
                    <div className="category_tags">
                      <span className="d-grid mx-auto my-2 text-center flex-wrap">
                        Shade Picker
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 foundationBlock">
                {/* <div className="d-none">{props.categoryRender}</div> */}
                <div
                  className={"selectedFoundation " + (props.foundationType === 3 ? "activeFoundation":"") }
                  title="Capture Photo"
                  onClick={()=>handleStage(3)}
                >
                  <div className="text-truncate text-center">
                    <button
                      className="btn btn-sm btnPdng"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                    >
                      <img
                        src={capturephoto}
                        className="logo-size"
                        alt="capturephoto"
                      />
                    </button>
                    <div className="category_tags">
                      <span className="d-grid mx-auto my-2 text-center flex-wrap">
                        Capture Photo
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              </div>
      </div>
  )
}
