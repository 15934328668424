import cv from "@techstark/opencv-js";
let alpha = 0.7;

function getChanneledMat(_canvasElement) {

    // Reagding the input image
    let matTexture = cv.imread(_canvasElement);

    // Flipping the image
    let flippedImage = new cv.Mat();
    cv.flip(matTexture, flippedImage, -1);
    cv.flip(flippedImage, flippedImage, -1);

    // Converting and returning the 
    let threeChannelMat = new cv.Mat();
    cv.cvtColor(flippedImage, threeChannelMat, cv.COLOR_RGBA2RGB);


    flippedImage.delete();
    matTexture.delete();

    return threeChannelMat;

}
function getExtendedLandmarks(_landmarks, _canvasElement) {
    const landmarks_points = _landmarks.map(lm => [parseInt(lm.x * _canvasElement.width), parseInt(lm.y * _canvasElement.height)]);

    let forehead_landmark_indices = [162, 21, 54, 103, 67, 109, 10, 338, 297, 332, 284, 251, 389, 150, 149, 176, 148, 152, 377, 400, 378, 379];

    //Incrementing the face region
    for (let idx of forehead_landmark_indices) {
        if (idx === 54 || idx === 21 || idx === 284 || idx === 251) {
            landmarks_points[idx] = [parseInt(landmarks_points[idx][0]), parseInt(landmarks_points[idx][1]) - 100];
        } else if (idx === 150 || idx === 149 || idx === 176 || idx === 148 || idx === 377 || idx === 400 || idx === 378 || idx === 379) {
            landmarks_points[idx] = [parseInt(landmarks_points[idx][0]), parseInt(landmarks_points[idx][1]) + 100];
        } else {
            landmarks_points[idx] = [parseInt(landmarks_points[idx][0]), parseInt(landmarks_points[idx][1]) - 100];
        }
    }

    return landmarks_points;
}

// FEL-676 
function getMask(_landmarks, _filteredLandmarks, _image, _color = [255, 255, 255]) {
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;

    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;

    for (let [x, y] of _filteredLandmarks) {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);

        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
    }

    let x = minX;
    let y = minY;

    let width = maxX - minX;
    let height = maxY - minY;

    // Getting center and axes Length from landmarks provided
    const center = [parseInt(x + width / 2), parseInt(y + height / 2)];
    const axesLegth = [parseInt(width / 2), parseInt(height / 2)];


    let centerPoint = new cv.Point(center[0], center[1]);
    let axexPoint = new cv.Size(axesLegth[0], axesLegth[1]);

    let basicMask = new cv.Mat.ones(_image.rows, _image.cols, cv.CV_8UC3);
    let ellipseColor = new cv.Scalar(_color[0], _color[1], _color[2]);

    //Making an ellipse according to the center point 
    cv.ellipse(basicMask, centerPoint, axexPoint, 0, 0, 360, ellipseColor, -1);

    function maskIntFunc(_arr) {
        return _arr * alpha;
    }

    let maskWithIntensityArray = basicMask.data.map(maskIntFunc);

    let maskMat = cv.matFromArray(basicMask.rows, basicMask.cols, cv.CV_8UC3, maskWithIntensityArray);
    // FEL-676 To Exclude Lips, Eyebrows, Eyes

    let left_eye_landmarks_indices = [130, 247, 30, 29, 27, 28, 56, 190, 243, 112, 26, 22, 23, 24, 110, 25];
    let right_eye_landmarks_indices = [359, 467, 260, 259, 257, 258, 286, 414, 463, 341, 256, 252, 253, 254, 339, 255];
    let lips_indices = [185, 40, 39, 37, 0, 267, 269, 270, 409, 375, 321, 405, 314, 17, 84, 181, 91, 146];
    let left_eyebrow_indices = [124, 46, 53, 52, 65, 55, 107, 66, 105, 63, 70];
    let right_eyebrow_indices = [285, 295, 282, 283, 276, 353, 300, 293, 334, 296, 336];

    let leftEyeLandmarks = left_eye_landmarks_indices.map(i => [Math.floor(_landmarks[i].x * _image.cols), Math.floor(_landmarks[i].y * _image.rows)]);
    let rightEyeLandmarks = right_eye_landmarks_indices.map(i => [Math.floor(_landmarks[i].x * _image.cols), Math.floor(_landmarks[i].y * _image.rows)]);
    let lipLandmarks = lips_indices.map(i => [Math.floor(_landmarks[i].x * _image.cols), Math.floor(_landmarks[i].y * _image.rows)]);
    let leftEyebrowLandmarks = left_eyebrow_indices.map(i => [Math.floor(_landmarks[i].x * _image.cols), Math.floor(_landmarks[i].y * _image.rows)]);
    let rightEyebrowLandmarks = right_eyebrow_indices.map(i => [Math.floor(_landmarks[i].x * _image.cols), Math.floor(_landmarks[i].y * _image.rows)]);

    let faceMask = new cv.Mat.zeros(_image.rows, _image.cols, cv.CV_8UC3);
    let facemask_kSize = new cv.Size(61, 61);
    cv.blur(faceMask, faceMask, facemask_kSize);

    let leftEyeMatVec = new cv.MatVector();
    let left_eye_mat = cv.matFromArray(1, leftEyeLandmarks.length, cv.CV_32SC2, leftEyeLandmarks.flat(2));
    leftEyeMatVec.push_back(left_eye_mat);

    let rightEyeMatVec = new cv.MatVector();
    let right_eye_mat = cv.matFromArray(1, rightEyeLandmarks.length, cv.CV_32SC2, rightEyeLandmarks.flat(2));
    rightEyeMatVec.push_back(right_eye_mat);

    let lipMatVec = new cv.MatVector();
    let lip_mat = cv.matFromArray(1, lipLandmarks.length, cv.CV_32SC2, lipLandmarks.flat(2));
    lipMatVec.push_back(lip_mat);

    let leftEyebrowMatVec = new cv.MatVector();
    let lef_eyebrow_mat = cv.matFromArray(1, leftEyebrowLandmarks.length, cv.CV_32SC2, leftEyebrowLandmarks.flat(2));
    leftEyebrowMatVec.push_back(lef_eyebrow_mat);

    let rightEyebrowMatVec = new cv.MatVector();
    let right_eyebrow_mat = cv.matFromArray(1, rightEyebrowLandmarks.length, cv.CV_32SC2, rightEyebrowLandmarks.flat(2));
    rightEyebrowMatVec.push_back(right_eyebrow_mat);

    cv.fillPoly(faceMask, leftEyeMatVec, [255, 255, 255, 1]);
    cv.fillPoly(faceMask, rightEyeMatVec, [255, 255, 255, 1]);
    cv.fillPoly(faceMask, lipMatVec, [255, 255, 255, 1]);
    cv.fillPoly(faceMask, leftEyebrowMatVec, [255, 255, 255, 1]);
    cv.fillPoly(faceMask, rightEyebrowMatVec, [255, 255, 255, 1]);

    maskMat.setTo(new cv.Scalar(0, 0, 0), faceMask);

    let bluredMask = new cv.Mat();
    let kSize = new cv.Size(81, 81);

    cv.blur(maskMat, bluredMask, kSize);

    maskMat.delete();
    basicMask.delete();

    return bluredMask;
}

function maskOverlay(_image, _mask) {
    let resultMat = new cv.Mat();
    cv.addWeighted(_image, alpha, _mask, 1 - alpha, -1, resultMat);

    cv.imshow('canvas_output', resultMat)
    resultMat.delete();
    _image.delete();
    _mask.delete();
}
export { getChanneledMat, getExtendedLandmarks, getMask, maskOverlay }