import React, { useEffect } from "react"
import { MakeupAPIs } from "../network/api.js"
import { BASE_URL } from "../configs/configs.js";


export default function MakeupTypes(props){

    let api = new MakeupAPIs();

    
    useEffect(() => {
        api.getMakeupTypes().then((res) => {
            props.setMakeupTypes(res);
            props.setActiveMakeupType({id: res?.[0]?.id, name: res?.[0]?.makeup_name, icon: BASE_URL + res?.[0]?.icon});
        })
    // eslint-disable-next-line
    }, []);


    const makeupTypeClickHandler = (id, name, icon) => {
        api.makeupTypeCount(id);
        props.setActiveMakeupType({id: id, name: name, icon: BASE_URL + icon});
        props.setComboMode(false);
        if (id in props.activeMakeupItem) {
            let cdata = Object.entries(props.activeMakeupItem[id])[0];
            props.setActiveCategory(parseInt(cdata[0]));
            props.setActiveProduct(cdata[1]);
        } else {
            props.setActiveProduct({id:null, name: null });
        }

    }

    return (
        <>
            {props.makeupTypes?.map((item, key) => {
                return (
                <div key={key} className={"categoryBtn d-flex " + (props.activeMakeupType.id === item.id ? 'activeT': '') } onClick={() => makeupTypeClickHandler(item.id, item.makeup_name, item.icon)}>
                    <button id={item.id} className="btn border-cir btn-sm btnPdng">
                        <img src={BASE_URL + item.icon} className="logo-size" alt="eyeliner"/>
                    </button>
                    <div className="typeStyleHerer">
                        <p className="d-grid mx-auto typeName">{item.makeup_name}</p>
                    </div>

                </div>
                )
            })}
            
        </>
    )
}