

//main();

//
// Start here
//
import {mat4} from 'gl-matrix';
const upperLip = [61, 185, 40, 39, 37, 0, 267, 269, 270, 409, 306, 292, 308, 415, 310, 311, 312, 13, 82, 81, 80, 62, 76];
const lowerLip = [95, 88, 178, 87, 14, 317, 402, 318, 324, 375, 321, 405, 314, 17, 84, 181, 91, 146];

function getMakeupProgramData(canvas, gl) {

    // If we don't have a GL context, give up now

    if (!gl) {
        alert('Unable to initialize WebGL. Your browser or machine may not support it.');
        return;
    }

    // Vertex shader program

    const vsSource = `
    attribute vec3 aVertexPosition;
    attribute vec3 aColor;
    
    attribute vec2 aTexCoords;

    uniform mat4 uModelViewMatrix;
    uniform mat4 uProjectionMatrix;

    varying vec3 vColor;
    
    varying vec2 vTexCoords;
    //varying vec4 vAvgLuminance;
    //varying float vBrightness;
    //varying float vContrast;

    void main() {
        gl_Position = uProjectionMatrix * uModelViewMatrix * vec4(aVertexPosition.xyz,1.0);
        
        vColor = aColor;
        vTexCoords = aTexCoords;
    }
  `;

    // Fragment shader program

    const fsSource = `
    precision lowp float;
    uniform sampler2D uSampler;

    varying vec3 vColor;
    varying vec2 vTexCoords;
    void main() {
        vec4 color = vec4(1.0,1.0,1.0,0.0);
        vec4 lum = vec4(0.0,0.0,0.0,0.0);
        float brightness = 0.3;
        float contrast = 0.2;

      vec4 texColor = texture2D(uSampler,vTexCoords);
      gl_FragColor = vec4(mix((texColor * brightness) , mix(lum,texColor,contrast),0.5).rgb,0.55);
      //gl_FragColor = vec4(texColor.rgb,0.6);
      //gl_FragColor = texture2D(uSampler,vTexCoords);
    }
  `;

    // Initialize a shader program; this is where all the lighting
    // for the vertices and so forth is established.
    const shaderProgramMakeup = initShaderProgram(gl, vsSource, fsSource);
    const programInfoMakeup = {
        program: shaderProgramMakeup,
        attribLocations: {
            vertexPosition: gl.getAttribLocation(shaderProgramMakeup, 'aVertexPosition'),
            colorPosition: gl.getAttribLocation(shaderProgramMakeup, "aColor"),
            texCoords: gl.getAttribLocation(shaderProgramMakeup, "aTexCoords"),
        },
        uniformLocations: {
            projectionMatrix: gl.getUniformLocation(shaderProgramMakeup, 'uProjectionMatrix'),
            modelViewMatrix: gl.getUniformLocation(shaderProgramMakeup, 'uModelViewMatrix'),
            sampler: gl.getUniformLocation(shaderProgramMakeup, "uSampler"),
        },
    };

    // Here's where we call the routine that builds all the
    // objects we'll be drawing.
    const buffers = null;

    const texture = null;


    //   // Draw the scene repeatedly
    //   function render(now) {
    //     now *= 0.001;  // convert to seconds
    //     const deltaTime = now - then;
    //     then = now;

    //     drawScene(gl, programInfo, buffers, texture, deltaTime);

    //     requestAnimationFrame(render);
    //   }
    //   requestAnimationFrame(render);

    return {
        programInfo: programInfoMakeup,
        shaderProgram: shaderProgramMakeup,
        buffers: buffers,
        texture: texture
    };
}

//
// initBuffers
//
// Initialize the buffers we'll need. For this demo, we just
// have one object -- a simple three-dimensional cube.
//
function initMakeupBuffers(gl, landmarks) {


    // Create a buffer for the square's positions.

    const positionBuffer = gl.createBuffer();
    //const colorBuffer = gl.createBuffer();
    const indexBuffer = gl.createBuffer();
    // Select the positionBuffer as the one to apply buffer
    // operations to from here out.


    // Now create an array of positions for the square.


    var lipVertices = [];
    var texels = [];

    for (let i = 0; i < upperLip.length; i++) {
        lipVertices.push(landmarks[upperLip[i]].x);
        lipVertices.push(landmarks[upperLip[i]].y);
        lipVertices.push(landmarks[upperLip[i]].z);
    }

    for (let i = 0; i < lowerLip.length; i++) {
        lipVertices.push(landmarks[lowerLip[i]].x);
        lipVertices.push(landmarks[lowerLip[i]].y);
        lipVertices.push(landmarks[lowerLip[i]].z);
       
    }

    //console.log();

   
    var drawOrder = [
            0, 22, 1,
            22, 21, 1,
            21, 20, 1,
            1, 20, 2,
            20, 3, 2,
            20, 19, 3,
            19, 4, 3,
            19, 18, 4,
            18, 5, 4,
            18, 17, 5,
            17, 16, 5,
            16, 6, 5,
            16, 15, 6,
            15, 14, 6,
            14, 7, 6,
            14, 13, 7,
            13, 12, 7,
            12, 8, 7,
            12, 11, 8,
            11, 10, 8,
            10, 9, 8,
            //
            10, 31, 32,
            31, 33, 32,
            31, 34, 33,
            31, 30, 34,
            30, 29, 34,
            29, 35, 34,
            29, 28, 35,
            28, 27, 35,
            27, 36, 35,
            27, 26, 36,
            26, 25, 36,
            25, 37, 36,
            25, 24, 37,
            24, 23, 37,
            23, 38, 37,
            23, 22, 38,
            22, 39, 38,
            22, 40, 39,
            22, 0, 40,
        ];

        texels = [
            0.0, 1.0, //61
            0.1, 1.0, //185
            0.2, 1.0, //40
            0.3, 1.0, //39
            0.4, 1.0, //37
            0.5, 1.0, //0
            0.6, 1.0, //267
            0.7, 1.0, //269
            0.8, 1.0, //270
            0.9, 1.0, //409
            1.0, 1.0, //306
            1.0, 0.5, //292
            1.0, 0.5, //308
            0.9, 0.5, //415
            0.8, 0.5, //310
            0.7, 0.5, //311
            0.6, 0.5, //312
            0.5, 0.5, //13
            0.4, 0.5, //82
            0.3, 0.5, //81
            0.2, 0.5, //80
            0.1, 0.5, //62
            0.0, 0.5, //76

            0.0, 0.6, //95
            0.1, 0.6, //88
            0.2, 0.6, //178
            0.3, 0.6, //87
            0.5, 0.6, //14
            0.6, 0.6, //317
            0.7, 0.6, //402
            0.8, 0.6, //318
            0.9, 0.6, //324
            1.0, 0.6, //375
            0.9, 0.0, //321
            0.8, 0.0, //405
            0.7, 0.0, //314
            0.5, 0.0, //17
            0.4, 0.0, //84
            0.3, 0.0, //181
            0.2, 0.0, //81
            0.0, 0.0, //416
        ];

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER,
        new Uint16Array(drawOrder),
        gl.STATIC_DRAW);

    // Now pass the list of positions into WebGL to build the
    // shape. We do this by creating a Float32Array from the
    // JavaScript array, then use it to fill the current buffer.

    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER,
        new Float32Array(lipVertices),
        gl.DYNAMIC_DRAW);
    // Convert the array of colors into a table for all the vertices.

    // var colors = [1.0, 0.0, 0.0];

    // const colorBuffer = gl.createBuffer();
    // gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
    // gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(colors), gl.STATIC_DRAW);



    const textureBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, textureBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(texels), gl.STATIC_DRAW);

    return {
        position: positionBuffer,
       // color: colorBuffer,
        indices: indexBuffer,
        texture: textureBuffer,
        vertexCount: drawOrder.length,
    };
}

//
// Initialize a texture and load an image.
// When the image finished loading copy it into the texture.
//
function loadMakeupTexture(gl, i) {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Because images have to be download over the internet
    // they might take a moment until they are ready.
    // Until then put a single pixel in the texture so we can
    // use it immediately. When the image has finished downloading
    // we'll update the texture with the contents of the image.
    const level = 0;
    const internalFormat = gl.RGBA;
    const width = 1;
    const height = 1;
    const border = 0;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;
    const pixel = new Uint8Array([0, 0, 255, 255]);  // opaque blue
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
        width, height, border, srcFormat, srcType,
        pixel);



    if (i) {
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
            srcFormat, srcType, i);

        // WebGL1 has different requirements for power of 2 images
        // vs non power of 2 images so check if the image is a
        // power of 2 in both dimensions.
        if (isPowerOf2(i.width) && isPowerOf2(i.height)) {
            // Yes, it's a power of 2. Generate mips.
            gl.generateMipmap(gl.TEXTURE_2D);
        } else {
            // No, it's not a power of 2. Turn of mips and set
            // wrapping to clamp to edge
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        }
    }

    return texture;
}


function isPowerOf2(value) {
    return (value & (value - 1)) === 0;
}

//
// Draw the scene.
//
function drawMakeupScene(gl, programInfoMakeup, buffersMakeup, texture,clearScene = false) {

    gl.clearColor(0.0, 0.0, 0.0, 0.0);  // Clear to black, fully opaque
    //gl.clearDepth(1.0);                 // Clear everything
    gl.disable(gl.DEPTH_TEST);
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    // Enable depth testing
    //gl.depthFunc(gl.LEQUAL);            // Near things obscure far things

    //gl.clear(gl.COLOR_BUFFER_BIT);
    // const fieldOfView = 45 * Math.PI / 180;   // in radians
    // const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
    // const zNear = 1.0;
    // const zFar = 1000.0;
    const projectionMatrix = mat4.create();

    // note: js always has the first argument
    // as the destination to receive the result.
    // mat4.perspective(projectionMatrix,
    //     fieldOfView,
    //     aspect,
    //     zNear,
    //     zFar);

    // Set the drawing position to the "identity" point, which is
    // the center of the scene.
    const modelViewMatrix = mat4.create();

    mat4.scale(modelViewMatrix, modelViewMatrix, [2.0, 2.0, 2.0]);
    mat4.rotate(modelViewMatrix, modelViewMatrix, (180 * Math.PI / 180), [0, 0, 1]);
    mat4.translate(modelViewMatrix, modelViewMatrix, [-0.50, -0.50, 0]);

    {
        const numComponents = 3;  // pull out 2 values per iteration
        const type = gl.FLOAT;    // the data in the buffer is 32bit floats
        const normalize = false;  // don't normalize
        const stride = 0;         // how many bytes to get from one set of values to the next
        // 0 = use type and numComponents above
        const offset = 0;         // how many bytes inside the buffer to start from
        gl.bindBuffer(gl.ARRAY_BUFFER, buffersMakeup.position);
        gl.vertexAttribPointer(
            programInfoMakeup.attribLocations.vertexPosition,
            numComponents,
            type,
            normalize,
            stride,
            offset);
        gl.enableVertexAttribArray(
            programInfoMakeup.attribLocations.vertexPosition);


        // gl.bindBuffer(gl.ARRAY_BUFFER, buffersMakeup.color);
        // gl.vertexAttribPointer(
        //     programInfoMakeup.attribLocations.colorPosition,
        //     numComponents,
        //     type,
        //     normalize,
        //     stride,
        //     offset);
        // gl.enableVertexAttribArray(
        //     programInfoMakeup.attribLocations.colorPosition);


        gl.bindBuffer(gl.ARRAY_BUFFER, buffersMakeup.texture);
        gl.vertexAttribPointer(
            programInfoMakeup.attribLocations.texCoords,
            2,
            type,
            normalize,
            stride,
            offset);
        gl.enableVertexAttribArray(
            programInfoMakeup.attribLocations.texCoords);
    }

    // Tell WebGL to use our program when drawing
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffersMakeup.indices);
    gl.useProgram(programInfoMakeup.program);

    // Set the shader uniforms

    gl.uniformMatrix4fv(
        programInfoMakeup.uniformLocations.projectionMatrix,
        false,
        projectionMatrix);
    gl.uniformMatrix4fv(
        programInfoMakeup.uniformLocations.modelViewMatrix,
        false,
        modelViewMatrix);

    // gl.uniform4fv(
    //     programInfo.uniformLocations.color,
    //     1,
    //     new Float32Array([0.0, 1.0, 0.0, 1.0]),
    //     0                
    // );

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.uniform1i(programInfoMakeup.uniformLocations.sampler, 0);

    {
        const vertexCount = buffersMakeup.vertexCount;
        const type = gl.UNSIGNED_SHORT;
        const offset = 0;
        gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
    }
}

//
// Initialize a shader program, so WebGL knows how to draw our data
//
function initShaderProgram(gl, vsSource, fsSource) {
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

    // Create the shader program

    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    // If creating the shader program failed, alert

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
        return null;
    }

    return shaderProgram;
}

//
// creates a shader of the given type, uploads the source and
// compiles it.
//
function loadShader(gl, type, source) {
    const shader = gl.createShader(type);

    // Send the source to the shader object

    gl.shaderSource(shader, source);

    // Compile the shader program

    gl.compileShader(shader);

    // See if it compiled successfully

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        alert('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }

    return shader;
}

export { getMakeupProgramData, drawMakeupScene, loadMakeupTexture, initMakeupBuffers };

