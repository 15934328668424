import { useState, useEffect } from "react";
import step1 from "../images/icons/Step 1.svg";
import live from "../images/icons/model.svg";
import photo from "../images/icons/live.svg";
import step2 from "../images/icons/Makeup 2.svg";
import step3 from "../images/icons/Makeup 3.gif";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo/FelicitaLogo.svg";
import ImageUploading from "react-images-uploading";
import BgRight from "../images/icons/bg-right.png";
import BgLeft from "../images/icons/bg-left.png";
import uploadanimation from "../images/icons/uploadanimation3.gif";
import { BASE_URL } from "../configs/configs";
import axios from "axios";
import { validateInputImage } from "../makeup_utils/facemesh_utils.js"
import { toast } from 'react-toastify';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"

export const ConsentScreen = () => {
    const [agreeTNC, setAgreeTNC] = useState(false);
    const navigate = useNavigate();
    const [images,] = useState([]);
    const [customLogo, setCustomLogo] = useState(null);
    const [isPhotoUploading, setIsPhotoUploading] = useState(false);
    const [isErrorChecked, setIsErrorChecked] = useState(true);
    const [type, setType] = useState('');

    useEffect(() => {
        // FEL-766 Dynamic Titles
        // window.token = token
        let titleNames = {'makeup': 'Felicita', 'recode':'Recode', 'ekta': 'MIORA Beauty'}
        let title = 'Felicita'
        for( let i of Object.keys(titleNames)) {
            if (window.location.href.includes(i)) {
                title = titleNames[i]
            }
        }

        document.getElementById("virtualTitle").innerHTML = title + " - Virtual TryOn";

        axios.get(BASE_URL + "/api/configurations/").then((res) => {
            if (res.data.data) {
                setCustomLogo(res.data.data.front_logo);
            }
        }).catch((e) => console.log(e));
    }, [])


    const LoadCamera = (type) => {
        setType(type);
        setIsPhotoUploading(true);

        setTimeout(() => {
            setIsPhotoUploading(false);
            const urlParams = new URLSearchParams(window.location.search);
            // get token from the query parameters.
            var token = urlParams.get('t') || '';

            navigate('/Makeup-Tryon',{state: { tryonType: type, token: token }})

            sessionStorage.setItem('locationValue', JSON.stringify({ tryonType: type, token: token }));
        }, 2000);

    }

    const onChange = (imageList, addUpdateIndex) => {
        setIsPhotoUploading(true);

        let imgTag = document.createElement("img");
        if (imageList) {
            imgTag.src = imageList[imageList.length - 1].data_url;
        }

        axios.post(BASE_URL + "/api/get_resized_img/", { "img": imageList[imageList.length - 1].data_url }).then((res) => {
            if (res.data.data) {
                var new_img = {}
                new_img.data_url = res.data.data.cropped_img
                validateInputImage(imgTag).then((is_valid) => {
                    const urlParams = new URLSearchParams(window.location.search);
                    // get token from the query parameters.
                    var token = urlParams.get('t') || '';

                    if (is_valid) {
                        setIsPhotoUploading(false);
                        navigate('/Makeup-Tryon',{state: {
                            tryonType: 'upload_photo',
                            imageList: new_img,
                            token: token
                        }})
                        sessionStorage.setItem('locationValue', JSON.stringify({
                            tryonType: 'upload_photo',
                            imageList: new_img,
                            token: token
                        }));
                    } else {
                        setIsPhotoUploading(false);
                        toast.error("Please upload a valid image!", {
                            autoClose: 3000,
                        })
                    }
                }).catch((err) => {
                    setIsPhotoUploading(false);
                });
            }
        }).catch((err) => {
            setIsPhotoUploading(false);
        });
    }

    const validateImage = (errors) => {

        var err_msg = ''
        if (errors) {

            if (errors.acceptType) { err_msg = 'Invalid file type' }
            if (errors.maxFileSize) { err_msg = 'File size should be less than 5 Mb' }
            if (errors.resolution) { err_msg = 'Selected file is not match your desired resolution' }

            toast.error(err_msg, {
                autoClose: 3000,
            });
            setIsErrorChecked(false);
        }
        else {
            return true
        }
    }

    return (
        <div className="container-fluid bg-img cover-container d-flex mx-auto flex-column">
            {
                isPhotoUploading && <div className="d-flex align-items-center justify-content-center uploadanimation"> <div className="d-flex align-items-center justify-content-center uploadanimation-bg"> </div>
                    <img className="btn_icon_btn" src={uploadanimation} alt="uploadanimation"></img></div>
            }
            <div className={"card border-0 card-bg_main " + (isPhotoUploading ? 'card-bg_main_loading' : '')}>
                <div className="row g-0 justify-content-center verticleAlignMiddleMob">
                    <div
                        className={
                            "col-xl-5 col-md-12 col-lg-5 order-xl-2 order-md-2 order-2 rounded-3 bg-Opa"
                        }
                    >

                        <div
                            className={
                                "App h-100 stepMobileAlign"
                            }
                        >
                            <div className="bgleftimg">
                                <img src={BgLeft} alt="bg_left"></img>
                            </div>
                            <div className="bgrightimg">
                                <img src={BgRight} alt="bg_right"></img>
                            </div>
                            <div
                                className={"row g-0 mx-auto text-center mobile-logo-space"}>
                                {customLogo ? <img src={BASE_URL + customLogo} className="WidthClass" alt="logo" /> : <img src={logo} className="WidthClass" alt="logo" />}
                            </div>

                            <div className="col-md-8 mx-xs-2 offset-md-2 p-2">
                                <div className="text-center">
                                    <div className="card card-step">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="card-img-container my-auto">
                                                    <img src={step1} alt="camera" />
                                                </div>
                                                <div className="step-content">
                                                    <span className="h5">Step 1</span>
                                                    {/* FEL-766 Rename */}
                                                    <div className="text-secondary mt-2 small step-text"> Grant permission for Felicita to access your camera</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="text-center stepFirstPdng">

                                    <div className="card card-step">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="card-img-container my-auto">
                                                    <img src={step2} alt="camera" />
                                                </div>
                                                <div className="step-content">
                                                    <span className="h5">Step 2</span>
                                                    <div className="text-secondary mt-2 small step-text">For optimal results, ensure that you are properly positioned in a well-lit area</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="text-center stepFirstPdng">
                                    <div className="card card-step">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="card-img-container my-auto" style={{ padding: "17px" }}>
                                                    <img src={step3} alt="camera" />
                                                </div>
                                                <div className="step-content">
                                                    <span className="h5">Step 3</span>
                                                    <div className="text-secondary mt-2 small step-text">Enjoy virtual - live or photo try-on</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 px-0 mt-3 policyPart">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input checkboxCustom"
                                            type="checkbox"
                                            checked={agreeTNC}
                                            id="flexCheckDefault"
                                            onChange={() => {
                                                setAgreeTNC(!agreeTNC);
                                            }}
                                        />
                                        {/* FEL-766 Rename */}
                                        <label className="form-check-label text-secondary small" htmlFor="flexCheckDefault">
                                            I give my consent for processing of my image to Felicita, and acknowledge that Felicita will not retain/store my image. I'm aware of the fact that while sharing my picture on social media, I am bound by the social media's terms and conditions.
                                        </label>
                                    </div>
                                </div>

                                <div className="mt-2 mb-3">
                                    <ul className="nav justify-content-center">
                                        <li className="">
                                            <div
                                                onClick={() => LoadCamera('video')}
                                                className={"btn p-3 btn-card " + (!agreeTNC ? ' disabled' : '')}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                disabled={!agreeTNC}
                                                title="Launch virtual mirror"
                                            >

                                                <div className="">
                                                    <div className="">
                                                        <img src={live} className="btn_icon_btn" alt="camera" />
                                                        <p>Live</p>
                                                    </div>
                                                </div>
                                                {/* <FontAwesomeIcon icon={faVideo} /> Virtual Try-On */}
                                            </div>
                                        </li>
                                        <li className="">
                                            <ImageUploading
                                                multiple
                                                value={images}
                                                onChange={onChange}
                                                // maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                acceptType={window.photoAccessType}
                                                maxFileSize={5242880}
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    isDragging,
                                                    dragProps,
                                                    errors
                                                }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">

                                                        {(isErrorChecked && errors && validateImage(errors))}
                                                        <button
                                                            // onClick={() => LoadCamera("upload")}
                                                            className="btn p-3 btn-card"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            disabled={!agreeTNC || isPhotoUploading}
                                                            title="Upload Photo From Gallary"
                                                            style={isDragging ? { color: "red" } : null}
                                                            onClick={() => {
                                                                onImageUpload(errors)
                                                                setIsErrorChecked(true);
                                                                // LoadCamera('upload_photo')
                                                            }}
                                                            {...dragProps}
                                                        >
                                                            <div className="">
                                                                <div className="">
                                                                    <img src={photo} className="btn_icon_btn" alt="camera" />
                                                                    <p className="">{isPhotoUploading && type !== 'video' ? 'Uploading...' : 'Photo'}</p>
                                                                </div>
                                                                {/* <div className="">
                                                                <img src={photo} className="btn_icon_btn" alt="camera" />
                                                                <p>Photo</p>
                                                            </div> */}
                                                            </div>
                                                        </button>
                                                        {/* <p>Uploading....</p> */}
                                                        &nbsp;
                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item h-100">
                                                                <img
                                                                    id="image"
                                                                    src={image.data_url}
                                                                    alt=""
                                                                    height="inherit"
                                                                    width="inherit"
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}