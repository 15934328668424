import cv from "@techstark/opencv-js";
import interpolate from "../b-spline";


function IsEyelinerApplied() {
    var is_eyeliner = false
    // if ('Eyeliner' in window.apply) {
    //     is_eyeliner = true
    // }
    return is_eyeliner
}

function Eyeshadow(
    canvasCtx_1,
    canvasElement,
    canvasElement_1,
    landmarks,
    canvasCtx
) {
    try {

        var lower = [243, 133, 173, 157, 158, 159, 160, 161, 246, 33, 263, 466, 388, 387, 386, 385, 384, 398, 362];
        var leftEyeShadow = [130,247,30,29,27,28,56,190,243,133,173,157,158,159,160,161,246,33,130];
        var rightEyeShadow = [463,414,286,258,257,259,260,467,359,263,466,388,387,386,385,384,398,362,463]

        canvasElement_1.width = canvasElement.width;
        canvasElement_1.height = canvasElement.height;

        canvasCtx_1.fillStyle = "black";
        canvasCtx_1.fillRect(0, 0, canvasElement.width, canvasElement.height);

        // let points = [
        //     [
        //         landmarks[247].x * canvasElement.width,
        //         landmarks[247].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[30].x * canvasElement.width,
        //         landmarks[30].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[29].x * canvasElement.width,
        //         landmarks[29].y * canvasElement.height,
        //     ], //added
        //     [
        //         landmarks[27].x * canvasElement.width,
        //         landmarks[27].y * canvasElement.height,
        //     ], //added
        //     [
        //         landmarks[28].x * canvasElement.width,
        //         landmarks[28].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[56].x * canvasElement.width,
        //         landmarks[56].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[190].x * canvasElement.width,
        //         landmarks[190].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[243].x * canvasElement.width,
        //         landmarks[243].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[133].x * canvasElement.width,
        //         landmarks[133].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[173].x * canvasElement.width,
        //         landmarks[173].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[157].x * canvasElement.width,
        //         landmarks[157].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[158].x * canvasElement.width,
        //         landmarks[158].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[159].x * canvasElement.width,
        //         landmarks[159].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[160].x * canvasElement.width,
        //         landmarks[160].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[161].x * canvasElement.width,
        //         landmarks[161].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[246].x * canvasElement.width,
        //         landmarks[246].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[33].x * canvasElement.width,
        //         landmarks[33].y * canvasElement.height,
        //     ],

        // ];
        let points = []

        for (let i = 0; i < leftEyeShadow.length; i++) {
            let x = landmarks[leftEyeShadow[i]].x *  canvasElement.width
            let y = landmarks[leftEyeShadow[i]].y *  canvasElement.height
            // console.log("=-=-========s=ssss=====", lower.includes(leftEyeShadow[i]), leftEyeShadow[i])
            if (lower.includes(leftEyeShadow[i])) {
                var is_eyeliner = IsEyelinerApplied();
                if (is_eyeliner)
                    y = y - 8;
                else
                    y = y - 6;
            }
        
            points.push([x,y]);    
        }


        var degree = 1;
        let points_x = [];
        let points_y = [];

        //0,1/0.01
        for (var t = 0; t <= 1; t += 0.1) {
            var point = interpolate(t, degree, points);

            points_x.push(Math.floor(point[0]));
            points_y.push(Math.floor(point[1]));
        }

        canvasCtx_1.beginPath();
        canvasCtx_1.moveTo(points_x[0], points_y[0]);

        for (let i = 0; i < points_y.length - 1; i++) {
            canvasCtx_1.lineTo(points_x[i], points_y[i]);
        }
        canvasCtx_1.strokeStyle = "white";
        canvasCtx_1.stroke();
        canvasCtx_1.fillStyle = "white";
        canvasCtx_1.fill();

        // let points1 = [
        //     [
        //         landmarks[463].x * canvasElement.width,
        //         landmarks[463].y * canvasElement.height,
        //     ], //added
        //     [
        //         landmarks[414].x * canvasElement.width,
        //         landmarks[414].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[286].x * canvasElement.width,
        //         landmarks[286].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[258].x * canvasElement.width,
        //         landmarks[258].y * canvasElement.height,
        //     ], //added
        //     [
        //         landmarks[257].x * canvasElement.width,
        //         landmarks[257].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[259].x * canvasElement.width,
        //         landmarks[259].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[260].x * canvasElement.width,
        //         landmarks[260].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[467].x * canvasElement.width,
        //         landmarks[467].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[359].x * canvasElement.width,
        //         landmarks[359].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[263].x * canvasElement.width,
        //         landmarks[263].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[466].x * canvasElement.width,
        //         landmarks[466].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[388].x * canvasElement.width,
        //         landmarks[388].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[387].x * canvasElement.width,
        //         landmarks[387].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[386].x * canvasElement.width,
        //         landmarks[386].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[385].x * canvasElement.width,
        //         landmarks[385].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[384].x * canvasElement.width,
        //         landmarks[384].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[398].x * canvasElement.width,
        //         landmarks[398].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[362].x * canvasElement.width,
        //         landmarks[362].y * canvasElement.height,
        //     ],
        //     [
        //         landmarks[463].x * canvasElement.width,
        //         landmarks[463].y * canvasElement.height,
        //     ],
        // ];

        let points1 = []

        for (let i = 0; i < rightEyeShadow.length; i++) {
            let x = landmarks[rightEyeShadow[i]].x *  canvasElement.width
            let y = landmarks[rightEyeShadow[i]].y *  canvasElement.height

            if (lower.includes(rightEyeShadow[i])) {
                let is_eyeliner = IsEyelinerApplied();
                if (is_eyeliner)
                    y = y - 10;
                else
                    y = y - 6;
            }
        
            points1.push([x,y]);    
        }


        degree = 1;
        points_x = [];
        points_y = [];

        // //0,1/0.01
        let points_test = [];
        for (var pt2 = 0; pt2 <= 1; pt2 += 0.1) {
            var pointIpt = interpolate(pt2, degree, points1);

            points_test.push(Math.floor(pointIpt[0]), Math.floor(pointIpt[1]));

            points_x.push(Math.floor(pointIpt[0]));
            points_y.push(Math.floor(pointIpt[1]));
        }

        canvasCtx_1.beginPath();
        canvasCtx_1.moveTo(points_x[0], points_y[0]);

        for (let i = 1; i < points_y.length; i++) {
            canvasCtx.lineTo(points_x[i], points_y[i]);
            canvasCtx_1.lineTo(points_x[i], points_y[i]);
        }
        canvasCtx_1.strokeStyle = "white";

        canvasCtx_1.stroke();

        canvasCtx_1.fillStyle = "white";
        canvasCtx_1.fill();

        let imgData = canvasCtx.getImageData(
            0,
            0,
            canvasElement.width,
            canvasElement.height
        );


        let imgData_1 = canvasCtx_1.getImageData(
            0,
            0,
            canvasElement.width,
            canvasElement.height
        );

        let mat = cv.matFromImageData(imgData);

        cv.cvtColor(mat, mat, cv.COLOR_RGBA2RGB);



        let coords = [];

        coords = [landmarks[31].x * canvasElement.width, landmarks[31].y * canvasElement.height, landmarks[226].x * canvasElement.width, landmarks[226].y * canvasElement.height,
        landmarks[225].x * canvasElement.width, landmarks[53].y * canvasElement.height, landmarks[193].x * canvasElement.width, landmarks[55].y * canvasElement.height,
        landmarks[417].x * canvasElement.width, landmarks[285].y * canvasElement.height, landmarks[293].x * canvasElement.width, landmarks[293].y * canvasElement.height,
        landmarks[276].x * canvasElement.width, landmarks[446].y * canvasElement.height, landmarks[381].x * canvasElement.width, landmarks[381].y * canvasElement.height,
        landmarks[465].x * canvasElement.width, landmarks[465].y * canvasElement.height, landmarks[196].x * canvasElement.width, landmarks[196].y * canvasElement.height,
        landmarks[233].x * canvasElement.width, landmarks[233].y * canvasElement.height, landmarks[31].x * canvasElement.width, landmarks[31].y * canvasElement.height];



        //OG image taken as a mat


        function mask(num) {
            if (num <= 0) {
                num = 0;
            }

            if (num > 0) {
                num = 1;
            }
            return num;
        }

        //mask image taken as mat
        let mat_mask = cv.matFromImageData(imgData_1);


        cv.cvtColor(mat_mask, mat_mask, cv.COLOR_RGBA2GRAY);
        let mat_mask_array = mat_mask.data.map(mask);
        let mat_mask_mat = cv.matFromArray(
            mat_mask.rows,
            mat_mask.cols,
            cv.CV_32F,
            mat_mask_array
        );


        //NEW CODE IN TRY BLOCK BELOW


        try {
            var maskEye = new cv.Mat();
            var imgEye = new cv.Mat();
            var tmp_mat = cv.matFromArray(12, 2, cv.CV_32F, coords);
            var rect = cv.minAreaRect(tmp_mat);

            var box = cv.RotatedRect.points(rect);
            var newBox = [];

            for (let i = 0; i < box.length; i++) {
                newBox.push(parseInt(box[i].x));
                newBox.push(parseInt(box[i].y));
            }

            tmp_mat = cv.matFromArray(4, 2, cv.CV_32F, newBox);
            var fourVars = cv.boundingRect(tmp_mat);

            //CREATE MASK_EYE FROM EXISTING MASK


            maskEye = mat_mask_mat.roi(fourVars);
            imgEye = mat.roi(fourVars);
        }
        catch (e) {
            mat.delete();
            mat_mask.delete();
            mat_mask_mat.delete();
            tmp_mat.delete();
            maskEye.delete();
            imgEye.delete();
            console.log("============eyeshadow v2 ROI exception : ", e);
            return;
        }


        ////// BEGIN --- APPLY BLUSH FUNCTION OF OPENCV PYTHON CODE
        let imgOrg = imgEye.clone();

        let val = new cv.Mat();

        let pil_image = new cv.Mat();

        imgEye.convertTo(pil_image, cv.CV_32F, 1 / 255);

        cv.cvtColor(pil_image, val, cv.COLOR_RGB2Lab, 0);


        // let width = imgEye.rows; //changes
        // let height = imgEye.cols;

        let average = cv.mean(val);

        let L = average[0];
        let A = average[1];
        let B = average[2];

        // let r_color = 255; //223
        // let g_color = 0;
        // let b_color = 0; //111

        let r = cv.matFromArray(1, 1, cv.CV_32F, [window.eyeshadow_ra / 255]);
        let g = cv.matFromArray(1, 1, cv.CV_32F, [window.eyeshadow_ga / 255]);
        let Bl = cv.matFromArray(1, 1, cv.CV_32F, [window.eyeshadow_ba / 255]);

        //   // let r = cv.matFromArray(1, 1, cv.CV_32F, [window.lips_ra / 255]);
        //   // let g = cv.matFromArray(1, 1, cv.CV_32F, [window.lips_ga / 255]);
        //   // let Bl = cv.matFromArray(1, 1, cv.CV_32F, [window.lips_ba / 255]);

        let rgbaPlanes = new cv.MatVector();

        rgbaPlanes.push_back(r);
        rgbaPlanes.push_back(g);
        rgbaPlanes.push_back(Bl);

        let temp_lab = new cv.Mat();
        cv.merge(rgbaPlanes, temp_lab);
        cv.cvtColor(temp_lab, temp_lab, cv.COLOR_RGBA2RGB);
        cv.cvtColor(temp_lab, temp_lab, cv.COLOR_RGB2Lab);

        let LabPlanes = new cv.MatVector();

        cv.split(temp_lab, LabPlanes);

        let L1 = LabPlanes.get(0);
        let A1 = LabPlanes.get(1);
        let B1 = LabPlanes.get(2);

        L1 = L1.data32F[0];
        A1 = A1.data32F[0];
        B1 = B1.data32F[0];

        let intensity = window.eyeshadow;

        let ll = (L1 - L) * intensity; //check these values again
        let aa = (A1 - A) * intensity;
        let bb = (B1 - B) * intensity;

        function myFunction_l(num) {
            num = num + ll;
            if (num < 0) {
                num = 0;
            }

            if (num > 100) {
                num = 100;
            }
            return num;
        }

        function myFunction_a(num) {
            num = num + aa;

            if (num < -127) {
                num = -127;
            }

            if (num > 128) {
                num = 128;
            }
            return num;
        }

        function myFunction_b(num) {
            num = num + bb;

            if (num < -127) {
                num = -127;
            }

            if (num > 128) {
                num = 128;
            }
            return num;
        }

        let val1Planes = new cv.MatVector();

        cv.split(val, val1Planes);

        let v_0 = val1Planes.get(0);
        let v_1 = val1Planes.get(1);
        let v_2 = val1Planes.get(2);

        let v_0_l = v_0.data32F.map(myFunction_l);
        let v_0_l_mat = cv.matFromArray(v_0.rows, v_0.cols, cv.CV_32F, v_0_l);

        let v_1_a = v_1.data32F.map(myFunction_a);
        let v_1_a_mat = cv.matFromArray(v_1.rows, v_1.cols, cv.CV_32F, v_1_a);

        let v_2_b = v_2.data32F.map(myFunction_b);
        let v_2_b_mat = cv.matFromArray(v_2.rows, v_2.cols, cv.CV_32F, v_2_b);

        let labPlanes = new cv.MatVector();
        labPlanes.push_back(v_0_l_mat);
        labPlanes.push_back(v_1_a_mat);
        labPlanes.push_back(v_2_b_mat);

        let val2 = new cv.Mat();

        cv.merge(labPlanes, val2);

        cv.cvtColor(val2, val2, cv.COLOR_Lab2RGB);
        val2.convertTo(val2, cv.CV_32F, 255);
        //   ////// END --- APPLY BLUSH FUNCTION OF OPENCV PYTHON CODE

        let ksize = new cv.Size(21, 21);
        //   // You can try more different parameters
        cv.GaussianBlur(maskEye, mat_mask_mat, ksize, 0, 0, cv.BORDER_DEFAULT);

        let imgBlur3D = new cv.Mat(mat_mask_mat.rows, mat_mask_mat.cols, val2.type());

        let imgBlur3D_planes = new cv.MatVector();

        imgBlur3D_planes.push_back(mat_mask_mat);
        imgBlur3D_planes.push_back(mat_mask_mat);
        imgBlur3D_planes.push_back(mat_mask_mat);



        cv.merge(imgBlur3D_planes, imgBlur3D);

        let output_mul_1 = new cv.Mat();

        cv.multiply(imgBlur3D, val2, output_mul_1);

        let image_ones = new cv.Mat.ones(
            imgEye.rows,
            imgEye.cols,
            cv.CV_8UC1
        );

        cv.cvtColor(image_ones, image_ones, cv.COLOR_RGBA2RGB);
        image_ones.convertTo(image_ones, cv.CV_32F);

        let output_sub = new cv.Mat();
        cv.subtract(image_ones, imgBlur3D, output_sub);

        let output_mul_2 = new cv.Mat();
        imgOrg.convertTo(imgOrg, cv.CV_32F);
        cv.multiply(output_sub, imgOrg, output_mul_2);

        let final_image = new cv.Mat();

        cv.add(output_mul_1, output_mul_2, final_image);
        final_image.convertTo(final_image, cv.CV_8U);

        let maskOrg = new cv.Mat.zeros(mat.rows, mat.cols, mat.type());
        let maskOrg1 = new cv.Mat.ones(final_image.rows, final_image.cols, mat.type());


        let x = maskOrg.roi(fourVars);

        final_image.copyTo(x);
        x.convertTo(x, cv.CV_8U);

        let y = mat.roi(fourVars);
        maskOrg1.copyTo(y);
        y.convertTo(y, cv.CV_8U);
        let latest = new cv.Mat();
        cv.add(maskOrg, mat, latest);
        cv.imshow("canvas_output", latest);


        x.delete();
        y.delete();
        mat.delete();
        val.delete();
        pil_image.delete();
        r.delete();
        g.delete();
        Bl.delete();
        rgbaPlanes.delete();
        temp_lab.delete();
        LabPlanes.delete();
        val1Planes.delete();
        v_0.delete();
        v_1.delete();
        v_2.delete();
        v_0_l_mat.delete();
        v_1_a_mat.delete();
        v_2_b_mat.delete();
        labPlanes.delete();
        val2.delete();
        mat_mask.delete();
        mat_mask_mat.delete();
        imgOrg.delete();
        imgBlur3D.delete();
        imgBlur3D_planes.delete();
        output_mul_1.delete();
        image_ones.delete();
        output_sub.delete();
        output_mul_2.delete();
        final_image.delete();
        imgEye.delete();
        maskEye.delete();
        maskOrg.delete();
        maskOrg1.delete();
        tmp_mat.delete();
        latest.delete();

    }
    catch (e) {
        console.log("------------------EXCEPTION IN makeup_eyeshadow Eyeshadow : ", e);
    }
}

export { Eyeshadow };