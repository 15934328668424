import React,{useEffect, useState} from 'react'
import selfie_info from "../images/icons/selfie_infoN.png"
import { skinToneDetect } from '../makeup_utils/tryon_handler';

export default function CapturePhoto (props) {
  const [agreeTNC, setAgreeTNC] = useState(false);
  const [isFirst,setIsFirst]=useState(false)

  useEffect(() => {
    props.setShowProducts(false);
    // eslint-disable-next-line
  }, [])
  

  const openModal = () => {
    if (!agreeTNC && !isFirst) {
      setIsFirst(true);
    }
  };
  const handleAnalyze =async  () =>{
    let getToneData = await skinToneDetect(true);
    props.setProductData(getToneData);
    props.setShowProducts(true);
  }

  return (
    <div>
       <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mobMl-0 FavMain">
        <div className="p-4 mt-4 text-secondary small">
          {/* FEL-766 Rename */}
          <div>
            <p>By taking your picture, Felicita will only process that image and will not store your image anywhere. For more information, see our Privacy Policy </p>
            <div className="form-check">

              <input
                className="form-check-input checkboxCustom"
                type="checkbox"
                checked={agreeTNC}
                id="flexCheckDefault"
                onChange={(e) => {
                  openModal();
                  setAgreeTNC(!agreeTNC);
                }}
                data-bs-toggle={isFirst ? '' : 'modal'}
                data-bs-target={isFirst ? '' : '#capturePhotoModal'}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {/* FEL-766 Rename */}
                I consent to processing of my image by Felicita as set out in the Privacy Policy.
              </label>
            </div>
          </div>
          <div className={"row mx-2 mt-3" + (agreeTNC ? ' ' : ' opacity-50')}>
            {agreeTNC ? 
            <button className="btn btn-theme" onClick={()=>handleAnalyze()}>
              Analyze
            </button> : 
            <button className="btn btn-theme" disabled>
              Analyze
            </button>}
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btn-link small text-secondary" data-bs-toggle="modal" data-bs-target="#capturePhotoModal">See guide for an efficient picture.</button>
          </div>
          
          <div className="modal fade" id="capturePhotoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          <div className="modal-body">
            <img src={selfie_info} className="img w-100 h-auto" alt="sample" />
          </div>
    </div>
  </div>
</div>
        </div>
        <img alt="staticImage" id="image" style={{ height: '150px', width: '150px' }} src="" className="img d-none" />
      </div>
    </div>
  )
}
