import React from 'react'

export default function OriginalCanvas(props) {
  return (
    <>
        <canvas
            id="canvas_output"
            ref={props.canvasRef}
            className="output_canvas CanvasPosition"
            style={{
            position: "absolute",
            top: 0,
            left: 0,
            }}
            // willReadFrequently="true"
        ></canvas>
        <canvas
        id="canvas_webgl"
        ref={props.webglRef}
        className="output_canvas CanvasPositionGl"
        style={{
            display: "none",
            position: "absolute",
        }}
        // willReadFrequently="true"
        ></canvas>
        <canvas
        id="canvas_webgl2"
        ref={props.webglRef2}
        className="output_canvas CanvasPositionGl"
        style={{
            position: "absolute",
        }}
        // willReadFrequently="true"
        ></canvas>
        <canvas
        id="canvas_color"
        ref={props.colorCanvasRef}
        className="output_canvas CanvasPosition"
        style={{
            display: "none",
            position: "absolute",
        }}
        // willReadFrequently="true"
        ></canvas>
    </>
  )
}
