import React from 'react'

export default function CompareCanvas(props) {
  return (
    <canvas
        id="canvas_output_original"
        ref={props.canvasOriginalRef}
        className={"CanvasPosition " + (props.is_visible ? '': 'd-none')}
        style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: props.is_visible ? 8 : 10
            }}
    ></canvas>
  )
}
