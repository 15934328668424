// api url
//testing
var baseUrl = "";
if (window.location.hostname.split('.')[1] === "localhost" || window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    baseUrl = "http://127.0.0.1:8000";
}
// baseUrl = "http://192.168.8.176:8000";
//  baseUrl="https://makeup.selfstylo.com"

export const BASE_URL = baseUrl;

// webcam access variables
export const websiteURL = "https://makeup.selfstylo.com";
export const HASHTAGS = "#SelfStylo";
export const Quote = "SelfStylo";

