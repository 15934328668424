import { DeviceUUID } from "device-uuid";
import { getAuth, signInAnonymously } from "firebase/auth";
import cv from "@techstark/opencv-js";

function getDeviceUUID() {
  var du = new DeviceUUID().parse();
  //console.log("!!!!!!!!!!!! du : ",du);
  var dua = [
    du.language,
    du.platform,
    du.os,
    du.cpuCores,
    du.isAuthoritative,
    du.silkAccelerated,
    du.isKindleFire,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    du.isSmartTV,
    du.pixelDepth,
    du.isTouchScreen,
  ];
  let uid = du.hashMD5(dua.join(":"));
  return window.firebaseUid ?? uid;
}

  const getDeviceUUIDAnonymous = async () => {
    // console.log("getDeviceUUIDAnonymous");
    const auth = getAuth();
    let res = await signInAnonymously(auth);
    if (res) {
      if (res.user) {
        if (res.user.uid) {
          window.firebaseUid = res.user.uid;
          localStorage.setItem("uid", res.user.uid);
        }
      }
    }
    // console.log(window.firebaseUid, "resssssssssssssss");
    // .then((response) => {
    //   // Signed in..
    //   console.log(response);
    // })
    // .catch((error) => {
    //   console.log(error);
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   // ...
    // });
  };



  function changeEyelinerTextureColor(x) {
    let matTexture = cv.imread("image_tex_eyeliner_left");
    let matTextureCopy = new cv.Mat();
    matTexture.copyTo(matTextureCopy);
    cv.cvtColor(matTextureCopy, matTextureCopy, cv.COLOR_RGBA2RGB);


  
    function changeRChannel(num) {
      if (num === 0) {
        num = parseInt(x[0]);
  
      }
      return num;
    }
    function changeGChannel(num) {
      if (num === 0) {
        num = parseInt(x[1]);
  
      }
      return num;
    }
    function changeBChannel(num) {
      if (num === 0) {
        num = parseInt(x[2]);
  
      }
      return num;
    }
  
  
    let textureChannels = new cv.MatVector();
    cv.split(matTextureCopy, textureChannels);
  
    let channelR = textureChannels.get(0);
    let channelG = textureChannels.get(1);
    let channelB = textureChannels.get(2);
  
  
  
    let channelRArr = channelR.data.map(changeRChannel);
  
    channelR = cv.matFromArray(channelR.rows, channelR.cols, cv.CV_8U, channelRArr);
  
  
    let channelGArr = channelG.data.map(changeGChannel);
  
    channelG = cv.matFromArray(channelG.rows, channelG.cols, cv.CV_8U, channelGArr);
  
  
    let channelBArr = channelB.data.map(changeBChannel);
  
    channelB = cv.matFromArray(channelB.rows, channelB.cols, cv.CV_8U, channelBArr);
  
  
  
    textureChannels = new cv.MatVector();
    textureChannels.push_back(channelR);
    textureChannels.push_back(channelG);
    textureChannels.push_back(channelB);
    // textureChannels.push_back(channelA);
    matTextureCopy = new cv.Mat();
    cv.merge(textureChannels, matTextureCopy);
  
  
  
    function changeToTransparent(num) {
      if (num === 255) {
  
        num = 0;
      }
      return num;
    }
  
    function copyFromMat(num) {
      return num;
    }
  
    cv.cvtColor(matTextureCopy, matTextureCopy, cv.COLOR_RGB2RGBA);

    textureChannels = new cv.MatVector();
    cv.split(matTextureCopy, textureChannels);
  
    let channelMatR = textureChannels.get(0);
    let channelMatG = textureChannels.get(1);
    let channelMatB = textureChannels.get(2);
    let channelMatA = textureChannels.get(3);
  
    let arr = channelMatR.data.map(copyFromMat);
  
  
    let arr1 = channelMatG.data.map(copyFromMat);
  
  
    let arr2 = channelMatB.data.map(copyFromMat);
  
    let arr3 = channelMatA.data.map(copyFromMat);
  
    for (let i = 0; i < arr.length; i++) {
  
      if (arr[i] === 255 && arr1[i] === 255 && arr2[i] === 255)
        arr3[i] = 0;
    }
  
  
  
    channelMatA = cv.matFromArray(channelMatA.rows, channelMatA.cols, cv.CV_8U, arr3);
  
    arr = channelMatR.data.map(changeToTransparent);
    channelMatR = cv.matFromArray(channelMatR.rows, channelMatR.cols, cv.CV_8U, arr);
  
    arr = channelMatG.data.map(changeToTransparent);
    channelMatG = cv.matFromArray(channelMatG.rows, channelMatG.cols, cv.CV_8U, arr);
  
    arr = channelMatB.data.map(changeToTransparent);
    channelMatB = cv.matFromArray(channelMatB.rows, channelMatB.cols, cv.CV_8U, arr);
  
  
    textureChannels = new cv.MatVector();
    textureChannels.push_back(channelMatR);
    textureChannels.push_back(channelMatG);
    textureChannels.push_back(channelMatB);
    textureChannels.push_back(channelMatA);
  
    matTextureCopy = new cv.Mat();
    cv.merge(textureChannels, matTextureCopy);
  
    let kernelMat = cv.Mat.ones(5, 5, cv.CV_8U);
    // kernelMat.convertTo(kernelMat, cv.CV_32F, 1 / 25);
  
    // cv.filter2D(matTextureCopy, matTextureCopy, -1, kernelMat);
  
    cv.erode(matTextureCopy, matTextureCopy, kernelMat);

    // new eyeliner implementation
    kernelMat = cv.Mat.ones(3, 3, cv.CV_8U);
    cv.morphologyEx(matTextureCopy, matTextureCopy, cv.MORPH_CROSS, kernelMat)

    let ksize = new cv.Size(7, 7);
    cv.GaussianBlur(matTextureCopy, matTextureCopy, ksize, 0, 0, cv.BORDER_ISOLATED);
  
    let canv = document.createElement("canvas");
    cv.imshow(canv, matTextureCopy);

    matTexture.delete();
    matTextureCopy.delete();
    kernelMat.delete();
    textureChannels.delete();

    return canv;
}
var oldCoordinates = [];
var hold;
function lerp(a, b, alpha) {
  return a + alpha * (b - a);
}
function getInterPolatedCords(_val_1, _val_aplha = 0.5) {
  var newCoordinates = [];
  if (oldCoordinates.length === 0) {
    oldCoordinates.push(_val_1);
    hold = oldCoordinates[0];
  }
  else {
    for (let i = 0; i < hold.length; i++) {
      newCoordinates.push({
        x: lerp(hold[i].x, _val_1[i].x, 0.7),
        y: lerp(hold[i].y, _val_1[i].y, 0.7),
        z: lerp(hold[i].z, _val_1[i].z, 0.7)
      })
    }
  }
  oldCoordinates = [];
  oldCoordinates.push(_val_1);
  hold = oldCoordinates[0];
  if (newCoordinates.length === 0) {
    return _val_1;
  }
  else {
    return newCoordinates;
  }

}
export { getDeviceUUID, getDeviceUUIDAnonymous, changeEyelinerTextureColor, getInterPolatedCords };