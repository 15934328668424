import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { MakeupAPIs } from '../network/api';
import {chunk} from '../makeup_utils/common_utils'
import useWindowDimensions from '../makeup_utils/window_dimensions';

export default function MakeupCategories(props) {
  const [categoryData, setCategoryData] = useState([]);
  const {width}=useWindowDimensions()
  const sliderRef = useRef();
  const api = new MakeupAPIs();

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  var mobile_settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          // slidesToShow: 5,
          // slidesToScroll: 4,
          // initialSlide: 2,
          // arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          // arrows: false,
        },
      },
    ],
  };

  useEffect(() => {

    if (props.activeMakeupType?.id !== null && props.activeMakeupType?.id !== undefined) {
      api.getAllCategories(props.activeMakeupType.id).then((res) => {
        setCategoryData(res);

        //Added for FEL-417 point number 12 Author: Pruthvi Barot
        var chunk_data = chunk(res, 4);
        // added slider index to keep track of active slide
        var slider_index = 0; // by default set to 0

        if (props.activeMakeupType.id in props.activeMakeupItem) {
          // FEL-417 For handling removal of applied makeup from layers
          if ((props?.activeProduct?.id) !== null) {
            const cdata = Object.entries(props.activeMakeupItem[props.activeMakeupType.id])[0];
            var categ_id = parseInt(cdata[0])
            props.setActiveCategory(categ_id);
            
            // check active category id in chunks, if present then return chunk's index
            // and set that index as active slide index
            chunk_data.forEach((element,i) => {
            var categ_id_list = element.map((el, i) => {return el.id})
            if (categ_id_list.includes(categ_id)) {
              slider_index = i;
            }
          });
          }
          else {
            slider_index = 0;
            props.setActiveCategory(res?.[0]?.id);
          }
        } else {
          slider_index = 0;
          props.setActiveCategory(res?.[0]?.id);
        }

        // set slide index to animate the slider and go to the active slide
        setTimeout(() => {
          sliderRef.current.slickGoTo(slider_index);
        }, 600);
        props.setShowProducts(true);
      });
    }

  //eslint-disable-next-line
  }, [props.activeMakeupType]);

  const categoryClickHandler = (id) => {
    api.categoryCount(id)
    props.setActiveCategory(id);
   
    if(width<=600){
      props.setShowCategories(false)
    }
  };

 
  return (
  <>
 
    <div className="row px-3">
    
    {width <= 600 ? 
    categoryData.length > 0 && <Slider {...mobile_settings}>
              {categoryData.map((item, id) => {
                  return (
                    <div className="px-2" key={id}>
                      <div className="categ-block">
                        <div 
                          onClick={() => categoryClickHandler(item.id)}
                          className="categoryBlock card-radius category-card shadow-sm text-center border p-2 my-1 "> 
                          {item.category_name}
                        </div>
                      </div>
                    </div>
                   
                  );
                })}
            </Slider> 
  
            : 
            <Slider {...settings} ref={sliderRef}>
        {categoryData.length > 0 && chunk(categoryData,4).map((slide, slideIndex) => (
          <div className="swiper-slide" key={slideIndex}>
            <div className="categ-block">
              <div className="row">
                {slide.map((element, elementIndex) => (
                  <div className="col-md-6" key={elementIndex}>
                    <div
                      id={element.id}
                      onClick={() => categoryClickHandler(element.id)}
                      className={
                        "categoryBlock card-radius category-card shadow-sm text-center border p-2 my-1 " +
                        (props.activeCategory === element.id ? 'activeCateg' : '')
                      }
                    >
                      {element.category_name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    }    
    </div>
  </>
  );
}
