import { initializeFaceMesh } from "./facemesh_utils.js";
import cv from "@techstark/opencv-js";
import interpolate from "../makeup_libs/b-spline.js";
import { Eyeshadow } from "../makeup_libs/makeup/makeup_eyeshadow";
import { getChanneledMat, getExtendedLandmarks, getMask, maskOverlay } from "../makeup_libs/makeup/foundationCv.js";

import { getInterPolatedCords } from "../makeup_libs/utils.js";

import {
    getMakeupProgramData,
    drawMakeupScene,
    loadMakeupTexture,
    initMakeupBuffers,
} from "../makeup_libs/webgl/simple_lipstick_renderer";

import {
    getGlossProgramData,
    drawGlossScene,
    loadGlossColorTexture,
    initGlossBuffers,
} from "../makeup_libs/webgl/gloss_lipstick_renderer";

import {
    getLeftEyeshadowProgramData,
    drawLeftEyeshadowScene,
    loadLeftEyeshadowTexture,
    initLeftEyeshadowBuffers,
} from "../makeup_libs/webgl/simple_left_eyeshadow_renderer";

import {
    getLiptickProgramData,
    drawLipstickScene,
    loadLipstickTexture,
    initLipstickBuffers,
} from "../makeup_libs/webgl/lipstickRenderer.js";


import {
    getConclearProgramData,
    drawConclearScene,
    loadConclearTexture,
    initConclearBuffers,
} from "../makeup_libs/webgl/conclear.js";

import {
    getLiptickEffectProgramData,
    drawLipstickEffectScene,
    loadLipstickEffectTexture,
    initLipstickEffectBuffers,
} from "../makeup_libs/webgl/lipstickEffectRenderer.js";

import {
    getGlitterLeftEyeshadowProgramData,
    drawGlitterLeftEyeshadowScene,
    loadGlitterLeftEyeshadowTexture,
    initGlitterLeftEyeshadowBuffers,
} from "../makeup_libs/webgl/glitter_left_eyeshadow_renderer";

import {
    getLeftEyelinerProgramData,
    drawLeftEyelinerScene,
    loadLeftEyelinerTexture,
    initLeftEyelinerBuffers,
} from "../makeup_libs/webgl/left_eyeliner_renderer";

import {
    getRightEyeshadowProgramData,
    drawRightEyeshadowScene,
    loadRightEyeshadowTexture,
    initRightEyeshadowBuffers,
} from "../makeup_libs/webgl/simple_right_eyeshadow_renderer";

import {
    getGlitterRightEyeshadowProgramData,
    drawGlitterRightEyeshadowScene,
    loadGlitterRightEyeshadowTexture,
    initGlitterRightEyeshadowBuffers,
} from "../makeup_libs/webgl/glitter_right_eyeshadow_renderer";

//LIPSTICK TEXTURES
import getLipstickTextureImage from "../images/LipstickTextures/New Lipstick May 2023 1.png";
import getLipGloss from "../images/LipstickTextures/LipstickGLoss2.png";
import getLipGlitter from "../images/LipstickTextures/lipstickGlitter.png";
import getLipGlitterGold from "../images/LipstickTextures/gold_glitter.png";
import getLipGlitterSilver from "../images/LipstickTextures/silver_glitter.png";
import getLipMetallic from "../images/LipstickTextures/lipstickMetallic.png";
import imgeSrcEyeliner from "../images/default_eyeliner.png";

//Blush Texture
import imgBlushTexture from '../images/BlushTextures/blushBaseTexture.png'
import imgConcealerTexture from '../images/ConcealerTextures/concealer_fade2.png' //option2 is better
import { MakeupAPIs } from "../network/api.js";
import Smoothening from "../makeup_libs/makeup/smootheningCv.js";

/*EYELINER TEXTURES*/
// import imgSrcEyelinerLeft from "../images/eyeliner_1.png";
// import imgSrcEyelinerLeft from "../images/thick-flick.png";
//BELOW ONES WORKING 
// import imgSrcEyelinerLeft from "../images/wide-flick-eyeliner_03.png";
// import imgSrcEyelinerLeft from "../images/eyeliner_01.png";  
// import imgSrcEyelinerLeft from "../images/eyeliner_08_02.png";
// import imgSrcEyelinerLeft from "../images/eyeliner_08_03.png";
//import imgSrcEyelinerLeft from "../images/eyeliner_08_03_01.png";
// import imgSrcEyelinerLeft from "../images/eyeliner_08_03_02.png";
/* TEST TEXTURE*/
// import imgSrcEyelinerLeft from "../images/eyeliner_test.png";
/*EXPERIMENTAL */
// import imgSrcEyelinerLeft from "../images/green_hair1.png";
// import imgSrcEyelinerLeft from "../images/eyeliner_08_01.png";
// import imgSrcEyelinerLeft from "../images/eyeliner_08_03_01.png";
// import imgSrcEyelinerRight from "../images/iris_texture_05_03.png";
// import imgSrcEyelinerRight from "../images/test.png";

//import imgFaceCutout from "../images/face-02.png";
// import beauty  from "../images/icons/beauty.svg";
// import beauty_red  from "../images/icons/beauty_red.svg";

var interPolatedValues = 0.95;
var concealerIntensity = 0.57;

var getLipstickTexture = document.createElement('img');
getLipstickTexture.src = getLipstickTextureImage;
var getLipGlossTexture = document.createElement('img');
getLipGlossTexture.src = getLipGloss
var getLipGlitterTexture = document.createElement('img');
getLipGlitterTexture.src = getLipGlitter;

var getLipGlitterGoldTexture = document.createElement('img');
getLipGlitterGoldTexture.src = getLipGlitterGold;

var getLipGlitterSilverTexture = document.createElement('img');
getLipGlitterSilverTexture.src = getLipGlitterSilver;

var getLipGMetallicTexture = document.createElement('img');
getLipGMetallicTexture.src = getLipMetallic;

var getBlushTexture = document.createElement('img');
getBlushTexture.src = imgBlushTexture;

var getConcealerTexture = document.createElement('img');
getConcealerTexture.src = imgConcealerTexture;

let glLipstickBase, glLipstickGloss, glMetallic, glLipstickGlitter;

var canvasElement,glCanvas,colorCanvas, canvasElement_1, canvasElementOriginal;
var canvasCtx, gl2, colorCanvasContext, canvasCtx_1, canvasCtxOriginal;

var makeup_items = {};
var mediapipe_results = [];
var image_tag = null;
var storeLandmarks = null;
var tryonType = '';
var isSmootheningApplied = false;

window.intensity = 1;
window.lipstickIntensity = 0.35;

// makeup type
window.eyeshadow = 0.85;
// window.lips = 1
window.blush = 0.5;

window.eyeliner = 4;
window.eyelinerWing = 2;

window.eyebrows = 0.8;

window.KajalWidth = 2;

window.foundation_intensity = 0.2;

window.photoAccessType = ['jpg','jpeg','jpe','jif','jfif','jfi','png','gif','webp',
    'tiff','tif','psd','raw','arw','cr2','nrw','k25','bmp','dib','heif','heic',
    'ind','indd','indt','jp2','j2k','jpf','jpx','jpm','mj2','svg', 'svgz','ai','eps']


const initialiseCanvas = (baseCanvas, glCan, maskCanvas, originalCanvas) => {

    canvasElement = baseCanvas;
    glCanvas = glCan;
    canvasElement_1 = maskCanvas;
    canvasElementOriginal = originalCanvas;

    canvasCtx = baseCanvas.getContext("2d");

    gl2 = glCanvas.getContext("webgl", {
        preserveDrawingBuffer: true,
        premultipliedAlpha: true,
    });

    let colorCanvasTemp = document.createElement('canvas');
    colorCanvasTemp.width = baseCanvas.width;
    colorCanvasTemp.height = baseCanvas.height;
    // colorCanvasTemp.style.display = "none";

    colorCanvas = colorCanvasTemp;
    colorCanvasContext = colorCanvas.getContext("2d");

    canvasCtx_1 = maskCanvas.getContext("2d");
    maskCanvas.style.display = "none";

    if (canvasElementOriginal) {
        canvasCtxOriginal = canvasElementOriginal.getContext("2d");
    }
}

const clearGlCanvas = (gl) => {

    gl.clearColor(0.0, 0.0, 0.0, 0.0); // Clear to black, fully opaque
    //gl.clearDepth(1.0);                 // Clear everything
    gl.disable(gl.DEPTH_TEST);
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    // Enable depth testing
    //gl.depthFunc(gl.LEQUAL);            // Near things obscure far things

    gl.clear(gl.COLOR_BUFFER_BIT);
}

const lipstickTryon = (i, landmarks) => {
    // let isLipstick = true;
    window.lips_ra = makeup_items[i]["color"][0];
    window.lips_ga = makeup_items[i]["color"][1];
    window.lips_ba = makeup_items[i]["color"][2];

    /**
     * Prepare a color canvas for the selected lipstick color
     */
    colorCanvasContext.fillStyle =
    "rgb(" +
    window.lips_ra +
    "," +
    window.lips_ga +
    "," +
    window.lips_ba +
    ")";
    colorCanvasContext.fillRect(
    0,
    0,
    colorCanvas.width,
    colorCanvas.height
    );

    /**
     * Draw Lipstick with webgl code
     */
    var glMakeup = getMakeupProgramData(glCanvas, gl2);
    //var imgTagGlitter;
    var glGloss;
    let imgTagGloss = document.getElementById("image_tex_gloss");

    if (getLipstickTexture.src === null || getLipstickTexture.src === undefined || getLipstickTexture === "")
    getLipstickTexture.src = getLipstickTextureImage;
    if (getLipGlossTexture.src === null || getLipGlossTexture.src === undefined || getLipGlossTexture === "")
    getLipGlossTexture.src = getLipGloss;
    if (getLipGlitterTexture.src === null || getLipGlitterTexture.src === undefined || getLipGlitterTexture === "")
    getLipGlitterTexture.src = getLipGlitter;
    if (getLipGMetallicTexture.src === null || getLipGMetallicTexture.src === undefined || getLipGMetallicTexture === "")
    getLipGMetallicTexture.src = getLipMetallic;

    if (getLipGlitterGoldTexture.src === null || getLipGlitterGoldTexture.src === undefined || getLipGlitterGoldTexture === "")
    getLipGlitterGoldTexture.src = getLipGlitterGold;

    if (getLipGlitterSilverTexture.src === null || getLipGlitterSilverTexture.src === undefined || getLipGlitterSilverTexture === "")
    getLipGlitterSilverTexture.src = getLipGlitterSilver;

    let lipStickColorChange = [];
    lipStickColorChange.push(window.lips_ra, window.lips_ga, window.lips_ba);



    var lipstickIntensity = makeup_items[i]["intensity"];
    var lipstickBlendIntensity = makeup_items[i]["blendIntensity"] || 0.0;

    // console.log(makeup_items[i]["product_type_code"])
    switch (makeup_items[i]["product_type_code"] || 0) {

    //CASE FOR JUST BASE OR MATTEE TEXTURE
    case 0:
        glLipstickBase = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glLipstickBase.texture =
        loadLipstickTexture(
            gl2,
            getLipstickTexture,
        );
        glLipstickBase.buffers =
        initLipstickBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawLipstickScene(
        gl2,
        glLipstickBase.programInfo,
        glLipstickBase.buffers,
        glLipstickBase.texture,
        lipstickIntensity,
        lipstickBlendIntensity,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );
        gl2.deleteTexture(glLipstickBase.texture);
        break;


    //CASE FOR GLOSS TEXTURE
    case 1:
        glLipstickBase = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glLipstickBase.texture =
        loadLipstickTexture(
            gl2,
            getLipstickTexture,
        );
        glLipstickBase.buffers =
        initLipstickBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawLipstickScene(
        gl2,
        glLipstickBase.programInfo,
        glLipstickBase.buffers,
        glLipstickBase.texture,
        lipstickIntensity,
        lipstickBlendIntensity,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );


        glLipstickGloss = getLiptickEffectProgramData(
        glCanvas,
        gl2
        );
        glLipstickGloss.texture =
        loadLipstickEffectTexture(
            gl2,
            getLipGlossTexture,
        );
        glLipstickGloss.buffers =
        initLipstickEffectBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawLipstickEffectScene(
        gl2,
        glLipstickGloss.programInfo,
        glLipstickGloss.buffers,
        glLipstickGloss.texture,
        0.25,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );

        gl2.deleteTexture(glLipstickGloss.texture);
        gl2.deleteTexture(glLipstickBase.texture);

        break;


    //CASE FOR MATEE CV
    case 2:
        glMakeup = getMakeupProgramData(glCanvas, gl2);
        glMakeup.texture = loadMakeupTexture(
        gl2,
        colorCanvas
        );

        glMakeup.buffers = initMakeupBuffers(gl2, landmarks);
        drawMakeupScene(
        gl2,
        glMakeup.programInfo,
        glMakeup.buffers,
        glMakeup.texture
        );


        break;


    //CASE FOR GLOSS CV
    case 3:
        glMakeup = getMakeupProgramData(glCanvas, gl2);
        glMakeup.texture = loadMakeupTexture(
        gl2,
        colorCanvas
        );

        glMakeup.buffers = initMakeupBuffers(gl2, landmarks);
        drawMakeupScene(
        gl2,
        glMakeup.programInfo,
        glMakeup.buffers,
        glMakeup.texture
        );
        //
        glGloss = getGlossProgramData(glCanvas, gl2);
        glGloss.texture2 = loadGlossColorTexture(
        gl2,
        imgTagGloss
        );
        glGloss.texture = loadGlossColorTexture(
        gl2,
        colorCanvas
        );
        glGloss.buffers = initGlossBuffers(gl2, landmarks);
        drawGlossScene(
        gl2,
        glGloss.programInfo,
        glGloss.buffers,
        glGloss.texture,
        glGloss.texture2,
        0.3
        );
        gl2.deleteTexture(glGloss.texture2);


        break;


    //CASE FOR GLITTER TEXTURE 
    case 4:
        glLipstickBase = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glLipstickBase.texture =
        loadLipstickTexture(
            gl2,
            getLipstickTexture,
        );
        glLipstickBase.buffers =
        initLipstickBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawLipstickScene(
        gl2,
        glLipstickBase.programInfo,
        glLipstickBase.buffers,
        glLipstickBase.texture,
        lipstickIntensity,
        lipstickBlendIntensity,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );

        glLipstickGlitter = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glLipstickGlitter.texture =
        loadLipstickTexture(
            gl2,
            getLipGlitterTexture,
        );
        glLipstickGlitter.buffers =
        initLipstickBuffers(gl2, landmarks);
        drawLipstickScene(
        gl2,
        glLipstickGlitter.programInfo,
        glLipstickGlitter.buffers,
        glLipstickGlitter.texture,
        0.5,
        0.0,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );
        gl2.deleteTexture(glLipstickBase.texture);
        gl2.deleteTexture(glLipstickGlitter.texture);
        break;


    //CASE FOR BOTH GLOSS and GLITTER TEXTURE
    case 5:

        //BASE TEXTURE
        glLipstickBase = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glLipstickBase.texture =
        loadLipstickTexture(
            gl2,
            getLipstickTexture,
        );
        glLipstickBase.buffers =
        initLipstickBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawLipstickScene(
        gl2,
        glLipstickBase.programInfo,
        glLipstickBase.buffers,
        glLipstickBase.texture,
        lipstickIntensity,
        lipstickBlendIntensity,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );

        //GLOSS TEXTURE
        glLipstickGloss = getLiptickEffectProgramData(
        glCanvas,
        gl2
        );
        glLipstickGloss.texture =
        loadLipstickEffectTexture(
            gl2,
            getLipGlossTexture,
        );
        glLipstickGloss.buffers =
        initLipstickEffectBuffers(gl2, landmarks);
        drawLipstickEffectScene(
        gl2,
        glLipstickGloss.programInfo,
        glLipstickGloss.buffers,
        glLipstickGloss.texture,
        0.25,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );



        //GLITTER TEXTURE
        glLipstickGlitter = getLiptickEffectProgramData(
        glCanvas,
        gl2
        );
        glLipstickGlitter.texture =
        loadLipstickEffectTexture(
            gl2,
            getLipGlitterSilverTexture,
        );
        glLipstickGlitter.buffers =
        initLipstickEffectBuffers(gl2, landmarks);
        drawLipstickEffectScene(
        gl2,
        glLipstickGlitter.programInfo,
        glLipstickGlitter.buffers,
        glLipstickGlitter.texture,
        0.60,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );

        gl2.deleteTexture(glLipstickBase.texture);
        gl2.deleteTexture(glLipstickGlitter.texture);
        gl2.deleteTexture(glLipstickGloss.texture);

        break;


    //CASE FOR GLOSS & GLITTER(Golden) TEXTURE
    case 15:
        glLipstickBase = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glLipstickBase.texture =
        loadLipstickTexture(
            gl2,
            getLipstickTexture,
        );
        glLipstickBase.buffers =
        initLipstickBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawLipstickScene(
        gl2,
        glLipstickBase.programInfo,
        glLipstickBase.buffers,
        glLipstickBase.texture,
        lipstickIntensity,
        lipstickBlendIntensity,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );

        glLipstickGloss = getLiptickEffectProgramData(
        glCanvas,
        gl2
        );
        glLipstickGloss.texture =
        loadLipstickEffectTexture(
            gl2,
            getLipGlossTexture,
        );
        glLipstickGloss.buffers =
        initLipstickEffectBuffers(gl2, landmarks);
        drawLipstickEffectScene(
        gl2,
        glLipstickGloss.programInfo,
        glLipstickGloss.buffers,
        glLipstickGloss.texture,
        0.25,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );


        glLipstickGlitter = getLiptickEffectProgramData(
        glCanvas,
        gl2
        );
        glLipstickGlitter.texture =
        loadLipstickEffectTexture(
            gl2,
            getLipGlitterGoldTexture,
        );
        glLipstickGlitter.buffers =
        initLipstickEffectBuffers(gl2, landmarks);
        drawLipstickEffectScene(
        gl2,
        glLipstickGlitter.programInfo,
        glLipstickGlitter.buffers,
        glLipstickGlitter.texture,
        0.60,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );

        gl2.deleteTexture(glLipstickBase.texture);
        gl2.deleteTexture(glLipstickGlitter.texture);
        gl2.deleteTexture(glLipstickGloss.texture);

        break;


    //CASE FOR GLITTER(Golden) TEXTURE
    case 16:
        glLipstickBase = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glLipstickBase.texture =
        loadLipstickTexture(
            gl2,
            getLipstickTexture,
        );
        glLipstickBase.buffers =
        initLipstickBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawLipstickScene(
        gl2,
        glLipstickBase.programInfo,
        glLipstickBase.buffers,
        glLipstickBase.texture,
        lipstickIntensity,
        lipstickBlendIntensity,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );


        glLipstickGlitter = getLiptickEffectProgramData(
        glCanvas,
        gl2
        );
        glLipstickGlitter.texture =
        loadLipstickEffectTexture(
            gl2,
            getLipGlitterGoldTexture,
        );
        glLipstickGlitter.buffers =
        initLipstickEffectBuffers(gl2, landmarks);
        drawLipstickEffectScene(
        gl2,
        glLipstickGlitter.programInfo,
        glLipstickGlitter.buffers,
        glLipstickGlitter.texture,
        0.60,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );

        gl2.deleteTexture(glLipstickBase.texture);
        gl2.deleteTexture(glLipstickGlitter.texture);

        break;


    //CASE FOR METALLIC TEXTURE               
    case 8:
        glLipstickBase = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glLipstickBase.texture =
        loadLipstickTexture(
            gl2,
            getLipstickTexture,
        );
        glLipstickBase.buffers =
        initLipstickBuffers(gl2, landmarks);
        drawLipstickScene(
        gl2,
        glLipstickBase.programInfo,
        glLipstickBase.buffers,
        glLipstickBase.texture,
        lipstickIntensity,
        lipstickBlendIntensity,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );

        glMetallic = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glMetallic.texture =
        loadLipstickTexture(
            gl2,
            getLipGMetallicTexture,
        );
        glMetallic.buffers =
        initLipstickBuffers(gl2, landmarks);
        drawLipstickScene(
        gl2,
        glMetallic.programInfo,
        glMetallic.buffers,
        glMetallic.texture,
        0.25,
        0.0,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );

        gl2.deleteTexture(glMetallic.texture);
        gl2.deleteTexture(glLipstickBase.texture);

        break;


    //CASE FOR METALLIC CV
    case 9:
        glMakeup = getMakeupProgramData(glCanvas, gl2);
        glMakeup.texture = loadMakeupTexture(
        gl2,
        colorCanvas
        );

        glMakeup.buffers = initMakeupBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawMakeupScene(
        gl2,
        glMakeup.programInfo,
        glMakeup.buffers,
        glMakeup.texture
        );
        //
        imgTagGloss = document.getElementById("image_tex_metallic_lipstick_1");
        glGloss = getGlossProgramData(glCanvas, gl2);
        glGloss.texture2 = loadGlossColorTexture(
        gl2,
        imgTagGloss
        );
        glGloss.texture = loadGlossColorTexture(
        gl2,
        colorCanvas
        );
        glGloss.buffers = initGlossBuffers(gl2, landmarks);
        drawGlossScene(
        gl2,
        glGloss.programInfo,
        glGloss.buffers,
        glGloss.texture,
        glGloss.texture2,
        0.175
        );
        gl2.deleteTexture(glGloss.texture2);

        break;


    //CASE FOR METALLIC 2 CV
    case 10:
        glMakeup = getMakeupProgramData(glCanvas, gl2);
        glMakeup.texture = loadMakeupTexture(
        gl2,
        colorCanvas
        );

        glMakeup.buffers = initMakeupBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawMakeupScene(
        gl2,
        glMakeup.programInfo,
        glMakeup.buffers,
        glMakeup.texture
        );
        //
        imgTagGloss = document.getElementById("image_tex_metallic_lipstick_2");
        glGloss = getGlossProgramData(glCanvas, gl2);
        glGloss.texture2 = loadGlossColorTexture(
        gl2,
        imgTagGloss
        );
        glGloss.texture = loadGlossColorTexture(
        gl2,
        colorCanvas
        );
        glGloss.buffers = initGlossBuffers(gl2, landmarks);
        drawGlossScene(
        gl2,
        glGloss.programInfo,
        glGloss.buffers,
        glGloss.texture,
        glGloss.texture2,
        0.175
        );
        gl2.deleteTexture(glGloss.texture2);


        break;


    //DEFAULT CASE ONLY MATTEE TEXTURE
    default:
        let glLipstickTextureDefault = getLiptickProgramData(
        glCanvas,
        gl2
        );
        glLipstickTextureDefault.texture =
        loadLipstickTexture(
            gl2,
            getLipstickTexture,
        );
        glLipstickTextureDefault.buffers =
        initLipstickBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
        drawLipstickScene(
        gl2,
        glLipstickTextureDefault.programInfo,
        glLipstickTextureDefault.buffers,
        glLipstickTextureDefault.texture,
        lipstickIntensity,
        lipstickBlendIntensity,
        [(lipStickColorChange[0] / 255), (lipStickColorChange[1] / 255), (lipStickColorChange[2] / 255)]
        );
        gl2.deleteTexture(glLipstickTextureDefault.texture);
        break;
    }
}

const eyeshadowTryon = (i, landmarks) => {
    window.eyeshadow_ra = makeup_items[i]["color"][0];
    window.eyeshadow_ga = makeup_items[i]["color"][1];
    window.eyeshadow_ba = makeup_items[i]["color"][2];

    var glLeftEyeshadow,
        glRightEyeshadow,
        glGlitterLeftEyeshadow,
        glGlitterRightEyeshadow;

    var imgTagGlitterEyeshadow = document.getElementById(
        "image_tex_glitter_micro"
    );
    /**
     * Prepare a color canvas for the selected lipstick color
     */
    colorCanvasContext.fillStyle =
        "rgb(" +
        window.eyeshadow_ra +
        "," +
        window.eyeshadow_ga +
        "," +
        window.eyeshadow_ba +
        ")";
    colorCanvasContext.fillRect(
        0,
        0,
        colorCanvas.width,
        colorCanvas.height
    );
    var imgTagGlitter;
    switch (makeup_items[i]["product_type_code"] || 0) {
        case 0:
        Eyeshadow(
            canvasCtx_1,
            canvasElement,
            canvasElement_1,
            landmarks,
            canvasCtx
        );
        break;
        case 2:
        glLeftEyeshadow = getLeftEyeshadowProgramData(glCanvas, gl2);
        glLeftEyeshadow.texture = loadLeftEyeshadowTexture(
            gl2,
            "container.jpg",
            colorCanvas
        );
        glLeftEyeshadow.buffers = initLeftEyeshadowBuffers(
            gl2,
            landmarks
        );
        drawLeftEyeshadowScene(
            gl2,
            glLeftEyeshadow.programInfo,
            glLeftEyeshadow.buffers,
            glLeftEyeshadow.texture
        );

        glRightEyeshadow = getRightEyeshadowProgramData(glCanvas, gl2);
        glRightEyeshadow.texture = loadRightEyeshadowTexture(
            gl2,
            "container.jpg",
            colorCanvas
        );
        glRightEyeshadow.buffers = initRightEyeshadowBuffers(
            gl2,
            landmarks
        );
        drawRightEyeshadowScene(
            gl2,
            glRightEyeshadow.programInfo,
            glRightEyeshadow.buffers,
            glRightEyeshadow.texture
        );
        break;
        case 4:
        glLeftEyeshadow = getLeftEyeshadowProgramData(glCanvas, gl2);
        glLeftEyeshadow.texture = loadLeftEyeshadowTexture(
            gl2,
            "container.jpg",
            colorCanvas
        );
        glLeftEyeshadow.buffers = initLeftEyeshadowBuffers(
            gl2,
            landmarks
        );
        drawLeftEyeshadowScene(
            gl2,
            glLeftEyeshadow.programInfo,
            glLeftEyeshadow.buffers,
            glLeftEyeshadow.texture
        );

        glRightEyeshadow = getRightEyeshadowProgramData(glCanvas, gl2);
        glRightEyeshadow.texture = loadRightEyeshadowTexture(
            gl2,
            "container.jpg",
            colorCanvas
        );
        glRightEyeshadow.buffers = initRightEyeshadowBuffers(
            gl2,
            landmarks
        );
        drawRightEyeshadowScene(
            gl2,
            glRightEyeshadow.programInfo,
            glRightEyeshadow.buffers,
            glRightEyeshadow.texture
        );

        glGlitterLeftEyeshadow = getGlitterLeftEyeshadowProgramData(
            glCanvas,
            gl2
        );
        //
        glGlitterLeftEyeshadow.texture =
            loadGlitterLeftEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitter
            );
        glGlitterLeftEyeshadow.buffers =
            initGlitterLeftEyeshadowBuffers(gl2, landmarks);
        drawGlitterLeftEyeshadowScene(
            gl2,
            glGlitterLeftEyeshadow.programInfo,
            glGlitterLeftEyeshadow.buffers,
            glGlitterLeftEyeshadow.texture
        );

        glGlitterRightEyeshadow = getGlitterRightEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterRightEyeshadow.texture =
            loadGlitterRightEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitter
            );
        glGlitterRightEyeshadow.buffers =
            initGlitterRightEyeshadowBuffers(gl2, landmarks);
        drawGlitterRightEyeshadowScene(
            gl2,
            glGlitterRightEyeshadow.programInfo,
            glGlitterRightEyeshadow.buffers,
            glGlitterRightEyeshadow.texture
        );
        gl2.deleteTexture(glGlitterRightEyeshadow.texture);
        gl2.deleteTexture(glGlitterLeftEyeshadow.texture);
        break;
        //Micro glitter
        case 6:
        imgTagGlitter = document.getElementById(
            "image_tex_glitter_micro"
        );
        Eyeshadow(
            canvasCtx_1,
            canvasElement,
            canvasElement_1,
            landmarks,
            canvasCtx
        );
        glGlitterLeftEyeshadow = getGlitterLeftEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterLeftEyeshadow.texture =
            loadGlitterLeftEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitter
            );
        glGlitterLeftEyeshadow.buffers =
            initGlitterLeftEyeshadowBuffers(gl2, landmarks);
        drawGlitterLeftEyeshadowScene(
            gl2,
            glGlitterLeftEyeshadow.programInfo,
            glGlitterLeftEyeshadow.buffers,
            glGlitterLeftEyeshadow.texture
        );
        glGlitterRightEyeshadow = getGlitterRightEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterRightEyeshadow.texture =
            loadGlitterRightEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitter
            );
        glGlitterRightEyeshadow.buffers =
            initGlitterRightEyeshadowBuffers(gl2, landmarks);
        drawGlitterRightEyeshadowScene(
            gl2,
            glGlitterRightEyeshadow.programInfo,
            glGlitterRightEyeshadow.buffers,
            glGlitterRightEyeshadow.texture
        );
        gl2.deleteTexture(glGlitterRightEyeshadow.texture);
        gl2.deleteTexture(glGlitterLeftEyeshadow.texture);
        break;
        //Nano glitter
        case 7:
        imgTagGlitter = document.getElementById(
            "image_tex_glitter_nano"
        );
        Eyeshadow(
            canvasCtx_1,
            canvasElement,
            canvasElement_1,
            landmarks,
            canvasCtx
        );

        glGlitterLeftEyeshadow = getGlitterLeftEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterLeftEyeshadow.texture =
            loadGlitterLeftEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitter
            );
        glGlitterLeftEyeshadow.buffers =
            initGlitterLeftEyeshadowBuffers(gl2, landmarks);
        drawGlitterLeftEyeshadowScene(
            gl2,
            glGlitterLeftEyeshadow.programInfo,
            glGlitterLeftEyeshadow.buffers,
            glGlitterLeftEyeshadow.texture
        );

        glGlitterRightEyeshadow = getGlitterRightEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterRightEyeshadow.texture =
            loadGlitterRightEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitter
            );
        glGlitterRightEyeshadow.buffers =
            initGlitterRightEyeshadowBuffers(gl2, landmarks);
        drawGlitterRightEyeshadowScene(
            gl2,
            glGlitterRightEyeshadow.programInfo,
            glGlitterRightEyeshadow.buffers,
            glGlitterRightEyeshadow.texture
        );
        gl2.deleteTexture(glGlitterRightEyeshadow.texture);
        gl2.deleteTexture(glGlitterLeftEyeshadow.texture);
        break;
        //metallic eyeshadow 1
        case 11:

        Eyeshadow(
            canvasCtx_1,
            canvasElement,
            canvasElement_1,
            landmarks,
            canvasCtx
        );
        //BACKDROP
        imgTagGlitterEyeshadow = document.getElementById(
            "image_tex_metallic_backdrop"
        );
        glGlitterLeftEyeshadow = getGlitterLeftEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterLeftEyeshadow.texture =
            loadGlitterLeftEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitterEyeshadow
            );
        glGlitterLeftEyeshadow.buffers =
            initGlitterLeftEyeshadowBuffers(gl2, landmarks);
        drawGlitterLeftEyeshadowScene(
            gl2,
            glGlitterLeftEyeshadow.programInfo,
            glGlitterLeftEyeshadow.buffers,
            glGlitterLeftEyeshadow.texture,
            0.1
        );

        glGlitterRightEyeshadow = getGlitterRightEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterRightEyeshadow.texture =
            loadGlitterRightEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitterEyeshadow
            );
        glGlitterRightEyeshadow.buffers =
            initGlitterRightEyeshadowBuffers(gl2, landmarks);
        drawGlitterRightEyeshadowScene(
            gl2,
            glGlitterRightEyeshadow.programInfo,
            glGlitterRightEyeshadow.buffers,
            glGlitterRightEyeshadow.texture,
            0.1
        );

        //GLITTER

        imgTagGlitterEyeshadow = document.getElementById(
            "image_tex_metallic_eyeshadow_1"
        );
        glGlitterLeftEyeshadow = getGlitterLeftEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterLeftEyeshadow.texture =
            loadGlitterLeftEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitterEyeshadow
            );
        glGlitterLeftEyeshadow.buffers =
            initGlitterLeftEyeshadowBuffers(gl2, landmarks);
        drawGlitterLeftEyeshadowScene(
            gl2,
            glGlitterLeftEyeshadow.programInfo,
            glGlitterLeftEyeshadow.buffers,
            glGlitterLeftEyeshadow.texture,
            0.4
        );

        glGlitterRightEyeshadow = getGlitterRightEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterRightEyeshadow.texture =
            loadGlitterRightEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitterEyeshadow
            );
        glGlitterRightEyeshadow.buffers =
            initGlitterRightEyeshadowBuffers(gl2, landmarks);
        drawGlitterRightEyeshadowScene(
            gl2,
            glGlitterRightEyeshadow.programInfo,
            glGlitterRightEyeshadow.buffers,
            glGlitterRightEyeshadow.texture,
            0.4
        );

        gl2.deleteTexture(glGlitterRightEyeshadow.texture);
        gl2.deleteTexture(glGlitterLeftEyeshadow.texture);

        break;
        //metallic eyeshadow 2
        case 12:

        Eyeshadow(
            canvasCtx_1,
            canvasElement,
            canvasElement_1,
            landmarks,
            canvasCtx
        );

        //BACKDROP
        imgTagGlitterEyeshadow = document.getElementById(
            "image_tex_metallic_backdrop"
        );
        glGlitterLeftEyeshadow = getGlitterLeftEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterLeftEyeshadow.texture =
            loadGlitterLeftEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitterEyeshadow
            );
        glGlitterLeftEyeshadow.buffers =
            initGlitterLeftEyeshadowBuffers(gl2, landmarks);
        drawGlitterLeftEyeshadowScene(
            gl2,
            glGlitterLeftEyeshadow.programInfo,
            glGlitterLeftEyeshadow.buffers,
            glGlitterLeftEyeshadow.texture,
            0.1
        );

        glGlitterRightEyeshadow = getGlitterRightEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterRightEyeshadow.texture =
            loadGlitterRightEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitterEyeshadow
            );
        glGlitterRightEyeshadow.buffers =
            initGlitterRightEyeshadowBuffers(gl2, landmarks);
        drawGlitterRightEyeshadowScene(
            gl2,
            glGlitterRightEyeshadow.programInfo,
            glGlitterRightEyeshadow.buffers,
            glGlitterRightEyeshadow.texture,
            0.1
        );

        //GLITTER

        imgTagGlitterEyeshadow = document.getElementById(
            "image_tex_metallic_eyeshadow_2"
        );
        glGlitterLeftEyeshadow = getGlitterLeftEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterLeftEyeshadow.texture =
            loadGlitterLeftEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitterEyeshadow
            );
        glGlitterLeftEyeshadow.buffers =
            initGlitterLeftEyeshadowBuffers(gl2, landmarks);
        drawGlitterLeftEyeshadowScene(
            gl2,
            glGlitterLeftEyeshadow.programInfo,
            glGlitterLeftEyeshadow.buffers,
            glGlitterLeftEyeshadow.texture,
            0.4
        );

        glGlitterRightEyeshadow = getGlitterRightEyeshadowProgramData(
            glCanvas,
            gl2
        );
        glGlitterRightEyeshadow.texture =
            loadGlitterRightEyeshadowTexture(
            gl2,
            "container.jpg",
            imgTagGlitterEyeshadow
            );
        glGlitterRightEyeshadow.buffers =
            initGlitterRightEyeshadowBuffers(gl2, landmarks);
        drawGlitterRightEyeshadowScene(
            gl2,
            glGlitterRightEyeshadow.programInfo,
            glGlitterRightEyeshadow.buffers,
            glGlitterRightEyeshadow.texture,
            0.4
        );

        gl2.deleteTexture(glGlitterRightEyeshadow.texture);
        gl2.deleteTexture(glGlitterLeftEyeshadow.texture);

        break;
        default:
        Eyeshadow(
            canvasCtx_1,
            canvasElement,
            canvasElement_1,
            landmarks,
            canvasCtx
        );
        break;
    }
}

const blushTryon = (i, landmarks) => {
    window.cheek_ra = makeup_items[i]["color"][0];
    window.cheek_ga = makeup_items[i]["color"][1];
    window.cheek_ba = makeup_items[i]["color"][2];

    var blushIntensity = window.blush;
    let blushColorChange = [];

    blushColorChange.push(window.cheek_ra, window.cheek_ga, window.cheek_ba);

    let glBlushTexture = getLiptickProgramData(
        glCanvas,
        gl2
    );
    glBlushTexture.texture =
        loadLipstickTexture(
        gl2,
        getBlushTexture,
        );
    glBlushTexture.buffers =
        initLipstickBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
    drawLipstickScene(
        gl2,
        glBlushTexture.programInfo,
        glBlushTexture.buffers,
        glBlushTexture.texture,
        blushIntensity,
        0.0,
        [(blushColorChange[0] / 255), (blushColorChange[1] / 255), (blushColorChange[2] / 255)]
    );
    gl2.deleteTexture(glBlushTexture.texture);
    // Cheek(
    //   canvasCtx_1,
    //   canvasElement,
    //   canvasElement_1,
    //   landmarks,
    //   canvasCtx
    // );
}

const eyelinerTryon = (i, landmarks) => {
    try {
      //PRE PROCESS TEXUTURE

        //   let active_eye_style = props.activeEyelinerStyle.current
        // let active_eye_style = false;
        // let color_id = makeup_items[i]['id'];

        let tex_img = makeup_items[i]?.['style_image'] || imgeSrcEyeliner;
        // if (color_id && active_eye_style) {
        // tex_img = window.eyeliner_textures.find(
        //     o => o.product_id === color_id && o.style_id === active_eye_style)['texture_image']
        // }
        // if (!tex_img) {
        // tex_img = window.eyeliner_textures?.find(
        //     o => o.product_id === color_id)?.['texture_image']
        // }

        let canv = new Image();

        canv.onload = function () {
            window.eyelinear = makeup_items[i]?.["code"] || false;
            if (window.eyelinear) {
    
                let x = window.eyelinear
                .toString()
                .substring(4, window.eyelinear.toString().length - 1);
                x = x.split(",");
            
                colorCanvasContext.fillStyle = "rgb(" +x[0]+"," +x[1]+"," +x[2]+")";
                colorCanvasContext.fillRect(
                0,
                0,
                colorCanvas.width,
                colorCanvas.height
                );
            
                //LEFT EYE SHADER
                let glLeftEyeliner = getLeftEyelinerProgramData(
                glCanvas,
                gl2
                );
                glLeftEyeliner.texture =
                loadLeftEyelinerTexture(
                    gl2,
                    "container.jpg",
                    canv,
                    //imgSrcEyelinerTex
                    // colorCanvas
                );
                glLeftEyeliner.buffers =
                initLeftEyelinerBuffers(gl2, landmarks);
                drawLeftEyelinerScene(
                gl2,
                glLeftEyeliner.programInfo,
                glLeftEyeliner.buffers,
                glLeftEyeliner.texture,
                0.9,
                [x[0] / 255, x[1] / 255, x[2] / 255]
                );
            }
        }
        canv.crossOrigin="anonymous";
        canv.src=tex_img;
    }
    catch (e) {
      console.log("------e : ", e);
    }
}

const foundationTryon = (i, landmarks) => {
    window.foundation_ra = makeup_items[i]["color"][0];
    window.foundation_ga = makeup_items[i]["color"][1];
    window.foundation_ba = makeup_items[i]["color"][2];
    let foundationColor = [window.foundation_ra, window.foundation_ga, window.foundation_ba]
    let channeledImage = getChanneledMat(canvasElement);
    let filteredLandmarks = getExtendedLandmarks(landmarks, canvasElement);
    let maskedImage = getMask(landmarks,filteredLandmarks, channeledImage, foundationColor);//FEL-676
    maskOverlay(channeledImage, maskedImage);
}

const smootheningTryon = (_isApplied) => {
    isSmootheningApplied = _isApplied
    applyMakeup(makeup_items);
}

const concealerTryon = (i, landmarks) => {
    window.conceal_ra = makeup_items[i]["color"][0];
    window.conceal_ga = makeup_items[i]["color"][1];
    window.conceal_ba = makeup_items[i]["color"][2];

    let concealerColorChange = [];

    concealerColorChange.push(window.conceal_ra, window.conceal_ga, window.conceal_ba);

    let glColcealerTexture = getConclearProgramData(
        glCanvas,
        gl2
    );
    glColcealerTexture.texture =
        loadConclearTexture(
        gl2,
        getConcealerTexture,
        );
    glColcealerTexture.buffers =
        initConclearBuffers(gl2, getInterPolatedCords(landmarks, interPolatedValues));
    drawConclearScene(
        gl2,
        glColcealerTexture.programInfo,
        glColcealerTexture.buffers,
        glColcealerTexture.texture,
        concealerIntensity,
        0.0,
        [(concealerColorChange[0] / 255), (concealerColorChange[1] / 255), (concealerColorChange[2] / 255)]
    );
    gl2.deleteTexture(glColcealerTexture.texture);
}

const skinToneDetect = async  (skinTone = false)=>{
    if(skinTone){
        let landmarks = storeLandmarks;
        let cpoints = [
            [landmarks[123].x * canvasElement.width, landmarks[123].y * canvasElement.height],
            [landmarks[117].x * canvasElement.width, landmarks[117].y * canvasElement.height],
            [landmarks[118].x * canvasElement.width, landmarks[118].y * canvasElement.height],
            [landmarks[101].x * canvasElement.width, landmarks[101].y * canvasElement.height],
            [landmarks[36].x * canvasElement.width, landmarks[36].y * canvasElement.height],
            [landmarks[187].x * canvasElement.width, landmarks[216].y * canvasElement.height],
            [landmarks[147].x * canvasElement.width, landmarks[147].y * canvasElement.height],
            [landmarks[123].x * canvasElement.width, landmarks[123].y * canvasElement.height]
          ]
          var degree = 1;
          let points_x = []
          let points_y = []
          for (var t = 0; t <= 1; t += 0.1) {
            var point = interpolate(t, degree, cpoints);
            points_x.push(Math.floor(point[0]))
            points_y.push(Math.floor(point[1]))
          }
          canvasCtx_1.beginPath();
          canvasCtx_1.moveTo(points_x[0], points_y[0]);
          for (let i = 0; i < points_y.length - 1; i++) {
            canvasCtx_1.lineTo(points_x[i], points_y[i]);
          }
          canvasCtx_1.strokeStyle = 'white';
          canvasCtx_1.stroke();
          canvasCtx_1.fillStyle = 'white';
          canvasCtx_1.fill()
          let imgData = canvasCtx.getImageData(0, 0, canvasElement.width, canvasElement.height);
          let imgData_1 = canvasCtx_1.getImageData(0, 0, canvasElement.width, canvasElement.height);
          let mat = cv.matFromImageData(imgData_1)
          let mat1 = cv.matFromImageData(imgData)
          cv.cvtColor(mat, mat, cv.COLOR_RGBA2RGB)
          cv.cvtColor(mat1, mat1, cv.COLOR_RGBA2RGB)
          function mask(num) {
            if (num <= 0) {
              num = 0
            }
            if (num > 0) {
              num = 1;
            }
            return num;
          }
          let mat_mask = cv.matFromImageData(imgData_1)
          cv.cvtColor(mat_mask, mat_mask, cv.COLOR_RGBA2GRAY)
          let mat_mask_array = mat_mask.data.map(mask)
          let mat_mask_mat = cv.matFromArray(mat_mask.rows, mat_mask.cols, cv.CV_64F, mat_mask_array);
          let mat_mask_mat1 = cv.matFromArray(mat_mask.rows, mat_mask.cols, cv.CV_64F, mat_mask_array);
          mat_mask_mat1.convertTo(mat_mask_mat1, cv.CV_8U);
          let face_image = new cv.Mat();
          mat1.copyTo(face_image, mat_mask_mat1);
          let face_image1 = new cv.MatVector()
          face_image1 = face_image.clone()
          cv.cvtColor(face_image1, face_image1, cv.COLOR_RGBA2RGB)
          cv.cvtColor(face_image1, face_image1, cv.COLOR_BGR2HSV)
          if (window.try_on_type === 'video') {
            cv.imshow('canvas_output', face_image1)
          }
          let faceplanes = new cv.MatVector()
          cv.split(face_image1, faceplanes);
          let fb = faceplanes.get(2)
          function sorting(numbers) {
            const sorted = numbers.slice().sort((a, b) => a - b);
            return sorted
          }
          let sorted_array_b = sorting(fb.data)
          let last_array_b = sorted_array_b.filter(function (x) { return x !== 0; })
          function median(values) {
            values.sort(function (a, b) {
              return a - b;
            });
            var half = Math.floor(values.length / 2);
            if (values.length % 2)
              return values[half];
            else
              return (values[half - 1] + values[half]) / 2.0;
          }
          // let r_medval = parseInt(median(last_array_r))
          // let g_medval = parseInt(median(last_array_g))
          let b_medval = parseInt(median(last_array_b))
          var message = null;
  
          if (b_medval >= 244 && b_medval <= 255) {
            message = "Fair";
            
          } else if (b_medval >= 211 && b_medval <= 243) {
            message = "Light";
            
          } else if (b_medval >= 173 && b_medval <= 210) {
            message = "Medium";
            
          } else if (b_medval >= 90 && b_medval <= 172) {
            message = "Dark";
            
          } else if (b_medval >= 26 && b_medval <= 89) {
            message = "Deep";
            
        } else {
            message = "Unable to detect, Make sure you have even lighting!";
            
          }
          let api = new MakeupAPIs();
          let toneData = [];
          
          cpoints = [];
          points_x = [];
          points_y = [];
          
          mat.delete();
          mat_mask.delete();
          mat_mask_mat.delete();
          face_image.delete();
          faceplanes.delete();
          mat_mask_mat1.delete();
          mat1.delete();
          await api.colorChange(message).then((_res)=>{
              skinTone = false;
              toneData = _res
            });
        return toneData;
        }

}
const makeupTryOn = (results) => {
    mediapipe_results = results;
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
    results.image,
    0,
    0,
    canvasElement.width,
    canvasElement.height
    );

    if (canvasCtxOriginal) {
        canvasCtxOriginal.save();
        canvasCtxOriginal.clearRect(
            0,
            0,
            canvasElementOriginal.width,
            canvasElementOriginal.height
          );
          canvasCtxOriginal.drawImage(
            results.image,
            0,
            0,
            canvasElementOriginal.width,
            canvasElementOriginal.height
          );
          canvasCtxOriginal.restore();
    }



    if (results.multiFaceLandmarks && results.multiFaceLandmarks.length > 0) {
        for (const landmarks of results.multiFaceLandmarks) {
            storeLandmarks = landmarks;
            clearGlCanvas(gl2);
    
            // FEL-766 New Smoothening
            if(isSmootheningApplied){
                Smoothening(landmarks, canvasElement, canvasCtx);
            }

            for (let i in makeup_items) {
                try {
        
                    switch (i) {
                        case "Lipstick":
                            lipstickTryon(i, landmarks);
                            break;
                        case "Eyeshadow":
                            eyeshadowTryon(i, landmarks);
                            break;
                        case "Blush":
                            blushTryon(i, landmarks);
                            break;
                        case "Eyeliner":
                            eyelinerTryon(i, landmarks);
                            break;
                        case "Foundation":
                            foundationTryon(i, landmarks);
                            break;
                        case "Concealer":
                            concealerTryon(i, landmarks);
                            break;
                        
                        default:
                            break;
                    }
                } catch(e) {
                    console.log("---eee-",e)
                }
            }
        }
        }
        else {
            clearGlCanvas(gl2);
        }

}

const applyMakeup = (makeupItems) => {
    makeup_items = makeupItems;
    if (image_tag !== null) {
        makeupTryOn(mediapipe_results);
    }
    if (tryonType === 'change_photo') {
        makeupTryOn(mediapipe_results);
    }

}

const initialiseFaceMesh = (webcamref, width, height, try_on_type, image) => {
    tryonType = try_on_type;
    image_tag = image;
    initializeFaceMesh(makeupTryOn, webcamref, width, height, try_on_type, image)
}


export {initialiseCanvas, makeupTryOn, applyMakeup, initialiseFaceMesh,skinToneDetect,smootheningTryon}